import React, { useEffect, useState } from 'react';
import moment from 'moment';
import EmployeeBasic from './common/EmployeeBasic';
import DetailApplication from './common/DetailApplication';
import ModalSubmitSendMail from '../Applicants/common/ModalSubmitSendMail';
import { GetDetailEmployee, deleteEmployee } from '../../../../../services/RecruiterService';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { displayModal, getAgeUser, onHandleChatNewTab, setSex } from '../../../../common/FuntionCommon';
import Loading from '../../../../common/SpinLoading';
import { toast } from '../../../../../utils/notification';
import * as CONSTANT from '../../../../common/constant';

const EmployeeDetail = () => {
  const [activeKeyValue, setActiveKeyValue] = useState('basicDetail');
  const navigate = useNavigate();
  const { id } = useParams();
  const [dataDetail, setDataDetail] = useState({});
  const [loading, setLoading] = useState(true);

  const getDataDetail = async () => {
    const data = await GetDetailEmployee(id);
    if (data) {
      setDataDetail(data?.employeeDetail);
    }
    setLoading(false);
  };

  useEffect(() => {
    getDataDetail();
  }, [id]);

  const [dataApplications, setDataApplications] = useState([]);
  useEffect(() => {
    setDataApplications(dataDetail?.applications?.reverse());
  }, [dataDetail]);

  const tabActivity = {
    basicDetail: <EmployeeBasic dataDetail={dataDetail} getDataDetail={getDataDetail} />,
    detailApplication: <DetailApplication data={dataDetail} getDataDetail={getDataDetail} />,
  };

  const onHandleChat = (e) => {
    e.preventDefault();
    onHandleChatNewTab(dataApplications?.[0].applyGroupId);
  };

  useEffect(() => {
    displayModal();
  });

  const [loadingDelete, setLoadingDelete] = useState(false);
  const handleDeleteEmployee = async () => {
    setLoadingDelete(true);
    const data = await deleteEmployee(id);
    setLoadingDelete(false);
    if (data) {
      navigate('/employees');
      toast('success', CONSTANT.DELETE_SUCCESSFULLY);
    } else toast('error', CONSTANT.FAIL);
  };
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className='pageHeader'>
            <div className='pageHeader__start'>
              <h1 className='pageHeader__title'>
                {dataDetail?.lastName + ' ' + dataDetail?.firstName + ' '}
                <span className='pageHeader__titleMeta'>
                  {getAgeUser({
                    fullYear: +dataDetail?.dateOfBirth_YYYY,
                    month: +dataDetail?.dateOfBirth_MM,
                    day: +dataDetail?.dateOfBirth_DD,
                  })}
                  歳 /{setSex(dataDetail?.sex)}
                </span>
              </h1>
              <div className='pageHeader__detail'>
                <div
                  className={`statusLight ${
                    dataDetail?.employeeStatus === '在職中'
                      ? '-success'
                      : dataDetail?.employeeStatus === '休職中'
                      ? '-warning'
                      : dataDetail?.employeeStatus === '退職済み'
                      ? '-neutral'
                      : ''
                  }`}
                >
                  {' '}
                  {dataDetail?.employeeStatus}
                </div>
                <dl className='pageHeader__definitionList'>
                  <dt>従業員ID: </dt>
                  <dd>
                    <span>{dataDetail?.employeeCode}</span>
                  </dd>
                  <dt>入社年月日：</dt>
                  <dd>{moment(dataDetail?.employeeCompanyEntranceDate).format('YYYY/MM/DD')}</dd>
                </dl>
              </div>
            </div>
            <div className='pageHeader__end'>
              <div className='button-wrapper'>
                <button type='button' className='button -default' data-modal-target='applicant-registration'>
                  応募者に戻す
                </button>
                {dataApplications?.[0].applyGroupId && dataApplications?.[0].chatStatus !== CONSTANT.NOT_YET_INVITE ? (
                  <Link
                    target='_blank'
                    onClick={(e) => onHandleChat(e)}
                    type='button'
                    className='button -outline-primary -chat'
                  >
                    {dataApplications?.[0]?.chatStatus}
                  </Link>
                ) : (
                  <button type='button' className='button -outline-primary -mail' data-modal-target='chat-invitation'>
                    チャットに招待
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className='u-spacer-block2'>
            <ul className='tab'>
              <li className='tab__item'>
                <Link
                  onClick={() => setActiveKeyValue('basicDetail')}
                  className={`tab__button ${activeKeyValue === 'basicDetail' ? 'is-active' : ''}`}
                >
                  基本情報
                </Link>
              </li>
              <li className='tab__item'>
                <Link
                  onClick={() => setActiveKeyValue('detailApplication')}
                  className={`tab__button ${activeKeyValue === 'detailApplication' ? 'is-active' : ''}`}
                >
                  応募情報
                </Link>
              </li>
            </ul>
          </div>
          {tabActivity?.[activeKeyValue]}
          <div className='u-spacer-top4'>
            <Link to='/employees' className='button -default -back'>
              一覧に戻る
            </Link>
          </div>
          <ModalSubmitSendMail
            mode={'sendMailEmployee'}
            refetchContext={getDataDetail}
            replicaId={dataDetail.applicantReplicaId}
            jobTitle={dataDetail.employeeJobTitle}
            jobId={dataDetail.employeeJobId}
            applicantEmail={dataDetail.email}
            firstName={dataDetail.firstName}
            lastName={dataDetail.lastName}
          />

          <div className='modal -small' id='applicant-registration'>
            <div className='modal__inner'>
              <div className='modal__content'>
                <div className='modal__header'>
                  <p className='modal__title'>応募者に戻す</p>
                </div>
                <div className='modal__body'>
                  <p>
                    従業員から応募者に戻します。よろしいですか？
                    <br />
                    <strong>
                      {`※「${dataDetail?.employeeStatus}」「${dataDetail?.employeeCode}」「${moment(
                        dataDetail?.employeeCompanyEntranceDate
                      ).format('YYYY/MM/DD')}」の情報が削除されます。（その他の情報は削除されません）`}
                    </strong>
                  </p>
                </div>
                <div className='modal__footer'>
                  <div className='button-wrapper -end'>
                    <button type='button' className='button -default js-modal-close'>
                      キャンセル
                    </button>
                    <button
                      type='button'
                      disabled={loadingDelete}
                      className='button -primary'
                      onClick={() => handleDeleteEmployee()}
                    >
                      {loadingDelete && <i className='fa fa-spinner fa-spin mr-[10px]'></i>} 登録
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal__overlay'></div>
          </div>
        </>
      )}
    </>
  );
};
export default EmployeeDetail;
