import '../src/designCaeru/resources/assets/css/app.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Fragment, Suspense, useEffect } from 'react';
import { privacyAndTermRoutes, privateSettingRoutes, publicRoutes } from './routes';
import LayoutUnAuthentication from './components/Layout/Unauthentication';
import SettingComponent from './components/Layout/Authentication/SettingComponent';

import React from 'react';
import { socketIO } from './services/socket';
import { ScrollToTop } from './utils/ScrollOnTop';
import {
  AUTO_LOGOUT_TIME,
  MESSAGE_TYPE_INTERVIEW,
  NOTI_INTERVIEW,
  NOTI_PDF_CV,
  NOTI_PDF_WORKHISTORY,
  TYPE_PDF_CV,
  TYPE_PDF_WORKHISTORY,
  USER_SIDE_ANONYMOUS,
  USER_SIDE_APPLICANT,
} from './components/common/constant';
import ErrorPage from './components/Layout/ErrorPage';
import { handleShowNotiForBrowser } from './components/common/FuntionCommon.js';

function App() {
  let logoutTimer;

  useEffect(() => {
    // active of user
    const events = ['load', 'click', 'scroll', 'keypress'];
    events.forEach((event) => window.addEventListener(event, resetLogoutTimer));

    // call function timer
    setLogoutTimer();

    // delete function timer
    return () => {
      clearTimeout(logoutTimer);
      events.forEach((event) => window.removeEventListener(event, resetLogoutTimer));
    };
  }, []);

  // reset timer
  const resetLogoutTimer = () => {
    const token = localStorage.getItem('token');
    if (token) {
      clearTimeout(logoutTimer);
      localStorage.setItem('lastActiveTime', Date.now());
      setLogoutTimer();
    }
  };

  const handleLogout = async () => {
    const lastActiveTime = localStorage.getItem('lastActiveTime');
    if (lastActiveTime && Date.now() - lastActiveTime > AUTO_LOGOUT_TIME) {
      window.location.href = '/login';
      localStorage.clear();
    }
  };

  // function timer
  const setLogoutTimer = () => {
    logoutTimer = setTimeout(() => {
      handleLogout();
      socketIO.disconnect();
    }, AUTO_LOGOUT_TIME);
  };

  // set new message when apply user send message
  useEffect(() => {
    socketIO.on('applyMessage', async (message) => {
      if (message) {
        let notiContent;
        switch (message.type) {
          case MESSAGE_TYPE_INTERVIEW:
            notiContent = NOTI_INTERVIEW;
            break;
          case TYPE_PDF_CV:
            notiContent = NOTI_PDF_CV;
            break;
          case TYPE_PDF_WORKHISTORY:
            notiContent = NOTI_PDF_WORKHISTORY;
            break;
          default:
            notiContent = message.content;
        }

        if (message?.fromSide === USER_SIDE_APPLICANT) {
          const messageFrom = message?.from?.lastName + message?.from?.firstName;
          handleShowNotiForBrowser(messageFrom, notiContent);
        } else {
          if (message?.fromHR?._id !== JSON.parse(localStorage.getItem('id'))) {
            const messageFrom = message?.fromHR?.lastName + message?.fromHR?.firstName ?? '';
            handleShowNotiForBrowser(messageFrom, notiContent);
          }
        }
      }
    });

    // set new message when contact user  send message
    socketIO.on('contactMessage', async (message) => {
      if (message) {
        if (message?.fromSide === USER_SIDE_ANONYMOUS) {
          const messageFrom = message?.from?.nickname;
          handleShowNotiForBrowser(messageFrom, message.content);
        } else {
          if (message?.fromHR?._id !== JSON.parse(localStorage.getItem('id'))) {
            const messageFrom = message?.fromHR?.lastName + message?.fromHR?.firstName;
            handleShowNotiForBrowser(messageFrom, message.content);
          }
        }
      }
    });
    return () => {
      socketIO.off('applyMessage');
      socketIO.off('contactMessage');
    };
  });

  return (

      <Router>
        <ScrollToTop />
        <Suspense>
          <Routes>
            {publicRoutes.map((route, index) => {
              const DefaultLayout = route.layout === null ? Fragment : LayoutUnAuthentication;
              const Page = route.component;
              return (
                <>
                  <Route
                    key={index}
                    path={route.path}
                    element={
                      <DefaultLayout>
                        <Page />
                      </DefaultLayout>
                    }
                  />
                  <Route path='*' element={<ErrorPage />} />
                </>
              );
            })}
            {privacyAndTermRoutes.map((route, index) => {
              const Page = route.component;
              return (
                <>
                  <Route key={index} path={route.path} element={<Page />} />
                  <Route path='*' element={<ErrorPage />} />
                </>
              );
            })}
            {privateSettingRoutes.map((route, index) => {
              const DefaultLayout = route.layout === null ? Fragment : SettingComponent;
              const Page = route.component;
              return (
                <>
                  <Route
                    key={index}
                    path={route.path}
                    element={
                      <DefaultLayout type={route.type}>
                        <Page />
                      </DefaultLayout>
                    }
                  />
                  <Route path='*' element={<ErrorPage />} />
                </>
              );
            })}
          </Routes>
        </Suspense>
      </Router>

  );
}

export default App;
