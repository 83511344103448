import { createSlice } from '@reduxjs/toolkit';
import { URL_JOBS } from '../../routes/constantRouter';

const initialState = {
  tagValueHeader: URL_JOBS,
  tagValueSettingCompany: 'company',
  dataInitial: {
    companyName: '',
    companyCode: '',
    role: '',
    email: '',
    userName: '',
  },
  dataRouterBackButton: {
    jodId: '',
    from_url: '',
  },
};

export const dataWebSlice = createSlice({
  name: 'dataSlice',
  initialState,
  reducers: {
    setTagNameHeader: (state, action) => {
      return { ...state, tagValueHeader: action.payload };
    },
    setTagNameSettingCompany: (state, action) => {
      return { ...state, tagValueSettingCompany: action.payload };
    },
    setDataInitial: (state, action) => {
      return { ...state, dataInitial: action.payload };
    },
    setDataRouterBackButton: (state, action) => {
      return { ...state, dataRouterBackButton: action.payload };
    },
  },
});

export const { setTagNameHeader, setTagNameSettingCompany, setDataInitial, setDataRouterBackButton } =
  dataWebSlice.actions;
export const dataSlice = (state) => state.dataSlice;
export default dataWebSlice.reducer;
