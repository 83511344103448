/* eslint-disable no-console */
/* eslint-disable quotes */
import React, { useEffect, useState } from 'react';
import { getTotalEmployeeForStatus } from '../../../../../../services/RecruiterService';
import { APPLICANT_EXIT, CHATTING, JOIN_WAITING, NOT_YET_INVITE } from '../../../../../common/constant';

const SearchEmployees = ({ getListEmployees, setQuerySearch }) => {
  const handleSearch = async (e) => {
    e.preventDefault();
    const searchData = document?.forms['searchEmployee'];
    const joinDateFrom = searchData['joinDateFrom']?.value ? new Date(searchData['joinDateFrom']?.value) : null;
    const joinDateTo = searchData['joinDateTo']?.value ? new Date(searchData['joinDateTo']?.value) : null;

    const inviteChat = searchData['inviteChat']?.checked ? NOT_YET_INVITE : '';
    const sendingEmail = searchData['sendingEmail']?.checked ? JOIN_WAITING : '';
    const chatting = searchData['chatting']?.checked ? CHATTING : '';
    const stopChat = searchData['stopChat']?.checked ? APPLICANT_EXIT : '';
    const chatStatus = [inviteChat, sendingEmail, chatting, stopChat].filter(Boolean).join(',');

    const working = searchData['working']?.checked ? '在職中' : '';
    const retiring = searchData['retiring']?.checked ? '休職中' : '';
    const retired = searchData['retired']?.checked ? '退職済み' : '';
    const employeeStatus = [working, retiring, retired].filter(Boolean).join(',');

    const querySearch = {
      status: employeeStatus,
      chatStatus: chatStatus,
      employeeCode: searchData['employeeId']?.value,
      name: searchData['name']?.value,
      joinDateFrom: joinDateFrom ? joinDateFrom?.toISOString() : '',
      joinDateTo: joinDateTo ? joinDateTo?.toISOString() : '',
      showHiddenInList: searchData['showHiddenInList']?.checked,
    };
    setQuerySearch(querySearch);
    await getListEmployees(querySearch, 1);
  };

  const handleClearSearch = async () => {
    document?.forms['searchEmployee'].reset();
  };

  const [dataTotal, setDataTotal] = useState({});
  const getTotalData = async () => {
    const data = await getTotalEmployeeForStatus();
    setDataTotal(data?.data);
  };
  useEffect(() => {
    getTotalData();
  }, []);

  return (
    <div className='panel search'>
      <form className='form' onSubmit={(e) => handleSearch(e)} name='searchEmployee'>
        <div className='search__condition'>
          <div className='search__row -col2'>
            <fieldset className='search__group'>
              <legend className='form__label u-text-light'>ステータス</legend>
              <div className='form__field'>
                <div className='checkbox-wrapper'>
                  <label className='checkbox'>
                    <input type='checkbox' defaultChecked className='checkbox__input' name='working' />
                    <span className='checkbox__label'>{`在職中（${dataTotal?.在職中 ?? 0})`}</span>
                  </label>
                  <label className='checkbox'>
                    <input type='checkbox' className='checkbox__input' name='retiring' />
                    <span className='checkbox__label'>{`休職中（${dataTotal?.休職中 ?? 0})`}</span>
                  </label>
                  <label className='checkbox'>
                    <input type='checkbox' className='checkbox__input' name='retired' />
                    <span className='checkbox__label'>{`退職済み（${dataTotal?.退職済み ?? 0})`}</span>
                  </label>
                </div>
              </div>
            </fieldset>
            <fieldset className='search__group'>
              <legend className='form__label u-text-light'>チャット</legend>
              <div className='form__field'>
                <div className='checkbox-wrapper'>
                  <label className='checkbox'>
                    <input
                      type='checkbox'
                      defaultChecked
                      className='checkbox__input'
                      name='inviteChat'
                      value='招待する'
                    />
                    <span className='checkbox__label'>招待する（未招待）</span>
                  </label>
                  <label className='checkbox'>
                    <input
                      type='checkbox'
                      defaultChecked
                      className='checkbox__input'
                      name='sendingEmail'
                      value='参加待ち'
                    />
                    <span className='checkbox__label'>参加待ち</span>
                  </label>
                  <label className='checkbox'>
                    <input
                      type='checkbox'
                      defaultChecked
                      className='checkbox__input'
                      name='chatting'
                      value='チャット中'
                    />
                    <span className='checkbox__label'>チャット中</span>
                  </label>
                  <label className='checkbox'>
                    <input
                      type='checkbox'
                      defaultChecked
                      className='checkbox__input'
                      name='stopChat'
                      value='退出済み'
                    />
                    <span className='checkbox__label'>退出済み</span>
                  </label>
                </div>
              </div>
            </fieldset>
          </div>
          <div className='search__row -col3'>
            <fieldset className='search__group'>
              <legend className='form__label u-text-light'>従業員ID</legend>
              <div className='form__field'>
                <input type='text' className='textfield' name='employeeId' />
              </div>
            </fieldset>
            <fieldset className='search__group'>
              <legend className='form__label u-text-light'>氏名</legend>
              <div className='form__field'>
                <input type='text' className='textfield' name='name' />
              </div>
            </fieldset>
            <fieldset className='search__group'>
              <legend className='form__label u-text-light'>入社年月日</legend>
              <div className='form__field'>
                <div className='form__inline'>
                  <input type='date' className='textfield' name='joinDateFrom' />
                  〜
                  <input type='date' className='textfield' name='joinDateTo' />
                </div>
              </div>
            </fieldset>
          </div>
          <div className='search__row'>
            <fieldset className='search__group'>
              <legend className='form__label u-text-light'>表示設定</legend>
              <div className='form__field'>
                <label className='checkbox'>
                  <input type='checkbox' className='checkbox__input' name='showHiddenInList' />
                  <span className='checkbox__label'>非表示中の従業員を表示</span>
                </label>
              </div>
            </fieldset>
          </div>
        </div>
        <div className='search__action'>
          <button type='button' className='button -default -reset search__resetButton' onClick={handleClearSearch}>
            検索条件をリセット
          </button>
          <button type='submit' className='button -accent search__primaryButton'>
            検索
          </button>
        </div>
      </form>
    </div>
  );
};
export default SearchEmployees;
