/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import {
  URL_SETTING_PROGRESS_RESULT,
  URL_SETTING_PROGRESS_RESULT_DETAIL,
} from '../../../../../../routes/constantRouter';
import {
  createDataProgress,
  getDataDetailProgress,
  getDataProgress,
  updateDataProgress,
} from '../../../../../../services/RecruiterService';
import { useNavigate, useParams } from 'react-router-dom';
import { setTagNameSettingCompany } from '../../../../../redux/reducer';
import { useDispatch } from 'react-redux';
import { toast } from '../../../../../../utils/notification';
import { resetRequireValidate, handleRequireInvalid, handleKeyPress } from '../../../../../common/FuntionCommon';
import Loading from '../../../../../common/SpinLoading';
import { UPDATE_SUCCESSFULLY, UPDATE_FAIL, SETTING_SUCCESSFULLY } from '../../../../../common/constant';
import useSWR, { mutate } from 'swr';
import { URL_API_COMPANY_PROGRESS } from '../../../../../../services';
import { getListProgress } from '../../Applicants/common/SearchApplicants';

const SettingProgress = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState({ loadingDetail: false, loadingAddUpdate: false });
  const navigate = useNavigate();
  const [dataDetail, setDataDetail] = useState();
  const dispatch = useDispatch();

  const fillData = (data) => {
    const dataSettingProgress = document.forms['progressData'];
    dataSettingProgress['step'].value = data?.step;
    dataSettingProgress['status'].value = data?.enable ? 'true' : 'false';
    dataSettingProgress['result'].value = data?.result.join();
  };
  const getDataDetail = async () => {
    setLoading((prev) => ({ ...prev, loadingDetail: true }));

    const data = await getDataDetailProgress(id);
    if (data) {
      setDataDetail(data?.progress);
    }
    setLoading((prev) => ({ ...prev, loadingDetail: false }));
  };

  useEffect(() => {
    if (id) {
      getDataDetail();
    }
  }, [id]);

  useEffect(() => {
    if (!loading.loadingDetail && id) {
      fillData(dataDetail);
    }
  }, [loading.loadingDetail, id]);
  const onHandleCancel = () => {
    if (id) {
      fillData(dataDetail);
    } else document.forms['progressData'].reset();
  };

  const dataProgress = getListProgress();

  const handleFinish = async (e) => {
    const dataSettingProgress = document.forms['progressData'];
    e.preventDefault();
    setLoading((prev) => ({ ...prev, loadingAddUpdate: true }));
    const payload = {
      step: dataSettingProgress['step'].value,
      result: dataSettingProgress['result'].value.split(','),
    };
    if (!id) {
      const data = await createDataProgress(payload);
      if (data) {
        toast('success', SETTING_SUCCESSFULLY);
        await dispatch(setTagNameSettingCompany('progress'));
        navigate('/settings');
      }
    } else {
      const data = await updateDataProgress(id, { ...payload, enable: dataSettingProgress['status'].value });
      if (data) {
        setDataDetail(data?.progress);
        toast('success', UPDATE_SUCCESSFULLY);
      }
    }
    setLoading((prev) => ({ ...prev, loadingAddUpdate: false }));
    mutate(URL_API_COMPANY_PROGRESS, dataProgress, true);
  };

  const onBackBtn = async () => {
    await dispatch(setTagNameSettingCompany('progress'));
    history.back();
  };
  return (
    <>
      {loading.loadingDetail ? (
        <Loading />
      ) : (
        <>
          <div className='pageHeader'>
            {!id ? (
              <h1 className='pageHeader__title'>進捗と結果の登録</h1>
            ) : (
              <div className='pageHeader__start'>
                <h1 className='pageHeader__title'>{dataDetail?.step}</h1>
                <div className='pageHeader__detail'>
                  <div className={`statusLight ${dataDetail?.enable ? '-success' : '-neutral'}`}>
                    {dataDetail?.enable ? '有効' : '無効'}
                  </div>
                  <dl className='pageHeader__definitionList'>
                    <dt>結果：</dt>
                    <dd>{dataDetail?.result?.join()}</dd>
                  </dl>
                </div>
              </div>
            )}
          </div>

          <div className='panel'>
            <div className='panel__body -large'>
              <form onKeyDown={handleKeyPress} onSubmit={(e) => handleFinish(e)} name='progressData' className='form'>
                <table className='detailTable'>
                  {id && (
                    <tr>
                      <th>ステータス</th>
                      <td>
                        <div className='radio-group'>
                          <label className='radio'>
                            <input type='radio' name='status' value='true' className='radio__input' />
                            <span className='radio__label'>有効</span>
                          </label>
                          <label className='radio'>
                            <input type='radio' name='status' value='false' className='radio__input' />
                            <span className='radio__label'>無効</span>
                          </label>
                        </div>
                        <p className='detailTable__note'>※無効にすると、プルダウンの選択肢から非表示になります。</p>
                      </td>
                    </tr>
                  )}
                  <tr>
                    <th>
                      進捗<span className='badge -danger u-spacer-left1'>必須</span>
                    </th>
                    <td>
                      <input
                        name='step'
                        type='text'
                        required
                        onInvalid={(e) => handleRequireInvalid(e)}
                        onChange={(e) => resetRequireValidate(e)}
                        className='textfield'
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      結果<span className='badge -danger u-spacer-left1'>必須</span>
                    </th>
                    <td>
                      <input
                        type='text'
                        name='result'
                        className='textfield'
                        required
                        onInvalid={(e) => handleRequireInvalid(e)}
                        onChange={(e) => resetRequireValidate(e)}
                        placeholder='例）通過,不合格'
                      />
                      <p className='detailTable__note'>
                        ※値はカンマで区切って、複数登録が可能です。
                        <br />
                        上で登録した「進捗」に紐づく結果としてプルダウンに表示されます。
                      </p>
                    </td>
                  </tr>
                </table>
                <div className='u-spacer-top4'>
                  <div className='button-wrapper -center'>
                    <button onClick={() => onHandleCancel()} type='button' className='button -default'>
                      キャンセル
                    </button>
                    <button type='submit' disabled={loading.loadingAddUpdate} className='button -primary'>
                      {loading.loadingAddUpdate && <i className='fa fa-spinner fa-spin mr-[10px]'></i>}保存
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div className='u-spacer-top4'>
            <button onClick={() => onBackBtn()} className='button -default -back'>
              一覧に戻る
            </button>
          </div>
        </>
      )}
    </>
  );
};
export default SettingProgress;
