// @flow
import * as React from 'react';
import { urlServer } from '../../../../common/constant';
import { closeModalOpen, setSex } from '../../../../common/FuntionCommon';
import { reflectApplicantInfo } from '../../../../../services/RecruiterService';
import { toast } from '../../../../../utils/notification';
import { useDispatch, useSelector } from 'react-redux';
import { chatSlice, setCurrentActiveGroupData, setListDataGroupPublic } from '../../../../redux/reducerChat';
import * as CONSTANT from '../../../../common/constant';

export const ModalApplicantApplyProfile = ({ infoData, isOpen, setIsOpenModal }) => {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const { listDataGroupPublic, currentActiveGroup } = useSelector(chatSlice);
  const role_user = JSON.parse(localStorage?.getItem('role'));

  const handleFinish = async () => {
    setLoading(true);
    const data = await reflectApplicantInfo(infoData?._id);
    if (data?.data) {
      toast('success', CONSTANT.COPY_SUCCESSFULLY);
      setLoading(false);
      setIsOpenModal(!isOpen);
      const finishedData = listDataGroupPublic?.map((item) => {
        if (item?.applicantUserId?._id === data?.data?.updatedApplicantReplica?.applicantUser) {
          return {
            ...item,
            applicantReplicaId: data?.data?.updatedApplicantReplica,
          };
        } else return item;
      });
      dispatch(setListDataGroupPublic(finishedData));
      dispatch(
        setCurrentActiveGroupData({ ...currentActiveGroup, applicantReplicaId: data?.data?.updatedApplicantReplica })
      );
    } else toast('error', CONSTANT.COPY_FAIL);
    closeModalOpen(document.getElementById('profile'));
  };
  return (
    <>
      <div className='modal -scrollable' id='profile'>
        <div className='modal__inner'>
          <div className='modal__content'>
            <div className='modal__header'>
              <p className='modal__title'>応募プロフィール</p>
            </div>
            <div className='modal__body'>
              <p className='u-align-center u-spacer-bottom1'>
                {infoData?.applyProfile?.avatar ? (
                  <img
                    src={`${infoData?.applyProfile?.avatar}`}
                    alt=''
                    className='avatar !w-[80px] !h-[80px] my-0 mx-auto'
                  />
                ) : (
                  <img src='avatar-default.svg' alt='' />
                )}
              </p>
              <table className='detailTable'>
                <tbody>
                  <tr>
                    <th>氏名</th>
                    <td> {infoData?.applyProfile?.lastName + ' ' + infoData?.applyProfile?.firstName}</td>
                  </tr>
                  <tr>
                    <th>氏名（フリガナ）</th>
                    <td>
                      {' '}
                      {infoData?.applyProfile?.lastNameFurigana + ' ' + infoData?.applyProfile?.firstNameFurigana}
                    </td>
                  </tr>
                  <tr>
                    <th>生年月日</th>
                    <td>
                      {' '}
                      {infoData?.applyProfile?.dateOfBirth_YYYY +
                        '年' +
                        infoData?.applyProfile?.dateOfBirth_MM +
                        '月' +
                        infoData?.applyProfile?.dateOfBirth_DD +
                        '日'}
                    </td>
                  </tr>
                  <tr>
                    <th>性別</th>
                    <td>{setSex(infoData?.applyProfile?.sex)}</td>
                  </tr>
                  <tr>
                    <th>メールアドレス</th>
                    <td>{infoData?.applyProfile?.emailProfile}</td>
                  </tr>
                  <tr>
                    <th>電話番号</th>
                    <td> {infoData?.applyProfile?.phoneNumber}</td>
                  </tr>
                  <tr>
                    <th>郵便番号</th>
                    <td>{infoData?.applyProfile?.zipCode}</td>
                  </tr>
                  <tr>
                    <th>住所</th>
                    <td> {infoData?.applyProfile?.address}</td>
                  </tr>
                  <tr>
                    <th>最終学歴</th>
                    <td>
                      {' '}
                      {infoData?.applyProfile?.highestEducation + infoData?.applyProfile?.graduatedYear + '年卒業'}
                    </td>
                  </tr>
                  <tr>
                    <th>学校名・学部・学科</th>
                    <td> {infoData?.applyProfile?.schoolAndMajor ?? ''}</td>
                  </tr>
                  <tr>
                    <th>保有資格</th>
                    <td> {infoData?.applyProfile?.certificates}</td>
                  </tr>
                  <tr>
                    <th>職務経歴（テキスト）</th>
                    <td>{infoData?.applyProfile?.workHistory}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='modal__footer'>
              <div className='button-wrapper -end'>
                <button
                  onClick={() => setIsOpenModal(!isOpen)}
                  type='button'
                  className='button -default js-modal-close'
                >
                  キャンセル
                </button>
                {role_user !== CONSTANT.ROLE_CHAT_ONLY && (
                  <button onClick={() => handleFinish()} disabled={loading} type='button' className='button -primary'>
                    {loading && <i className='fa fa-spinner fa-spin mr-[10px]'></i>}応募者情報に反映
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
