import React from 'react';
import imageSrc1 from './images/IMG_3886.jpeg';
import imageSrc2 from './images/IMG_3887.jpeg';
import imageSrc3 from './images/IMG_3888.jpeg';

const UserDeletion = () => {
  return (
    <div className='mx-auto w-fit p-[20px] bg-[white]'>
      <div className='text-[28px] font-bold text-center my-[30px]'>アカウントを削除する</div>
        <div>
          <p>手順1: セッティングボタンをクリックしてください。</p>
          <div className='mx-auto w-fit mt-[5px] mb-[5px]'>
            <img src={imageSrc1} alt='' width={200} height={400}/>
          </div>
        </div>
        <div>
          <p>手順2: アカウントセッティングボタンをクリックしてください。</p>
          <div className='mx-auto w-fit mt-[5px] mb-[5px]'>
            <img src={imageSrc2} alt='' width={200} height={400}/>
          </div>
        </div>
        <div>
          <p>手順3: アカウントの削除を確認してください。 </p>
          <div className='mx-auto w-fit mt-[5px] mb-[5px]'>
            <img src={imageSrc3} alt='' width={200} height={400}/>
          </div>
        </div>
      <div>
        <div className='font-bold mt-[30px] mb-[10px]'>もし質問や問題があれば、下記の情報でお気軽にお問い合わせください。</div>
        <div>
          CAERU株式会社
          <br />
          css@caeru.asia
          <br />
          〒735-0026 広島県安芸郡府中町桃山1丁目16‐5

        </div>
      </div>
    </div>
  );
};
export default UserDeletion;
