/* eslint-disable no-unused-vars */
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  chatSlice,
  setListMessagesMainChat,
  setMessageSending,
  setScrollOnBottomChat,
} from '../../../../redux/reducerChat';
import {
  deleteApplyMessage,
  deleteContactMessage,
  getApplyGroupBasicInfo,
  getApplyLastMessages,
  getApplyLastMessagesContact,
  recruiterGetApplyHistoryMessages,
  recruiterGetContactHistoryMessages,
  sendMessage,
  sendMessageAnonymous,
} from '../../../../../services/ChatService';
import moment from 'moment';
import ModalSubmitSendMail from '../../SettingComponent/Applicants/common/ModalSubmitSendMail';
import { CHAT_TYPE_PUBLIC } from '../../../../common/constant';
import { ModalApplicantApplyProfile } from './ModalApplicantApplyProfile';
import { ModalOpenFilePdf } from './ModalOpenFilePdf';
import { displayModal, getWeekDay } from '../../../../common/FuntionCommon';
import * as CONSTANT from '../../../../common/constant';
import { GetUrlPdfForPreviewChat } from '../../../../../services/RecruiterService';

let inputIME = false;

const MainChat = () => {
  const { currentActiveGroup, typeChat, messagesMainChat, scrollOnBottomChat } = useSelector(chatSlice);

  const activeGroupChatId = currentActiveGroup?._id;
  const [loadingGetMess, setLoadingGetMess] = useState(true);
  const [messageInput, setMessageInput] = useState('');
  const [loadingMoreMessage, setLoadingMoreMessage] = useState(false);
  const dataApplicantSelect =
    currentActiveGroup?.applyProfile && Object.keys(currentActiveGroup?.applyProfile)?.length > 0
      ? currentActiveGroup?.applyProfile
      : currentActiveGroup?.employeeId || currentActiveGroup?.applicantReplicaId || currentActiveGroup?.applicantUserId;
  const jobNameApplicantSelect = currentActiveGroup?.recruitmentId;
  const dispatch = useDispatch();

  const [firstShowMessage, setFirstShowMessage] = useState(true);

  const getLastMessage = async (type) => {
    const data =
      typeChat === CHAT_TYPE_PUBLIC
        ? await getApplyLastMessages([activeGroupChatId])
        : await getApplyLastMessagesContact([activeGroupChatId]);
    if (data?.[activeGroupChatId]?.messages) {
      dispatch(setListMessagesMainChat(data?.[activeGroupChatId]?.messages)); // chat space
      // not is action delete message
      setFirstShowMessage(!firstShowMessage);
      dispatch(setScrollOnBottomChat(!scrollOnBottomChat));
    }
    setLoadingGetMess(false);
  };

  useEffect(() => {
    getLastMessage();
    setLoadingGetMess(true);
  }, [activeGroupChatId]);

  useEffect(() => {
    const element = document.getElementById('js-chatRoomBody');
    if (element && element.scrollHeight !== 0) {
      element.scrollTop = element.scrollHeight;
    }
  }, [scrollOnBottomChat, firstShowMessage]);

  // When input message and send
  const sendMessageData = async (e) => {
    e.preventDefault();
    const alreadySent =
      typeChat === CHAT_TYPE_PUBLIC
        ? await sendMessage(activeGroupChatId, messageInput)
        : await sendMessageAnonymous(activeGroupChatId, messageInput);
    if (alreadySent) {
      setMessageInput(''); // reset textbox input

      const dataSetting = JSON.parse(localStorage?.getItem('dataSetting'));
      const dataMessagesClone = [...messagesMainChat];

      const afterChat = [
        ...dataMessagesClone,
        {
          ...alreadySent,
          from: {
            _id: JSON.parse(localStorage?.getItem('id')),
            lastName: dataSetting?.lastName,
            firstName: dataSetting?.firstName,
          },
        },
      ];
      dispatch(setListMessagesMainChat(afterChat));
      dispatch(setScrollOnBottomChat(!scrollOnBottomChat));
      dispatch(setMessageSending({ activeGroupChatId, messageInput, typeChat }));
    }
    var element = document.getElementById('js-chatRoomBody');
    element.scrollTop = element.scrollHeight;
  };

  const loadMoreMessages = async () => {
    const data =
      typeChat === CHAT_TYPE_PUBLIC
        ? await recruiterGetApplyHistoryMessages(activeGroupChatId, messagesMainChat?.length)
        : await recruiterGetContactHistoryMessages(activeGroupChatId, messagesMainChat?.length);
    const dataMessagesClone = [...messagesMainChat];
    if (Array.isArray(data)) {
      dispatch(setListMessagesMainChat([...data, ...dataMessagesClone]));
    }
  };

  const handleScroll = async (e) => {
    let element = e.target;
    if (element.scrollTop === 0 && element.scrollHeight !== element.offsetHeight) {
      setLoadingMoreMessage(true);
      await loadMoreMessages();
      setLoadingMoreMessage(false);
    }
  };

  const handleDeleteMessage = async (messageId) => {
    typeChat === CHAT_TYPE_PUBLIC ? await deleteApplyMessage(messageId) : await deleteContactMessage(messageId);

    const listMessAfterDelete = messagesMainChat?.map((item) => {
      if (item?._id === messageId) {
        return { ...item, deleted: true };
      }
      return item;
    });
    dispatch(setListMessagesMainChat(listMessAfterDelete));

    if (messagesMainChat[messagesMainChat.length - 1]._id === messageId.toString()) {
      dispatch(setMessageSending({ activeGroupChatId, messageInput, typeChat, deleted: true }));
    }

    setShowDelete(!showBtnDelete);
  };

  const [infoApplyUser, setInfoApplyUser] = useState();
  const [isOpenModalInfo, setIsOpenModalInfo] = useState(false);

  const handleOpenModalProfile = async () => {
    setIsOpenModalInfo(true);
    const data = await getApplyGroupBasicInfo(activeGroupChatId);
    if (data) {
      setInfoApplyUser(data);
    }
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );
  const [openModalPdf, setOpenModalPdf] = useState(false);
  const [urlPdf, setUrlPdf] = useState({ url: '', type: '' });

  const [reflectingMessageId, setSelectingMessage] = useState();

  const handleOpenModalPreviewPDF = async (message) => {
    setSelectingMessage(message._id);
    setOpenModalPdf(true);
    const awsS3Url = await GetUrlPdfForPreviewChat(activeGroupChatId, message?.type);
    const file = await new Blob([awsS3Url], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    setUrlPdf({ url: fileURL, type: message?.type });
  };

  const scrollDownChatRoom = () => {
    const chatRoomBody = document.getElementById('js-chatRoomBody');

    if (!chatRoomBody) {
      return;
    }

    // スクロールが発生しているか調べる
    const isOverflow = chatRoomBody.scrollHeight > chatRoomBody.clientHeight;

    if (isOverflow) {
      chatRoomBody.scrollTo({
        top: chatRoomBody.scrollHeight - chatRoomBody.clientHeight,
      });
    }
  };
  const sendMessageEnter = (event) => {
    const elementChat = document.querySelector('.chatRoom__foot');
    const elementInput = document.querySelector('.chatRoom__input');
    if (event.shiftKey && event.keyCode == 13) {
      const countLines = event.target.value.split('\n');
      if (countLines.length < 5) {
        elementChat.style.height = elementChat.offsetHeight + 15 + 'px';
        elementInput.style.height = '100%';
        elementInput.style.overflow = 'hidden';
      } else {
        elementInput.style.overflowY = 'scroll';
      }
      event.preventDefault();
      setMessageInput(event.target.value + '\n');
    } else if (event.key === 'Enter') {
      elementChat.style.height = 80 + 'px';
      elementInput.style.height = '47px';
      event.preventDefault();
      document.getElementById('js-chatMessageSend').click();
    }
  };

  useLayoutEffect(() => {
    scrollDownChatRoom();
  }, []);

  const [idItemDelete, setIdItemDelete] = useState('');
  const [showBtnDelete, setShowDelete] = useState(false);

  const handleToggleBtn = (idItem) => {
    setShowDelete(!showBtnDelete);
    setIdItemDelete(idItem);
  };
  useEffect(() => {
    displayModal();
  });

  const handleChangeMessageInput = (e) => {
    const elementChat = document.querySelector('.chatRoom__foot');
    const elementInput = document.querySelector('.chatRoom__input');

    setMessageInput(e.target.value);
    let lines = e.target.value.split('\n');
    if (lines.length > 1 && lines[lines.length - 1] == '' && lines.length < 5) {
      elementChat.style.height = elementChat.offsetHeight - 15 + 'px';
    }
    if (lines?.length === 1) {
      elementChat.style.height = 80 + 'px';
      elementInput.style.height = '47px';
    }
    if (lines.length < 5) {
      elementInput.style.overflow = 'hidden';
    }
  };
  useEffect(() => {
    setMessageInput('');
    const elementChat = document.querySelector('.chatRoom__foot');
    const elementInput = document.querySelector('.chatRoom__input');
    if (elementChat) {
      elementChat.style.height = 80 + 'px';
      elementInput.style.height = '47px';
      elementInput.style.overflow = 'hidden';
    }
  }, [currentActiveGroup]);

  async function handleInputKeyDown(e) {
    if (e.key === 'Tab') {
      e.preventDefault();
    } else if (e.key === 'Enter' && !inputIME) {
      sendMessageEnter(e);
    }
  }

  // check message is link or basic text
  const getFormattedMessage = (message, from) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const messagesArray = message.split(urlRegex);
    const formattedMessage = messagesArray.map((item, index) =>
      urlRegex.test(item) ? (
        <a
          className={`border-solid border-b-[1px] ${
            from !== CONSTANT.USER_SIDE_RECRUITER ? 'text-[#308ee2] border-[#308ee2]' : 'text-[white] border-[white]'
          }`}
          key={index}
          href={item}
          target='_blank'
          rel='noopener noreferrer'
        >
          {item}
        </a>
      ) : (
        item
      )
    );
    return formattedMessage;
  };
  return (
    <>
      <>
        <section className='chatRoom relative'>
          <div className='chatRoom__head'>
            <h2 className='chatRoom__headTitle'>
              {typeChat === CHAT_TYPE_PUBLIC
                ? dataApplicantSelect?.lastName + ' ' + dataApplicantSelect?.firstName
                : currentActiveGroup?.nickname}
            </h2>
            <p className='u-text-light'>{jobNameApplicantSelect?.jobTitle}</p>
          </div>
          <div className='chatRoom__body' id='js-chatRoomBody' onScroll={(e) => handleScroll(e)}>
            <>
              {loadingGetMess ? (
                <>
                  <div>
                    <Spin className='left-[50%] top-[50%] absolute' indicator={antIcon} />
                  </div>
                </>
              ) : (
                <>
                  {typeChat === CHAT_TYPE_PUBLIC && !currentActiveGroup?.applyProfile && (
                    <div className='message -full'>
                      <p className='message__plainText'>{`${
                        dataApplicantSelect?.lastName + ' ' + dataApplicantSelect?.firstName
                      } を招待しました`}</p>
                      <div className='message__body'>
                        <button type='button' className='button -outline-primary' data-modal-target='chat-invitation'>
                          招待メールを再送
                        </button>
                      </div>
                    </div>
                  )}
                  {loadingMoreMessage && (
                    <div>
                      <Spin className='left-[50%] top-[68px] absolute' indicator={antIcon} />
                    </div>
                  )}
                  {messagesMainChat?.map((message) => {
                    const interview =
                      message?.type === CONSTANT.MESSAGE_TYPE_INTERVIEW ? JSON.parse(message?.content) : {};
                    const dateInterview = interview?.interviewStart;
                    const dayJapanese = new Date(dateInterview);
                    return (
                      <React.Fragment key={message._id + typeChat}>
                        {((message?.fromSide === CONSTANT.USER_SIDE_APPLICANT && message?.to === activeGroupChatId) ||
                          (message?.fromSide === CONSTANT.USER_SIDE_ANONYMOUS &&
                            message?.to === activeGroupChatId)) && (
                          <div className='message'>
                            <div className='message__head'>
                              {dataApplicantSelect?.avatar ?? currentActiveGroup?.avatar ? (
                                <img
                                  src={`${dataApplicantSelect?.avatar ?? currentActiveGroup?.avatar}`}
                                  alt=''
                                  className='avatar'
                                />
                              ) : (
                                <img src='avatar-default.svg' alt='' className='avatar' />
                              )}
                              <p className='message__name'>
                                {' '}
                                {typeChat === CHAT_TYPE_PUBLIC
                                  ? message?.from?.lastName + ' ' + message?.from?.firstName
                                  : message?.from?.nickname}
                              </p>
                              <time
                                className='message__time'
                                dateTime={moment(message?.createTime).format('YYYY年MM月DD日HH:mm')}
                              >
                                {' '}
                                {moment(message?.createTime).format('YYYY年MM月DD日HH:mm')}
                              </time>
                            </div>
                            {message?.deleted && (
                              <i className='message__text whitespace-pre-wrap max-w-full w-fit mt-[8px] !text-[white] !bg-[gray]'>
                                メッセージは投稿者によって削除されました
                              </i>
                            )}
                            {message?.type === 'profile' && (
                              <>
                                <p className='message__plainText'>
                                  応募プロフィールが送信されました。
                                  <br />
                                  クリックで詳細を確認できます。
                                </p>
                                <div className='message__body'>
                                  <button
                                    onClick={() => handleOpenModalProfile()}
                                    type='button'
                                    className='message__banner'
                                    data-modal-target='profile'
                                  >
                                    <img
                                      data-modal-target='profile'
                                      src='icon-profile.svg'
                                      alt=''
                                      className='message__bannerIcon'
                                    />
                                    <span data-modal-target='profile' className='message__bannerText'>
                                      応募プロフィール
                                    </span>
                                  </button>
                                </div>
                              </>
                            )}
                            {message?.type === CONSTANT.TYPE_PDF_WORKHISTORY && !message?.deleted && (
                              <>
                                <p className='message__plainText'>
                                  書類が送信されました。
                                  <br />
                                  クリックで詳細を確認できます。
                                </p>
                                <div className='message__body'>
                                  <button
                                    onClick={() => handleOpenModalPreviewPDF(message)}
                                    type='button'
                                    className='message__banner'
                                    id='btnOpenModalPdf'
                                  >
                                    <img src='icon-document.svg' alt='' className='message__bannerIcon' />
                                    <span className='message__bannerText'>職務経歴書</span>
                                  </button>
                                </div>
                              </>
                            )}
                            {message?.type === CONSTANT.TYPE_PDF_CV && !message?.deleted && (
                              <>
                                <p className='message__plainText'>
                                  書類が送信されました。
                                  <br />
                                  クリックで詳細を確認できます。
                                </p>
                                <div className='message__body'>
                                  <button
                                    onClick={() => handleOpenModalPreviewPDF(message)}
                                    type='button'
                                    className='message__banner'
                                    id='btnOpenModalPdf'
                                  >
                                    <img src='icon-document.svg' alt='' className='message__bannerIcon' />
                                    <span className='message__bannerText'>履歴書</span>
                                  </button>
                                </div>
                              </>
                            )}
                            {message?.type === 'text' && !message?.deleted && (
                              <div className='message__body max-w-full'>
                                <p className='message__text whitespace-pre-wrap max-w-full'>
                                  {getFormattedMessage(message?.content, CONSTANT.USER_SIDE_APPLICANT)}
                                </p>
                              </div>
                            )}
                          </div>
                        )}
                        {message?.fromSide === CONSTANT.USER_SIDE_RECRUITER &&
                          message?.to === activeGroupChatId &&
                          message?.type !== CONSTANT.MESSAGE_TYPE_INTERVIEW &&
                          message?.from?._id === JSON.parse(localStorage?.getItem('id')) && (
                            <div className='message -own -end'>
                              <div className='message__head'>
                                <p className='message__name'>
                                  {message?.from?.lastName + ' ' + message?.from?.firstName}
                                </p>
                                <time className='message__time' dateTime='2023-01-15 09:00'>
                                  {' '}
                                  {moment(message?.createTime).format('YYYY年MM月DD日HH:mm')}
                                </time>
                              </div>
                              <div className='message__body max-w-full'>
                                {message?.deleted ? (
                                  <i className='message__text whitespace-pre-wrap max-w-full !bg-[gray]'>
                                    メッセージは投稿者によって削除されました
                                  </i>
                                ) : (
                                  <>
                                    <p className='message__text whitespace-pre-wrap max-w-full'>
                                      {getFormattedMessage(message?.content, CONSTANT.USER_SIDE_RECRUITER)}
                                    </p>
                                    <div className='message__menu'>
                                      <button
                                        onClick={() => handleToggleBtn(message?._id)}
                                        type='button'
                                        className='iconButton -moreVert js-messageMoreButton'
                                      ></button>
                                      {showBtnDelete && idItemDelete == message?._id && (
                                        <ul className='message__menuList'>
                                          <li className='message__menuItem'>
                                            <button
                                              type='button'
                                              onClick={() => handleDeleteMessage(message?._id)}
                                              className='message__menuDelete js-messageDeleteButton'
                                            >
                                              削除
                                            </button>
                                          </li>
                                        </ul>
                                      )}
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          )}
                        {message?.fromSide !== CONSTANT.USER_SIDE_APPLICANT &&
                          message?.fromSide !== CONSTANT.USER_SIDE_ANONYMOUS &&
                          message?.to === activeGroupChatId &&
                          message?.type !== CONSTANT.MESSAGE_TYPE_INTERVIEW &&
                          message?.from?._id !== JSON.parse(localStorage?.getItem('id')) && (
                            <div className='message -end'>
                              <div className='message__head'>
                                <p className='message__name'>
                                  {' '}
                                  {message?.from?.firstName && message?.from?.lastName
                                    ? message?.from?.lastName + ' ' + message?.from?.firstName
                                    : message?.from?.username}
                                </p>
                                <time className='message__time' dateTime='2023-01-25 09:25'>
                                  {' '}
                                  {moment(message?.createTime).format('YYYY年MM月DD日HH:mm')}
                                </time>
                              </div>
                              <div className='message__body max-w-full'>
                                {message?.deleted ? (
                                  <i className='message__text whitespace-pre-wrap max-w-full !text-[white] !bg-[gray]'>
                                    メッセージは投稿者によって削除されました
                                  </i>
                                ) : (
                                  <p className='message__text whitespace-pre-wrap max-w-full'>
                                    {getFormattedMessage(message?.content, 'differentHR')}
                                  </p>
                                )}
                              </div>
                            </div>
                          )}
                        {message?.fromSide === CONSTANT.USER_SIDE_RECRUITER && message?.type === 'interview' && (
                          <div className='message -full'>
                            <p className='message__plainText'>面接日時が設定されました</p>
                            <div className='message__body'>
                              <div className='message__note'>
                                面接日時
                                <br />
                                {interview?.interviewStart && (
                                  <>
                                    <span className='u-display-inlineBlock'>
                                      {moment(interview?.interviewStart).format('YYYY年MM月DD日')}
                                      {getWeekDay(dayJapanese)}
                                    </span>
                                    <span className='u-display-inlineBlock'>
                                      {moment(interview?.interviewStart).format('HH:mm')}-
                                      {interview?.interviewEnd ? moment(interview?.interviewEnd).format('HH:mm') : ''}
                                    </span>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </React.Fragment>
                    );
                  })}
                </>
              )}
            </>
            {/* // ) : (
            //   <div className='message -full'>
            //     <p className='message__plainText'>{`${
            //       dataApplicantSelect?.lastName + ' ' + dataApplicantSelect?.firstName
            //     } を招待しました`}</p>
            //     <div className='message__body'>
            //       <button type='button' className='button -outline-primary' data-modal-target='chat-invitation'>
            //         招待メールを再送
            //       </button>
            //     </div>
            //   </div>
            // )} */}
          </div>
          <form className='chatRoom__foot' onSubmit={(e) => sendMessageData(e)}>
            <div className='chatRoom__footInner h-full'>
              <textarea
                value={messageInput}
                onChange={(e) => handleChangeMessageInput(e)}
                onKeyDown={(e) => handleInputKeyDown(e)}
                type='text'
                placeholder='メッセージを入力…（Enterで送信 / Shift+Enterで改行）'
                className='chatRoom__input h-[47px]'
                id='js-chatMessageInput'
                onCompositionStart={() => {
                  inputIME = true;
                }}
                onCompositionEnd={() => {
                  inputIME = false;
                }}
              />
              <button
                // onClick={() => sendMessageData()}
                disabled={messageInput.trim() !== '' ? false : true}
                type='submit'
                className='iconButton -send chatRoom__send'
                id='js-chatMessageSend'
              ></button>
            </div>
          </form>
          <ModalApplicantApplyProfile
            infoData={infoApplyUser?.applyGroup}
            isOpen={isOpenModalInfo}
            setIsOpenModal={setIsOpenModalInfo}
          />
          <ModalSubmitSendMail
            mode={'sendMailAgain'}
            jobTitle={currentActiveGroup.recruitmentId?.jobTitle}
            jobId={currentActiveGroup.recruitmentId?._id}
            replicaId={currentActiveGroup.applicantReplicaId?._id}
            applicantEmail={currentActiveGroup.applicantReplicaId?.email}
            firstName={currentActiveGroup.applicantReplicaId?.firstName}
            lastName={currentActiveGroup.applicantReplicaId?.lastName}
          />
          {openModalPdf && (
            <ModalOpenFilePdf
              groupId={activeGroupChatId}
              messageId={reflectingMessageId}
              urlPdf={urlPdf}
              isOpen={openModalPdf}
              setIsOpenModal={setOpenModalPdf}
            />
          )}
        </section>
      </>
    </>
  );
};
export default MainChat;
