import * as React from 'react';
import { toast } from '../../../../../utils/notification';
import { reflectPdfCv, reflectPdfWorkHistory } from '../../../../../services/RecruiterService';
import * as CONSTANT from '../../../../common/constant';
import { closeModalOpen } from '../../../../common/FuntionCommon';
import { useDispatch, useSelector } from 'react-redux';
import { chatSlice, setCurrentActiveGroupData } from '../../../../redux/reducerChat';

export const ModalOpenFilePdf = ({ urlPdf, messageId, isOpen, setIsOpenModal }) => {
  const [loading, setLoading] = React.useState(false);
  const role_user = JSON.parse(localStorage?.getItem('role'));
  const { currentActiveGroup } = useSelector(chatSlice);

  const dispatch = useDispatch();
  const handleFinish = async () => {
    setLoading(true);
    const data =
      urlPdf?.type === CONSTANT.TYPE_PDF_CV ? await reflectPdfCv(messageId) : await reflectPdfWorkHistory(messageId);
    if (data) {
      toast('success', CONSTANT.COPY_SUCCESSFULLY);
      setLoading(false);
      setIsOpenModal(!isOpen);
      dispatch(
        setCurrentActiveGroupData({ ...currentActiveGroup, applicantReplicaId: data?.data?.updatedApplicantReplica })
      );
    } else toast('error', CONSTANT.COPY_FAIL);
    closeModalOpen(document.getElementById('pdfCV'));
  };

  return (
    <>
      <div className='modal -scrollable !opacity-100 !visible'>
        <div className='modal__inner'>
          <div className='modal__content' id='pdfCV'>
            <div className='modal__header'>
              <p className='modal__title'>{urlPdf?.type === CONSTANT.TYPE_PDF_CV ? '履歴書' : '職務経歴書'}</p>
            </div>
            <div className='modal__body'>
              <iframe src={`${urlPdf?.url}`} title={urlPdf?.type} className='modal__doc'></iframe>
            </div>
            <div className='modal__footer'>
              <div className='button-wrapper -end'>
                <button onClick={() => setIsOpenModal(false)} type='button' className='button -default js-modal-close'>
                  キャンセル
                </button>
                {role_user !== CONSTANT.ROLE_CHAT_ONLY && (
                  <button onClick={() => handleFinish()} disabled={loading} type='button' className='button -primary'>
                    {loading && <i className='fa fa-spinner fa-spin mr-[10px]'></i>}応募者情報に反映
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
