import React, { Suspense, useEffect, useState } from 'react';
import ApplicantInformation from './ApplicantInformation';
import JobInfo from './JobInfo';
import ParticipatingUsers from './ParticipatingUsers';
import Note from './Note';
import { GetListHr } from '../../../../../services/RecruiterService';
import { useSelector } from 'react-redux';
import { chatSlice } from '../../../../redux/reducerChat';
import { CHAT_TYPE_PUBLIC } from '../../../../common/constant';
import { displayModal, getFullNameForListHR } from '../../../../common/FuntionCommon';
import { URL_API_GET_LIST_HR } from '../../../../../services';
import useSWR from 'swr';

const RightBar = ({ groupId }) => {
  const { typeChat } = useSelector(chatSlice);
  const { currentActiveGroup } = useSelector(chatSlice);

  const dataApplication = currentActiveGroup?.application;

  const applicantReplicaId = currentActiveGroup?.applicantReplicaId?._id ?? currentActiveGroup?.applicantUserId?._id;
  const currentJobId = currentActiveGroup?.recruitmentId?._id;

  const [activeKeyValue, setActiveKeyValue] = useState('1');

  const onChangeTag = (e) => {
    setActiveKeyValue(e);
  };

  const [dataListHr, setDataListHr] = useState([]);
  const { data } = useSWR([1, 100, URL_API_GET_LIST_HR], GetListHr, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  useEffect(() => {
    const finishData = getFullNameForListHR(data);
    setDataListHr(finishData);
  }, [data]);

  const arrayActivityTab = {
    1:
      typeChat === CHAT_TYPE_PUBLIC ? (
        <ApplicantInformation
          key={applicantReplicaId + currentJobId}
          dataApplication={dataApplication ?? {}}
          applicantId={applicantReplicaId}
          jobId={currentJobId}
        />
      ) : (
        <Note jobId={typeChat === CHAT_TYPE_PUBLIC ? currentJobId : currentActiveGroup?.recruitmentId?._id} />
      ),
    2: (
      <JobInfo
        jobId={typeChat === CHAT_TYPE_PUBLIC ? currentJobId : currentActiveGroup?.recruitmentId?._id}
        dataListHr={dataListHr}
      />
    ),
    3: (
      <ParticipatingUsers
        groupId={groupId}
        dataListHr={dataListHr}
        hrListForGroup={currentActiveGroup?.recruiterMembers}
      />
    ),
  };

  useEffect(() => {
    displayModal();
  });

  return (
    <Suspense>
      <div className='chatInfo'>
        <div className='chatInfo__head'>
          <ul className='tab'>
            <li className='tab__item'>
              <button
                onClick={() => onChangeTag('1')}
                type='button'
                className={`tab__button js-tabButton ${activeKeyValue === '1' ? 'is-active' : ''}`}
                data-tab-target='applicantInfo'
              >
                {typeChat === CHAT_TYPE_PUBLIC ? '応募者情報' : 'メモ'}
              </button>
            </li>
            <li className='tab__item'>
              <button
                onClick={() => onChangeTag('2')}
                className={`tab__button js-tabButton ${activeKeyValue === '2' ? 'is-active' : ''}`}
                data-tab-target='jobInfo'
              >
                求人情報
              </button>
            </li>
            <li className='tab__item'>
              <button
                onClick={() => onChangeTag('3')}
                className={`tab__button js-tabButton ${activeKeyValue === '3' ? 'is-active' : ''}`}
                data-tab-target='users'
              >
                参加ユーザー
              </button>
            </li>
          </ul>
        </div>
        <div className='chatInfo__body'>{arrayActivityTab?.[activeKeyValue]}</div>
      </div>
    </Suspense>
  );
};
export default RightBar;
