import React, { useState } from 'react';
import { toast } from '../../../../../../utils/notification';
import { deleteProgressStep } from '../../../../../../services/RecruiterService';
import * as CONSTANT from '../../../../../common/constant';
import { closeModalOpen } from '../../../../../common/FuntionCommon';

const ModalDeleteProgress = ({ getData, progress, replicaId }) => {
  const [loading, setLoading] = useState(false);
  const handleDelete = async () => {
    setLoading(true);
    const data = await deleteProgressStep(replicaId, progress?._id);
    if (data) {
      toast('success', CONSTANT.DELETE_SUCCESSFULLY);
    }
    getData();
    setLoading(false);
    closeModalOpen(document.getElementById('selection-delete'));
  };
  return (
    <>
      <div className='modal -small' id='selection-delete'>
        <div className='modal__inner'>
          <div className='modal__content'>
            <div className='modal__header'>
              <p className='modal__title'>{`${progress?.step}を削除しますか？`}</p>
            </div>
            <div className='modal__body'>
              <p>
                {`${progress?.step}を削除します。よろしいですか？`}
                <br />
                ※この操作は取り消せません
              </p>
            </div>
            <div className='modal__footer'>
              <div className='button-wrapper -end'>
                <button type='button' className='button -default js-modal-close'>
                  キャンセル
                </button>
                <button
                  onClick={() => handleDelete()}
                  disabled={loading}
                  type='button'
                  className='button -danger js-modal-close'
                >
                  {loading && <i className='fa fa-spinner fa-spin mr-[10px]'></i>}削除
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className='modal__overlay'></div>
      </div>
    </>
  );
};
export default ModalDeleteProgress;
