import FormParentApplicant from './FormParentApplicant';
import BodyTableListResult from './common/BodyTableListResult';
import React from 'react';

// 不合格
const ApplicantsFailure = () => {
  return (
    <div className='lang-ding-page'>
      <FormParentApplicant>
        <BodyTableListResult type={'applicantsFailure'} />
      </FormParentApplicant>
    </div>
  );
};
export default ApplicantsFailure;
