import React from 'react';
const ModalConfirmBtn = ({ handleCancel, changeHrChat, listHr, namHR }) => {
  return (
    <div className="modal -small !opacity-100 !visible" id="removeChatUser">
      <div className="modal__inner">
        <div className="modal__content">
          <form action="#" className="form">
            <div className="modal__header">
              <p className="modal__title">チャットから除外しますか？</p>
            </div>
            <div className="modal__body">
              <p>{`${namHR}をこのチャットから除外します。よろしいですか？`}</p>
              <p>※会話に再び追加することは可能です。</p>
            </div>
            <div className="modal__footer">
              <div className="button-wrapper -end">
                <button onClick={() => handleCancel()} type="button" className="button -default js-modal-close">キャンセル</button>
                <button  onClick={() => changeHrChat(listHr)} type="button" className="button -primary js-modal-close">除外</button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="modal__overlay"></div>
    </div>
  );
};
export default ModalConfirmBtn;
