import ChatComponent from '../components/Layout/Authentication/Chatcomponent';
import InitialSetting from '../components/Layout/Authentication/InitialSetting';
import ApplicantsBody from '../components/Layout/Authentication/SettingComponent/Applicants';
import ApplicantDetail from '../components/Layout/Authentication/SettingComponent/Applicants/ApplicantDetail';
import ApplicantsDecline from '../components/Layout/Authentication/SettingComponent/Applicants/ApplicantsDecline';
import ApplicantsFailure from '../components/Layout/Authentication/SettingComponent/Applicants/ApplicantsFailure';
import ApplicantCreateNew from '../components/Layout/Authentication/SettingComponent/Applicants/ApplicantCreateNew';
import ApplicantsPass from '../components/Layout/Authentication/SettingComponent/Applicants/ApplicantsPass';
import ChangeNewPassword from '../components/Layout/Authentication/SettingComponent/ChangeNewPw';
import EmployeesBody from '../components/Layout/Authentication/SettingComponent/Employees';
import EmployeesDetail from '../components/Layout/Authentication/SettingComponent/Employees/EmployeesDetail';
import JobsBody from '../components/Layout/Authentication/SettingComponent/Jobs';
import AddNewJob from '../components/Layout/Authentication/SettingComponent/Jobs/AddNewJob';
import JobDetail from '../components/Layout/Authentication/SettingComponent/Jobs/JobDetail';
import SettingsCompany from '../components/Layout/Authentication/SettingComponent/SettingsCompany';
import SettingProgress from '../components/Layout/Authentication/SettingComponent/SettingsCompany/Progress/SettingProgress';
import SettingUser from '../components/Layout/Authentication/SettingComponent/SettingsCompany/User/addNewSettingUser';
import ForgotPassword from '../components/Layout/Unauthentication/ForgotPassword';
import Login from '../components/Layout/Unauthentication/Login';
import SettingPasswordFirstTime from '../components/Layout/Unauthentication/SettingPasswordFirstTime';
import Signup from '../components/Layout/Unauthentication/Signup';
import * as URL from './constantRouter';
import PrivacyPolicy from '../components/Layout/Unauthentication/Document/PrivacyPolicy';
import TermsOfService from '../components/Layout/Unauthentication/Document/TermsOfService';
import UserDeletion from '../components/Layout/Unauthentication/Document/UserDeletion';
import ErrorPage from '../components/Layout/ErrorPage';
import FinishedSignUp from '../components/Layout/Unauthentication/Signup/FinishedSignUp';
import SignupVerifyOtp from '../components/Layout/Unauthentication/Signup/VerifyMail';

export const publicRoutes = [
  { path: '/', component: Login },
  { path: URL.URL_LOGIN, component: Login },
  { path: URL.URL_FORGOT_PW, component: ForgotPassword },
  { path: URL.URL_SIGNUP1, component: Signup },
  { path: URL.URL_VERIFY_EMAIL, component: SignupVerifyOtp },
  { path: URL.URL_FINISHED_SIGNUP, component: FinishedSignUp },
  { path: URL.URL_RESET_PW, component: SettingPasswordFirstTime },
];
export const privacyAndTermRoutes = [
  { path: URL.PRIVACY_POLICY, component: PrivacyPolicy },
  { path: URL.TERM_OF_SERVICE, component: TermsOfService },
  { path: URL.USER_DELETION, component: UserDeletion },
];

export const privateSettingRoutes = [
  {
    path: URL.URL_SETTING_INTERNAL,
    component: InitialSetting,
    type: 'initial',
  },

  { path: URL.URL_PASSWORD_CHANGE, component: ChangeNewPassword },
  //job
  { path: URL.URL_JOBS, component: JobsBody },
  { path: URL.URL_ADD_NEW_JOB, component: AddNewJob },
  { path: URL.URL_JOB_DETAIL, component: JobDetail },
  //applicant
  { path: URL.URL_APPLICANTS, component: ApplicantsBody },
  { path: URL.URL_APPLICANT_PASS, component: ApplicantsPass },
  { path: URL.URL_APPLICANT_DECLINE, component: ApplicantsDecline },
  { path: URL.URL_APPLICANT_FAILURE, component: ApplicantsFailure },
  { path: URL.URL_APPLICANT_NEW, component: ApplicantCreateNew },
  { path: URL.URL_APPLICANT_DETAIL, component: ApplicantDetail },
  // employee
  { path: URL.URL_EMPLOYEES, component: EmployeesBody },
  { path: URL.URL_EMPLOYEES_DETAIL, component: EmployeesDetail },
  //company
  { path: URL.URL_SETTINGS_COMPANY, component: SettingsCompany },
  { path: URL.URL_SETTING_USER_NEW, component: SettingUser },
  { path: URL.URL_SETTING_DETAIL_USER, component: SettingUser },
  { path: URL.URL_SETTING_PROGRESS_RESULT, component: SettingProgress },
  { path: URL.URL_SETTING_PROGRESS_RESULT_DETAIL, component: SettingProgress },
  //chat
  { path: URL.URL_CHAT_APPLICANTS, component: ChatComponent, type: 'chat' },
];

export const errorPageRoutes = [{ path: URL.URL_ERROR_PAGE, component: ErrorPage }];
