/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useRef, useState } from 'react';
import { UpdateEmployee } from '../../../../../../services/RecruiterService';
import { useParams } from 'react-router-dom';
import { toast } from '../../../../../../utils/notification';
import * as CONSTANT from '../../../../../common/constant';
import {
  resetRequireValidate,
  handleKeyPress,
  handleRequireInvalid,
  validateEmail,
  validatePhoneNumber,
  validateZipcode,
  getAgeUser,
  openNewTabPreviewPDF,
} from '../../../../../common/FuntionCommon';
import {
  getOptionsDate,
  getOptionsMonth,
  getOptionsYear,
  getOptionsYearGraduation,
} from '../../Applicants/common/ApplicantBasicInfo';
import { get } from 'japan-postal-code';
import moment from 'moment';

const EmployeeBasic = ({ dataDetail, getDataDetail }) => {
  const [fileCV, setFileCV] = useState(null);
  const [fileWorkHistory, setFileWorkHistory] = useState(null);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const validatesRef = useRef({
    firstName: null,
    lastName: null,
    dateOfBirth_YYYY: null,
    dateOfBirth_MM: null,
    dateOfBirth_DD: null,
    email: null,
    employeeCode: null,
    employeeCompanyEntranceDate: null,
  });

  const handleUpdate = async (e) => {
    e.preventDefault();
    const validated = validateInput();
    if (!validated) {
      // If there are any error in validate, then do nothing
      toast('error', CONSTANT.PLEASE_CHECK_INPUT);
      return;
    } else {
      clearErrorMessage();
      setLoading(true);
      const payload = {
        firstName: document.forms['employeeData']['firstName'].value ?? '',
        lastName: document.forms['employeeData']['lastName'].value ?? '',
        firstNameFurigana: document.forms['employeeData']['firstNameFurigana'].value ?? '',
        lastNameFurigana: document.forms['employeeData']['lastNameFurigana'].value ?? '',
        dateOfBirth_DD: document.forms['employeeData']['dateOfBirth_DD'].value ?? '',
        dateOfBirth_MM: document.forms['employeeData']['dateOfBirth_MM'].value ?? '',
        dateOfBirth_YYYY: document.forms['employeeData']['dateOfBirth_YYYY'].value ?? '',
        sex: document.forms['employeeData']['gender'].value ?? '',
        email: document.forms['employeeData']['email'].value ?? '',
        zipCode: document.forms['employeeData']['zipCode'].value ?? '',
        phoneNumber: document.forms['employeeData']['phoneNumber'].value ?? '',
        address: document.forms['employeeData']['address'].value ?? '',
        highestEducation: document.forms['employeeData']['highestEducation'].value ?? '',
        graduatedYear: document.forms['employeeData']['graduatedYear'].value ?? '',
        major: document.forms['employeeData']['major'].value ?? '',
        certificates: document.forms['employeeData']['certificates'].value ?? '',
        workHistory: document.forms['employeeData']['workHistory'].value ?? '',
        employeeStatus: document.forms['employeeData']['employeeStatus'].value ?? '',
        employeeCode: document.forms['employeeData']['employeeCode'].value ?? '',
        employeeCompanyEntranceDate: document.forms['employeeData']['employeeCompanyEntranceDate'].value ?? '',
        hiddenInList: document.forms['employeeData']['hiddenInList'].checked ? 'true' : 'false',
      };
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        formData.append(`${key}`, payload[key]);
      });
      if (fileCV !== null) {
        formData.append('pdfCV', fileCV);
      }
      if (fileWorkHistory !== null) {
        formData.append('pdfWorkHistory', fileWorkHistory);
      }
      const data = await UpdateEmployee(id, formData);
      setLoading(false);
      getDataDetail();
      if (data) {
        toast('success', CONSTANT.UPDATE_SUCCESSFULLY);
      }
    }
  };

  // option selects
  const optionsYear = getOptionsYear();
  const optionsMonth = getOptionsMonth();
  const optionsDate = getOptionsDate();
  const optionsYearGraduation = getOptionsYearGraduation();

  // set old user
  const [age, setAge] = useState('');
  const handleChangeYear = async (e) => {
    resetRequireValidate(e);
    setAge(
      getAgeUser({
        fullYear: +document.forms['employeeData']['dateOfBirth_YYYY'].value,
        month: +document.forms['employeeData']['dateOfBirth_MM'].value,
        day: +document.forms['employeeData']['dateOfBirth_DD'].value,
      })
    );
  };
  const handleChangeMonth = async (e) => {
    resetRequireValidate(e);
    setAge(
      getAgeUser({
        fullYear: +document.forms['employeeData']['dateOfBirth_YYYY'].value,
        month: +document.forms['employeeData']['dateOfBirth_MM'].value,
        day: +document.forms['employeeData']['dateOfBirth_DD'].value,
      })
    );
  };
  const handleChangeDay = async (e) => {
    resetRequireValidate(e);
    setAge(
      getAgeUser({
        fullYear: +document.forms['employeeData']['dateOfBirth_YYYY'].value,
        month: +document.forms['employeeData']['dateOfBirth_MM'].value,
        day: +document.forms['employeeData']['dateOfBirth_DD'].value,
      })
    );
  };
  // fill data for form
  const fillDataDetail = () => {
    const dataForm = document.forms['employeeData'];
    setAge(
      getAgeUser({
        fullYear: +dataDetail?.dateOfBirth_YYYY,
        month: +dataDetail?.dateOfBirth_MM,
        day: +dataDetail?.dateOfBirth_DD,
      })
    );
    dataForm['firstName'].value = dataDetail?.firstName ?? '';
    dataForm['lastName'].value = dataDetail?.lastName ?? '';
    dataForm['dateOfBirth_DD'].value = dataDetail?.dateOfBirth_DD ?? '';
    dataForm['dateOfBirth_MM'].value = dataDetail?.dateOfBirth_MM ?? '';
    dataForm['dateOfBirth_YYYY'].value = dataDetail?.dateOfBirth_YYYY ?? '';
    dataForm['firstNameFurigana'].value = dataDetail?.firstNameFurigana ?? '';
    dataForm['lastNameFurigana'].value = dataDetail?.lastNameFurigana ?? '';
    dataForm['gender'].value = dataDetail?.sex ?? 'not_set';
    dataForm['email'].value = dataDetail?.email ?? '';
    dataForm['zipCode'].value = dataDetail?.zipCode ?? '';
    dataForm['phoneNumber'].value = dataDetail?.phoneNumber ?? '';
    dataForm['address'].value = dataDetail?.address ?? '';
    dataForm['highestEducation'].value = dataDetail?.highestEducation ?? '';
    dataForm['graduatedYear'].value = dataDetail?.graduatedYear ?? '';
    dataForm['major'].value = dataDetail?.major ?? '';
    dataForm['certificates'].value = dataDetail?.certificates ?? '';
    dataForm['workHistory'].value = dataDetail?.workHistory ?? '';
    dataForm['employeeStatus'].value = dataDetail?.employeeStatus;
    dataForm['employeeCode'].value = dataDetail?.employeeCode;
    dataForm['employeeCompanyEntranceDate'].value = moment(dataDetail?.employeeCompanyEntranceDate)?.format(
      'YYYY-MM-DD'
    );
  };
  // validate Form
  const [showErrorFindAddress, setShowErrorFindAddress] = useState('');
  const [showErrorPhone, setShowErrorPhone] = useState('');
  const [showErrorZipcode, setShowErrorZipcode] = useState('');
  const [showErrorEmail, setShowErrorEmail] = useState('');
  const [urlFilePdfCvAfterChange, setUrlFilePdfCvAfterChange] = useState('');
  const [urlFilePdfHistoryAfterChange, setUrlFilePdfHistoryAfterChange] = useState('');

  const getDataAddress = async () => {
    let search = false;
    await new Promise((resolve, reject) => {
      get(document.forms['employeeData']['zipCode'].value, function (address) {
        search = true;

        if (address) {
          document.forms['employeeData']['address'].value = `${address.prefecture ?? ''} ${address.city ?? ''} ${
            address.area ?? ''
          } ${address.street ?? ''}`;
          setShowErrorFindAddress('');
          resolve(address);
        }
      });

      // reject after 4 seconds
      setTimeout(() => {
        resolve('Operation timed out.');
      }, 4000);
    });

    if (!search) {
      setShowErrorFindAddress(CONSTANT.ADDRESS_NOT_FOUND);
    }
  };

  const clearErrorMessage = () => {
    setShowErrorFindAddress('');
    setShowErrorZipcode('');
    setShowErrorEmail('');
    setShowErrorPhone('');
    // reset message some field require
    validatesRef?.current?.firstName?.setCustomValidity('');
    validatesRef?.current?.lastName?.setCustomValidity('');
    validatesRef?.current?.dateOfBirth_YYYY?.setCustomValidity('');
    validatesRef?.current?.dateOfBirth_MM?.setCustomValidity('');
    validatesRef?.current?.dateOfBirth_DD?.setCustomValidity('');
    validatesRef?.current?.email?.setCustomValidity('');
    validatesRef?.current?.employeeCode?.setCustomValidity('');
    validatesRef?.current?.employeeCompanyEntranceDate?.setCustomValidity('');
  };

  const onHandleCancel = () => {
    clearErrorMessage();
    fillDataDetail();
    setUrlFilePdfCvAfterChange('');
    setUrlFilePdfHistoryAfterChange('');
    // reset select file fpf
    const fileFields = document.querySelectorAll('.js-fileField');
    fileFields.forEach((fileField, index) => {
      const fileInput = fileField.querySelector('.fileField__input');
      const fileName = fileField.querySelector('.fileField__name');

      const resetSelectFile = () => {
        fileInput.value = '';
        fileField.classList.remove('is-fileSet');
        fileName.textContent = '選択されていません';
        fileName.removeAttribute('tabindex');
      };
      const fillDataFilePdf = async () => {
        if (dataDetail?.pdfCV && index === 0) {
          fileField.classList.add('is-fileSet');
          fileName.textContent = '履歴書（PDF）';
          fileName.tabIndex = '0';
        } else if (!dataDetail?.pdfCV && index === 0) {
          resetSelectFile();
        }
        if (dataDetail?.pdfWorkHistory && index === 1) {
          fileField.classList.add('is-fileSet');
          fileName.textContent = '職務経歴書（PDF）';
          fileName.tabIndex = '0';
        } else if (!dataDetail?.pdfWorkHistory && index === 1) {
          resetSelectFile();
        }
      };
      fillDataFilePdf();
    });
    //
  };

  const validateInput = () => {
    let validatedItem = true;
    // Zip code
    const trueZipcode = validateZipcode(document.forms['employeeData']['zipCode'].value);
    if (!trueZipcode) {
      setShowErrorZipcode(CONSTANT.ZIP_CODE_FORMAT);
      validatedItem = false;
    }

    // phone Number
    const truePhone = validatePhoneNumber(document.forms['employeeData']['phoneNumber'].value);

    if (!truePhone) {
      setShowErrorPhone(CONSTANT.PHONE_NUMBER_FORMAT);
      validatedItem = false;
    }

    //validate email
    const isEmail = validateEmail(document.forms['employeeData']['email'].value);
    if (!isEmail) {
      setShowErrorEmail(CONSTANT.ERROR_EMAIL);
      validatedItem = false;
    }
    return validatedItem;
  };
  const customizeInputFile = () => {
    const fileFields = document.querySelectorAll('.js-fileField');

    if (!fileFields.length) {
      return;
    }

    fileFields.forEach((fileField, index) => {
      const fileInput = fileField.querySelector('.fileField__input');
      const fileName = fileField.querySelector('.fileField__name');
      const clearButton = fileField.querySelector('.fileField__clearButton');
      let fileUrl;
      if (dataDetail?.pdfCV && index === 0) {
        fileField.classList.add('is-fileSet');
        fileName.textContent = '履歴書（PDF）';
        fileName.tabIndex = '0';
      }
      if (dataDetail?.pdfWorkHistory && index === 1) {
        fileField.classList.add('is-fileSet');
        fileName.textContent = '職務経歴書（PDF）';
        fileName.tabIndex = '0';
      }

      // ファイルが選択された場合
      fileInput.addEventListener('change', (event) => {
        if (fileInput.files.length) {
          fileField.classList.add('is-fileSet');
          fileName.textContent = event.target.files[0].name;
          fileUrl = URL.createObjectURL(fileInput.files[0]);
          if (index === 0) {
            setFileCV(event.target.files[0]);
            setUrlFilePdfCvAfterChange(fileUrl);
          } else {
            setFileWorkHistory(event.target.files[0]);
            setUrlFilePdfHistoryAfterChange(fileUrl);
          }
          fileName.tabIndex = '0';
        }
      });

      clearButton.addEventListener('click', () => {
        fileInput.value = '';
        fileField.classList.remove('is-fileSet');
        fileName.textContent = '選択されていません';
        fileName.removeAttribute('tabindex');
      });
    });
  };
  useEffect(() => {
    fillDataDetail();
    customizeInputFile();
  }, [dataDetail]);

  return (
    <>
      <div className='panel'>
        <div className='panel__body -large'>
          <form name='employeeData' onKeyDown={handleKeyPress} className='form' onSubmit={(e) => handleUpdate(e)}>
            <table className='detailTable u-spacer-top2'>
              <caption className='detailTable__caption'>プロフィール</caption>
              <tbody>
                <tr>
                  <th>
                    氏名<span className='badge -danger u-spacer-left1'>必須</span>
                  </th>
                  <td>
                    <div className='form__inline'>
                      <input
                        type='text'
                        required
                        onInvalid={(e) => handleRequireInvalid(e)}
                        onChange={(e) => resetRequireValidate(e)}
                        className='textfield -short'
                        placeholder='姓'
                        name='lastName'
                        ref={(e) => (validatesRef.current.lastName = e)}
                      />
                      <input
                        type='text'
                        required
                        onInvalid={(e) => handleRequireInvalid(e)}
                        onChange={(e) => resetRequireValidate(e)}
                        className='textfield -short'
                        placeholder='名'
                        name='firstName'
                        ref={(e) => (validatesRef.current.firstName = e)}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>氏名（フリガナ）</th>
                  <td>
                    <div className='form__inline'>
                      <input type='text' className='textfield -short' placeholder='セイ' name='firstNameFurigana' />
                      <input type='text' className='textfield -short' placeholder='メイ' name='lastNameFurigana' />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>
                    生年月日<span className='badge -danger u-spacer-left1'>必須</span>
                  </th>
                  <td>
                    <div className='form__inline'>
                      <select
                        className='select -x_short'
                        required
                        onInvalid={(e) => handleRequireInvalid(e)}
                        onChange={(e) => handleChangeYear(e)}
                        name='dateOfBirth_YYYY'
                        ref={(e) => (validatesRef.current.dateOfBirth_YYYY = e)}
                      >
                        {optionsYear?.map((item) => (
                          <option key={item.value} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                      </select>
                      年
                      <select
                        required
                        onInvalid={(e) => handleRequireInvalid(e)}
                        onChange={(e) => handleChangeMonth(e)}
                        className='select -x_short'
                        name='dateOfBirth_MM'
                        ref={(e) => (validatesRef.current.dateOfBirth_MM = e)}
                      >
                        {optionsMonth?.map((item) => (
                          <option key={item.value} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                      </select>
                      月
                      <select
                        required
                        onInvalid={(e) => handleRequireInvalid(e)}
                        onChange={(e) => handleChangeDay(e)}
                        className='select -x_short'
                        name='dateOfBirth_DD'
                        ref={(e) => (validatesRef.current.dateOfBirth_DD = e)}
                      >
                        {optionsDate?.map((item) => (
                          <option key={item.value} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                      </select>
                      日{age ? age + '歳' : ''}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>
                    性別<span className='badge -danger u-spacer-left1'>必須</span>
                  </th>
                  <td>
                    <div className='radio-group'>
                      <label className='radio'>
                        <input type='radio' name='gender' value='male' className='radio__input' defaultChecked />
                        <span className='radio__label'>男性</span>
                      </label>
                      <label className='radio'>
                        <input type='radio' name='gender' value='female' className='radio__input' />
                        <span className='radio__label'>女性</span>
                      </label>
                      <label className='radio'>
                        <input type='radio' name='gender' value='not_set' className='radio__input' />
                        <span className='radio__label'>回答しない</span>
                      </label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>
                    メールアドレス<span className='badge -danger u-spacer-left1'>必須</span>
                  </th>
                  <td>
                    <div className='flex gap-[20px] items-center'>
                      <input
                        required
                        onInvalid={(e) => handleRequireInvalid(e)}
                        onChange={(e) => e.target.setCustomValidity('')}
                        name='email'
                        className='textfield'
                        placeholder='例）taro@example.com'
                        ref={(e) => (validatesRef.current.email = e)}
                      />
                      {showErrorEmail && <span className='text-[red] customError'>{showErrorEmail}</span>}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>電話番号</th>
                  <td>
                    <div className='form__inline'>
                      <input type='text' className='textfield -short' name='phoneNumber' />
                      {showErrorPhone && <span className='text-[red] customError'>{showErrorPhone}</span>}
                    </div>
                    <p className='detailTable__note'>※ハイフンなしで入力してください。</p>
                  </td>
                </tr>
                <tr>
                  <th>郵便番号</th>
                  <td>
                    <div className='form__inline'>
                      <input
                        name='zipCode'
                        type='text'
                        placeholder='郵便番号を入力してください'
                        maxLength={8}
                        className='textfield -short'
                        onChange={() => setShowErrorFindAddress('')}
                      />
                      <button onClick={() => getDataAddress()} type='button' className='button -secondary'>
                        住所自動入力
                      </button>
                      {showErrorFindAddress && <span className='text-[red]'>{showErrorFindAddress}</span>}
                      {showErrorZipcode && <span className='text-[red] customError'>{showErrorZipcode}</span>}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>住所</th>
                  <td>
                    <input type='text' name='address' className='textfield' />
                  </td>
                </tr>
              </tbody>
            </table>
            <table className='detailTable'>
              <caption className='detailTable__caption'>業務情報</caption>
              <tr>
                <th>
                  ステータス<span className='badge -danger u-spacer-left1'>必須</span>
                </th>
                <td>
                  <div className='radio-group'>
                    <label className='radio'>
                      <input
                        type='radio'
                        name='employeeStatus'
                        className='radio__input'
                        value={'在職中'}
                        defaultChecked
                      />
                      <span className='radio__label'>在職中</span>
                    </label>
                    <label className='radio'>
                      <input type='radio' name='employeeStatus' className='radio__input' value={'休職中'} />
                      <span className='radio__label'>休職中</span>
                    </label>
                    <label className='radio'>
                      <input type='radio' name='employeeStatus' className='radio__input' value={'退職済み'} />
                      <span className='radio__label'>退職済み</span>
                    </label>
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  従業員ID<span className='badge -danger u-spacer-left1'>必須</span>
                </th>
                <td>
                  <input
                    required
                    type='text'
                    className='textfield -short'
                    name='employeeCode'
                    onInvalid={(e) => handleRequireInvalid(e)}
                    onChange={(e) => resetRequireValidate(e)}
                    ref={(e) => (validatesRef.current.employeeCode = e)}
                  />
                  <p className='detailTable__note'>※貴社のフォーマットに合わせてご記入ください</p>
                </td>
              </tr>
              <tr>
                <th>
                  入社年月日<span className='badge -danger u-spacer-left1'>必須</span>
                </th>
                <td>
                  <input
                    required
                    type='date'
                    className='textfield -short'
                    onInvalid={(e) => handleRequireInvalid(e)}
                    onChange={(e) => resetRequireValidate(e)}
                    name='employeeCompanyEntranceDate'
                    ref={(e) => (validatesRef.current.employeeCompanyEntranceDate = e)}
                  />
                </td>
              </tr>
            </table>
            <table className='detailTable'>
              <caption className='detailTable__caption'>学歴・職歴</caption>
              <tbody>
                <tr>
                  <th>最終学歴</th>
                  <td>
                    <div className='form__inline'>
                      <select className='select -short' name='highestEducation'>
                        <option value=''>選択してください</option>
                        <option value='大学院了(博士)'>大学院了(博士)</option>
                        <option value='大学院了(修士)'>大学院了(修士)</option>
                        <option value='大学卒'>大学卒</option>
                        <option value='短大卒'>短大卒</option>
                        <option value='高専卒'>高専卒</option>
                        <option value='専門学校卒'>専門学校卒</option>
                        <option value='専門学校卒'>高校卒</option>
                        <option value='その他'>その他</option>
                      </select>
                      <select className='select -x_short u-spacer-left1' name='graduatedYear'>
                        {optionsYearGraduation?.map((item) => (
                          <option key={item.value}>{item.value}</option>
                        ))}
                      </select>
                      年卒業
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>学校名・学部・学科</th>
                  <td>
                    <input
                      name='major'
                      type='text'
                      className='textfield'
                      placeholder='例）テスト大学　経営学部　経営学科'
                    />
                  </td>
                </tr>
                <tr>
                  <th>保有資格</th>
                  <td>
                    <textarea name='certificates' className='textfield' cols='40' rows='4'></textarea>
                    <p className='detailTable__note'>
                      例）普通自動車免許あり（20xx年△月取得）、TOEIC ◯点（20xx年△月取得）
                    </p>
                  </td>
                </tr>
                <tr>
                  <th>職務経歴（テキスト）</th>
                  <td>
                    <textarea name='workHistory' className='textfield' cols='40' rows='4'></textarea>
                    <p className='detailTable__note'>
                      例）AAA株式会社 営業部 △△△△を販売（20xx年△月〜20xx年△月）
                      <br />
                      株式会社BBB 営業企画部 マネージャー 企画立案・チームメンバーのマネジメント (20xx年△月〜現在)
                    </p>
                  </td>
                </tr>
                <tr>
                  <th>履歴書（PDF）</th>
                  <td>
                    <div className='fileField js-fileField' value='pdfCV'>
                      <label className='button -secondary fileField__uploadButton'>
                        ファイルを選択
                        <input type='file' accept='.pdf' name='pdfCV' className='fileField__input' />
                      </label>
                      <div
                        className='fileField__name'
                        role='button'
                        id='pdfCV'
                        aria-hidden
                        onClick={() =>
                          document.getElementById('pdfCV').textContent !== '選択されていません'
                            ? openNewTabPreviewPDF(
                                urlFilePdfCvAfterChange,
                                id,
                                CONSTANT.TYPE_PDF_CV,
                                CONSTANT.EMPLOYEE_SETTING
                              )
                            : null
                        }
                      >
                        選択されていません
                      </div>
                      <button
                        onClick={() => setFileCV('')}
                        type='button'
                        className='button -secondary fileField__clearButton'
                      >
                        クリア
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>職務経歴書（PDF）</th>
                  <td>
                    <div className='fileField js-fileField' value='pdfWorkHistory'>
                      <label className='button -secondary fileField__uploadButton'>
                        ファイルを選択
                        <input type='file' accept='.pdf' name='pdfWorkHistory' className='fileField__input' />
                      </label>
                      <div
                        className='fileField__name'
                        id='pdfWorkHistory'
                        role='button'
                        aria-hidden
                        onClick={() =>
                          document.getElementById('pdfWorkHistory').textContent !== '選択されていません'
                            ? openNewTabPreviewPDF(
                                urlFilePdfHistoryAfterChange,
                                id,
                                CONSTANT.TYPE_PDF_WORKHISTORY,
                                CONSTANT.EMPLOYEE_SETTING
                              )
                            : null
                        }
                      >
                        選択されていません
                      </div>
                      <button
                        onClick={() => setFileWorkHistory('')}
                        type='button'
                        className='button -secondary fileField__clearButton'
                      >
                        クリア
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <table className='detailTable'>
              <caption className='detailTable__caption'>表示設定</caption>
              <tr>
                <th>表示ステータス</th>
                <td>
                  <label className='checkbox'>
                    <input
                      type='checkbox'
                      name='hiddenInList'
                      defaultChecked={dataDetail?.hiddenInList}
                      className='checkbox__input'
                    />
                    <span className='checkbox__label'>応募者一覧から非表示にする</span>
                  </label>
                </td>
              </tr>
            </table>
            <div className='u-spacer-top4'>
              <div className='button-wrapper -center'>
                <button onClick={() => onHandleCancel()} type='button' className='button -default'>
                  キャンセル
                </button>
                <button type='submit' disabled={loading} className='button -primary'>
                  {loading && <i className='fa fa-spinner fa-spin'></i>}保存
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default EmployeeBasic;
