import React, { useEffect, useState } from 'react';
import { changNewPassword } from '../../../../../services/RecruiterService';
import { useDispatch } from 'react-redux';
import { setTagNameHeader } from '../../../../redux/reducer';
import { resetRequireValidate, handleRequireInvalid } from '../../../../common/FuntionCommon';
import { toast } from '../../../../../utils/notification';
import * as CONSTANT from '../../../../common/constant';

const ChangeNewPassword = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setTagNameHeader(''));
  }, []);
  const [loading, setLoading] = useState(false);
  const handleChangeNewPassword = async (e) => {
    setLoading(true);
    e.preventDefault();
    // const email = JSON.parse(localStorage.getItem('dataSetting'));
    const data = await changNewPassword(
      // email?.email,
      document.forms['changePw']['oldPw'].value,
      document.forms['changePw']['newPw'].value
    );
    if (data) {
      toast('success', CONSTANT.CHANGE_PASSWORD_SUCCESSFULLY);
    }
    setLoading(false);
  };
  const handleCancel = () => {
    document.forms['changePw'].reset();
  };

  const [disableBtn, setDisableBtn] = useState(false);

  const validatePW = (event) => {
    event.target.setCustomValidity('');
    var pw = document.getElementById('password').value;
    var pwConfirm = document.getElementById('confirmPassword').value;
    var regularExpression = /^(?=.*\d)(?=.*[a-zA-Z])[a-zA-Z0-9]{8,}$/;
    if (regularExpression?.test(pw)) {
      setDisableBtn(false);
      document.getElementById('message').innerHTML = '';
    } else {
      setDisableBtn(true);
      document.getElementById('message').innerHTML = CONSTANT.PASSWORD_RULE;
    }
    if (pwConfirm && pw !== pwConfirm) {
      setDisableBtn(true);
      document.getElementById('messageConfirm').innerHTML = CONSTANT.PASSWORD_CONFIRM_ERROR;
    } else {
      document.getElementById('messageConfirm').innerHTML = '';
      setDisableBtn(false);
    }
  };

  const handleChangePW = (event) => {
    event.target.setCustomValidity('');
    var pw1 = document.getElementById('password').value;
    var pw2 = document.getElementById('confirmPassword').value;
    if (pw1 !== pw2) {
      setDisableBtn(true);
      document.getElementById('messageConfirm').innerHTML = CONSTANT.PASSWORD_CONFIRM_ERROR;
    } else {
      document.getElementById('messageConfirm').innerHTML = '';
      setDisableBtn(false);
    }
  };

  function showPw() {
    var x = document.getElementById('password');
    if (x.type === 'password') {
      x.type = 'text';
    } else {
      x.type = 'password';
    }
  }
  function showConfirmPw() {
    var x = document.getElementById('confirmPassword');
    if (x.type === 'password') {
      x.type = 'text';
    } else {
      x.type = 'password';
    }
  }
  return (
    <>
      <div className='pageHeader'>
        <h1 className='pageHeader__title'>パスワード変更</h1>
      </div>

      <div className='panel'>
        <div className='panel__body -large'>
          <form name='changePw' onSubmit={(e) => handleChangeNewPassword(e)} className='form'>
            <table className='detailTable'>
              <tr>
                <th>
                  現在のパスワード<span className='badge -danger u-spacer-left1'>必須</span>
                </th>
                <td>
                  <input
                    required
                    onInvalid={(e) => handleRequireInvalid(e)}
                    onChange={(e) => resetRequireValidate(e)}
                    type='password'
                    name='oldPw'
                    className='textfield'
                  />
                </td>
              </tr>
              <tr>
                <th>
                  新しいパスワード<span className='badge -danger u-spacer-left1'>必須</span>
                </th>
                <td className='items-center relative'>
                  <input
                    required
                    onInvalid={(e) => handleRequireInvalid(e)}
                    onChange={(e) => validatePW(e)}
                    type='password'
                    name='newPw'
                    className='textfield'
                    id='password'
                  />
                  <span
                    style={{ transform: 'translateY(-50%)' }}
                    role='button'
                    aria-hidden
                    onClick={showPw}
                    className='fa fa-fw fa-eye field-icon toggle-password absolute right-[20px] cursor-pointer top-[50%]'
                  ></span>
                </td>
                <p
                  id='message'
                  style={{
                    color: 'red',
                    whiteSpace: 'pre-line',
                    height: '73px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                ></p>
              </tr>
              <tr>
                <th>
                  新しいパスワード(確認用)<span className='badge -danger u-spacer-left1'>必須</span>
                </th>
                <td className='items-center relative'>
                  <input
                    required
                    onChange={(e) => handleChangePW(e)}
                    type='password'
                    name='confirmNewPw'
                    className='textfield'
                    id='confirmPassword'
                  />
                  <span
                    style={{ transform: 'translateY(-50%)' }}
                    role='button'
                    aria-hidden
                    onClick={showConfirmPw}
                    className='fa fa-fw fa-eye field-icon toggle-password absolute right-[20px] cursor-pointer top-[50%]'
                  ></span>
                </td>
                <p
                  id='messageConfirm'
                  style={{
                    color: 'red',
                    whiteSpace: 'pre-line',
                    height: '73px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                ></p>
              </tr>
            </table>
            <div className='u-spacer-top4'>
              <div className='button-wrapper -center'>
                <button onClick={handleCancel} type='button' className='button -default'>
                  キャンセル
                </button>
                <button disabled={disableBtn || loading} type='submit' className='button -primary'>
                  {loading && <i className='fa fa-spinner fa-spin mr-[10px]'></i>}保存
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default ChangeNewPassword;
