import React, { useState } from 'react';
import { toast } from '../../../../../../utils/notification';
import { roleHrUser } from '../../../../../common/FuntionCommon';
import { resendMailUser } from '../../../../../../services/RecruiterService';
import { useParams } from 'react-router-dom';
import * as CONSTANT from '../../../../../common/constant';

const ModalResendMailInviteHR = ({ dataDetail }) => {
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const resendEmail = async () => {
    setLoading(true);
    const data = await resendMailUser(id);
    setLoading(false);
    const closeButton = document.querySelector('.js-modal-close');
    closeButton.click();
    if (data) {
      toast('success', CONSTANT.SEND_MAIL_SUCCESSFULLY);
    }
  };
  return (
    <>
      <div className='modal' id='resend-invitation'>
        <div className='modal__inner'>
          <div className='modal__content'>
            <div className='modal__header'>
              <p className='modal__title'>招待メールを再送信</p>
            </div>
            <div className='modal__body'>
              <p>以下の内容で、招待メールが送られます。</p>
              <table className='detailTable u-spacer-top2'>
                <tbody>
                  <tr>
                    <th>氏名</th>
                    <td>{dataDetail?.lastName + ' ' + dataDetail?.firstName}</td>
                  </tr>
                  <tr>
                    <th>メールアドレス</th>
                    <td>{dataDetail?.email}</td>
                  </tr>
                  <tr>
                    <th>権限</th>
                    <td>{roleHrUser(dataDetail?.role)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='modal__footer'>
              <div className='button-wrapper -end'>
                <button type='button' className='button -default js-modal-close'>
                  キャンセル
                </button>
                <button
                  onClick={() => resendEmail()}
                  disabled={loading}
                  type='button'
                  className='button -default -primary'
                >
                  {loading && <i className='fa fa-spinner fa-spin mr-[10px]'></i>}送信
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className='modal__overlay'></div>
      </div>
    </>
  );
};
export default ModalResendMailInviteHR;
