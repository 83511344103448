import React, { useEffect, useState } from 'react';
import { StrictMode } from 'react';
import { Skeleton } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  chatSlice,
  setCurrentActiveGroupData,
  setListAnonymousElement,
  setListPublicElement,
  setListDataGroupPublic,
  setListGroupChatAnonymous,
  setTypeChat,
} from '../../../../redux/reducerChat';
import {
  getApplyLastMessages,
  getListChatAboutJob,
  recruiterGetContactLastMessages,
  unReadMessageApplyUser,
  unReadMessageContactUser,
} from '../../../../../services/ChatService';
import { socketIO } from '../../../../../services/socket';
import { APPLICANT_EXIT, CHAT_TYPE_ANONYMOUS, CHAT_TYPE_PUBLIC, JOIN_WAITING } from '../../../../common/constant';

const LeftBar = ({ loadingLogin }) => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const {
    socketConnect,
    listDataGroupPublic,
    currentActiveGroup,
    jobIDinSelectChat,
    typeChat,
    contentMessageSend,
    listGroupChatAnonymous,
  } = useSelector(chatSlice);

  const getLastMessageApply = async (listGroupId) => {
    const data = await getApplyLastMessages(listGroupId);
    return data;
  };
  const getLastMessageAnonymous = async (listGroupId) => {
    const data = await recruiterGetContactLastMessages(listGroupId);
    return data;
  };

  // When select jobId, refetch list LeftBar
  const dataLoading = async (dataListChatByJob) => {
    const getDataFinish = async (listGroups, type) => {
      if (!Array.isArray(listGroups)) {
        return null;
      }
      const listGroupIds = listGroups.map((group) => group._id);

      const lastMessageFromBE =
        type === CHAT_TYPE_PUBLIC
          ? await getLastMessageApply(listGroupIds)
          : await getLastMessageAnonymous(listGroupIds);

      const finishData = listGroups?.map((item) => {
        const lastMessagesForTheGroup = lastMessageFromBE?.[item?._id]?.messages;

        return {
          ...item,
          lastMessage: lastMessagesForTheGroup[lastMessagesForTheGroup.length - 1],
          unReadMessage: lastMessageFromBE?.[item?._id]?.unread,
        };
      });

      await finishData?.sort(function (a, b) {
        if (!a?.lastMessage?.createTime) return 1;
        if (!b?.lastMessage?.createTime) return -1;
        return new Date(b?.lastMessage?.createTime) - new Date(a?.lastMessage?.createTime);
      });
      return finishData;
    };
    const finishDataPublic = await getDataFinish(dataListChatByJob?.applyGroups, CHAT_TYPE_PUBLIC);
    const finishDataAnonymous = await getDataFinish(dataListChatByJob?.contactGroups, CHAT_TYPE_ANONYMOUS);

    if (Array.isArray(finishDataPublic)) {
      dispatch(setListDataGroupPublic(finishDataPublic));
    }
    if (Array.isArray(finishDataAnonymous)) {
      dispatch(setListGroupChatAnonymous(finishDataAnonymous));
    }

    setLoading(false);
  };

  const getDataListChatAboutJob = async () => {
    if(!socketConnect){
      return;
    }

    const data = await getListChatAboutJob(jobIDinSelectChat);

    if (data) {
      dataLoading(data); // get LastMessage and then mapping those into listChatByJob

      const listGroupIdPublic = (await data?.applyGroups?.map((item) => item?._id)) ?? [];
      const listGroupIdAnonymous = (await data?.contactGroups?.map((item) => item?._id)) ?? [];

      // Reset Right Bar when lose focus on leftbar
      if (
        currentActiveGroup?._id &&
        !listGroupIdPublic.includes(currentActiveGroup?._id) &&
        !listGroupIdAnonymous.includes(currentActiveGroup?._id)
      ) {
        dispatch(setCurrentActiveGroupData({}));
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    getDataListChatAboutJob();
  }, [jobIDinSelectChat, loadingLogin]);

  // listen event when user apply
  useEffect(() => {
    socketIO.on('sendProfile', async (newGroup) => {
      if (newGroup) {
        if (newGroup?.jobId === jobIDinSelectChat || jobIDinSelectChat === '') {
          await getDataListChatAboutJob();
        }
      }
    });
    return () => {
      socketIO.off('sendProfile');
    };
  });

  // listen event when contact user apply
  useEffect(() => {
    socketIO.on('newContactGroup', async (newGroup) => {
      if (newGroup) {
        await getDataListChatAboutJob();
      }
    });
    return () => {
      socketIO.off('newContactGroup');
    };
  });

  // When anonymous setting avatar and nickname for contact group
  useEffect(() => {
    socketIO.on('contactGroupInfo', async (message) => {
      if (message) {
        await getDataListChatAboutJob(message);
      }
    });
    return () => {
      socketIO.off('contactGroupInfo');
    };
  });

  // set last message when send message
  useEffect(() => {
    const listChatClone = typeChat === CHAT_TYPE_PUBLIC ? listDataGroupPublic : listGroupChatAnonymous;

    let newItem = {};
    let indexItem = {};
    const finishData = listChatClone?.map((item, index) => {
      if (item?._id === contentMessageSend?.activeGroupChatId) {
        const itemClone = { ...item };
        itemClone.lastMessage = {
          ...itemClone.lastMessage,
          type: 'text',
          content: contentMessageSend?.messageInput,
          deleted: contentMessageSend.deleted,
        };
        newItem = itemClone;
        indexItem = index;

        return itemClone;
      }
      return item;
    });
    finishData.splice(indexItem, 1);
    finishData.unshift(newItem);

    if (typeChat === CHAT_TYPE_PUBLIC) dispatch(setListDataGroupPublic(finishData));
    else dispatch(setListGroupChatAnonymous(finishData));
  }, [contentMessageSend]);

  const { evaluateInterviewApplicant } = useSelector(chatSlice);
  // set data right bar after update info evaluation
  useEffect(() => {
    if (evaluateInterviewApplicant && Object.keys(evaluateInterviewApplicant)?.length !== 0) {
      const listChatClone = typeChat === CHAT_TYPE_PUBLIC ? listDataGroupPublic : listGroupChatAnonymous;
      const data = listChatClone?.map((item) => {
        if (item?.application && item?.application?._id === evaluateInterviewApplicant?._id) {
          const itemClone = { ...item };
          itemClone.application = {
            ...item.application,
            evaluationStatus: evaluateInterviewApplicant.evaluationStatus,
            note: evaluateInterviewApplicant.note,
            rating: evaluateInterviewApplicant.rating,
            progress: evaluateInterviewApplicant.progress,
          };
          return itemClone;
        }
        return item;
      });

      if (Array.isArray(data)) {
        typeChat === CHAT_TYPE_PUBLIC
          ? dispatch(setListDataGroupPublic(data))
          : dispatch(setListGroupChatAnonymous(data));
      }
    }
  }, [evaluateInterviewApplicant]);

  // set data unreadMessage FE after onclick chat user
  useEffect(() => {
    const updateWant = { groupId: currentActiveGroup?._id, updating: { unReadMessage: 0 } };
    if (typeChat === CHAT_TYPE_PUBLIC) {
      dispatch(setListPublicElement(updateWant));
      if (currentActiveGroup?.unReadMessage > 0) {
        // call backend
        unReadMessageApplyUser(currentActiveGroup?._id, currentActiveGroup?.lastMessage?._id);
      }
    } else {
      dispatch(setListAnonymousElement(updateWant));
      if (currentActiveGroup?.unReadMessage > 0) {
        // call backend
        unReadMessageContactUser(currentActiveGroup?._id, currentActiveGroup?.lastMessage?._id);
      }
    }
  }, [currentActiveGroup]);

  let loadingSkeleton = [];
  for (let i = 0; i < 10; i++) {
    loadingSkeleton.push(
      <Skeleton
        key={i}
        className='loading-left-bar-chat'
        avatar
        paragraph={{
          rows: 1,
        }}
      />
    );
  }
  const collapseChatSidebarBlock = () => {
    const buttons = document.querySelectorAll('.js-chatSidebarCollapseButton');

    if (!buttons.length) {
      return;
    }

    buttons.forEach((button) => {
      button.addEventListener('click', (event) => {
        const chatListBlock = event.target.closest('.chatSidebar__block');
        chatListBlock.classList.toggle('is-collapsed');
      });
    });
  };
  useEffect(() => {
    collapseChatSidebarBlock();
  });

  // auto scroll on element active
  useEffect(() => {
    if (currentActiveGroup?._id && !loading) {
      const element = document.getElementById(currentActiveGroup?._id);
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [currentActiveGroup?._id, loading]);

  const defineLeftBarLastMessage = (lastMessage) => {
    switch (lastMessage?.type) {
      case 'text':
        return lastMessage?.deleted ? 'メッセージは投稿者によって削除されました' : lastMessage?.content;
      case 'interview':
        return '面接日時が設定されました';
      case 'profile':
        return '応募プロフィールが送信されました。';
      case 'pdfCV':
        return lastMessage?.deleted ? 'メッセージは投稿者によって削除されました' : '履歴書が送信されました。';
      case 'pdfWorkHistory':
        return lastMessage?.deleted ? 'メッセージは投稿者によって削除されました' : '職務経歴書が送信されました。';
      default:
        return '';
    }
  };

  return (
    <StrictMode>
      {loading ? (
        <div className='flex flex-col left-bar-chat'>{loadingSkeleton}</div>
      ) : (
        <div className='chatSidebar'>
          <div className='chatSidebar__block'>
            <div className='chatSidebar__head'>
              <button type='button' className='iconButton -expand js-chatSidebarCollapseButton'></button>
              <p className='chatSidebar__headTitle'>応募者（{listDataGroupPublic?.length}人）</p>
            </div>
            <div className='chatSidebar__body'>
              {listDataGroupPublic?.map((item) => {
                const itemData =
                  item?.applyProfile && Object.keys(item?.applyProfile)?.length > 0
                    ? item?.applyProfile
                    : item?.employeeId ?? item?.applicantReplicaId;
                return (
                  <div
                    id={item?._id}
                    role='button'
                    aria-hidden
                    key={item?._id}
                    className={`user ${
                      currentActiveGroup?._id === item?._id ? '-current' : item?.unReadMessage > 0 ? '-unread' : ''
                    } `}
                    onClick={() => (
                      dispatch(setCurrentActiveGroupData(item ?? {})), dispatch(setTypeChat(CHAT_TYPE_PUBLIC))
                    )}
                  >
                    {itemData?.avatar ? (
                      <img src={`${itemData?.avatar}`} alt='' className='avatar' />
                    ) : (
                      <img src='avatar-default.svg' alt='' className='avatar' />
                    )}
                    <div className='user__body'>
                      <div className='user__info'>
                        {item?.application && evaluateInterviewApplicant?._id === item?.application?._id ? (
                          <>
                            {evaluateInterviewApplicant?.evaluationStatus && (
                              <span className='badge -default'>{evaluateInterviewApplicant?.evaluationStatus}</span>
                            )}
                          </>
                        ) : (
                          <>
                            {item?.application && item?.application?.evaluationStatus && (
                              <span className='badge -default'>{item?.application?.evaluationStatus}</span>
                            )}
                          </>
                        )}
                        <p className='user__name'>{itemData?.lastName + ' ' + itemData?.firstName}</p>
                        {evaluateInterviewApplicant?._id === item?.application?._id ? (
                          <>
                            {evaluateInterviewApplicant?.rating > 0 && (
                              <span className='star'>{evaluateInterviewApplicant?.rating}</span>
                            )}
                          </>
                        ) : (
                          <>
                            {item?.application?.rating && item?.application?.rating > 0 && (
                              <span className='star'>{item?.application?.rating}</span>
                            )}
                          </>
                        )}
                      </div>
                      <p className='user__message'>{defineLeftBarLastMessage(item.lastMessage)}</p>
                    </div>
                    {item?.status === APPLICANT_EXIT && <span className='badge -rounded -dark'>退出</span>}
                    {item?.status === JOIN_WAITING && <span className='badge -rounded -accent'>招待</span>}
                    {/* <span className="badge -rounded -accent">招待</span> */}
                  </div>
                );
              })}
            </div>
          </div>
          <div className='chatSidebar__block'>
            <div className='chatSidebar__head'>
              <button type='button' className='iconButton -expand js-chatSidebarCollapseButton'></button>
              <p className='chatSidebar__headTitle'>コンタクト（{listGroupChatAnonymous?.length}人）</p>
            </div>
            <div className='chatSidebar__body'>
              {listGroupChatAnonymous?.map((item) => (
                <div
                  role='button'
                  id={item._id}
                  aria-hidden
                  className={`user ${
                    currentActiveGroup?._id === item?._id ? '-current' : item?.unReadMessage > 0 ? '-unread' : ''
                  } `}
                  key={item?._id}
                  onClick={() => (
                    dispatch(setCurrentActiveGroupData(item ?? {})), dispatch(setTypeChat(CHAT_TYPE_ANONYMOUS))
                  )}
                >
                  {item?.avatar ? (
                    <img src={`${item?.avatar}`} alt='' className='avatar' />
                  ) : (
                    <img src='avatar-default.svg' alt='' className='avatar' />
                  )}
                  <div className='user__body'>
                    <div className='user__info'>
                      <p className='user__name'> {item?.nickname}</p>
                    </div>
                    <p className='user__message'>
                      {item?.lastMessage?.deleted
                        ? 'メッセージは投稿者によって削除されました'
                        : item?.lastMessage?.content}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </StrictMode>
  );
};
export default LeftBar;
