
import { toast } from '../utils/notification';
import { fetch, socketIO } from './socket';






export const loginByToken = async () => {
  try {
    const token = JSON.parse(localStorage.getItem('token'));
    const userAgent = navigator.userAgent;
    let browserType = '';
    if (userAgent.indexOf('Chrome') > -1) {
      browserType = 'Google Chrome';
    } else if (userAgent.indexOf('Firefox') > -1) {
      browserType = 'Mozilla Firefox';
    } else if (userAgent.indexOf('Safari') > -1) {
      browserType = 'Apple Safari';
    } else if (userAgent.indexOf('MSIE') > -1 || userAgent.indexOf('Trident') > -1) {
      browserType = 'Microsoft Internet Explorer';
    } else {
      browserType = 'Unknown browser';
    }

    const data = await fetch('loginByToken', {
      side: 'Recruiter',
      os: 'OS X',
      browser: browserType,
      environment: userAgent,
      token,
    });

    return data;
  } catch (error) {
    console.error("LogIn ERROR: ", error)

    return;
  }
};


export const getApplyLastMessages = async (groupId) => {
  if (groupId) {
    try {
      const data = await fetch('recruiterGetApplyLastMessages', {
        applyGroupIds: [...groupId],
      });

      return data;
    } catch (error) {
      return;
    }
  } else {
    toast('error', 'GroupId must not null');
  }
};
export const sendMessage = async (groupId, content) => {
  try {
    const data = await fetch('sendApplyMessage', {
      to: groupId,
      type: 'text',
      content,
    });
    return data;
  } catch (error) {
    return;
  }
};
export const recruiterGetApplyHistoryMessages = async (groupId, existCount) => {
  try {
    const data = await fetch('recruiterGetApplyHistoryMessages', {
      groupId,
      existCount,
    });
    return data;
  } catch (error) {
    return;
  }
};
export const deleteApplyMessage = async (messageId) => {
  try {
    const data = await fetch('deleteApplyMessage', {
      messageId,
    });
    return data;
  } catch (error) {
    return;
  }
};
export const getListChatAboutJob = async (jobId) => {
  try {
    const data = await fetch('getListChatAboutJob', {
      jobId,
    });
    return data;
  } catch (error) {
    return;
  }
};
export const getApplyGroupBasicInfo = async (groupId) => {
  try {
    const data = await fetch('getApplyGroupBasicInfo', {
      groupId,
    });
    return data;
  } catch (error) {
    return;
  }
};
//contact
export const recruiterGetContactLastMessages = async (groupId) => {
  try {
    const data = await fetch('recruiterGetContactLastMessages', {
      contactGroupIds: [...groupId],
    });

    return data;
  } catch (error) {
    return;
  }
};
export const getApplyLastMessagesContact = async (groupId) => {
  try {
    const data = await fetch('recruiterGetContactLastMessages', {
      contactGroupIds: [...groupId],
    });
    return data;
  } catch (error) {
    return;
  }
};
export const sendMessageAnonymous = async (groupId, content) => {
  try {
    const data = await fetch('recruiterSendContactMessage', {
      to: groupId,
      type: 'text',
      content,
    });
    return data;
  } catch (error) {
    return;
  }
};
export const recruiterGetContactHistoryMessages = async (groupId, existCount) => {
  try {
    const data = await fetch('recruiterGetContactHistoryMessages', {
      groupId,
      existCount,
    });
    return data;
  } catch (error) {
    return;
  }
};
export const deleteContactMessage = async (messageId) => {
  try {
    const data = await fetch('recruiterDeleteContactMessage', {
      messageId,
    });

    return data;
  } catch (error) {
    return;
  }
};
export const unReadMessageApplyUser = async (groupId, lastMessageId) => {
  try {
    const data = await fetch('updateApplyHistory', {
      linkmanId: groupId,
      messageId: lastMessageId,
    });

    return data;
  } catch (error) {
    return;
  }
};
export const unReadMessageContactUser = async (groupId, lastMessageId) => {
  try {
    const data = await fetch('updateContactHistory', {
      linkmanId: groupId,
      messageId: lastMessageId,
    });

    return data;
  } catch (error) {
    return;
  }
};
