import React, { useState } from 'react';
import { RecruiterRegister } from '../../../../services/RecruiterService';
import { Link, useNavigate } from 'react-router-dom';
import { handleRequireInvalid, validateEmail } from '../../../common/FuntionCommon';
import * as CONSTANT from '../../../common/constant';
import { PRIVACY_POLICY, URL_VERIFY_EMAIL } from '../../../../routes/constantRouter';

const Signup = () => {
  const dataStep1 = document.forms['signup1'];
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const handleSignup = async (e) => {
    e.preventDefault();
    if (!dataStep1['check-privacy']?.checked) {
      return;
    }
    setLoading(true);
    setLoading(false);
    if (showErrorEmail) {
      return;
    }
    const data = await RecruiterRegister(dataStep1['email'].value, dataStep1['password'].value);
    if (data) {
      return navigate(URL_VERIFY_EMAIL, { state: { email: dataStep1['email'].value } });
    }
  };

  function showPw() {
    var x = document.getElementById('password');
    if (x.type === 'password') {
      x.type = 'text';
    } else {
      x.type = 'password';
    }
  }
  function showConfirmPw() {
    var x = document.getElementById('confirmPassword');
    if (x.type === 'password') {
      x.type = 'text';
    } else {
      x.type = 'password';
    }
  }

  const [disableBtn, setDisableBtn] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const handleChangePW = (event) => {
    event.target.setCustomValidity('');
    var pw1 = document.getElementById('password').value;
    var pw2 = document.getElementById('confirmPassword').value;
    if (pw1 !== pw2) {
      setDisableBtn(true);
      document.getElementById('messageConfirm').innerHTML = CONSTANT.PASSWORD_CONFIRM_ERROR;
    } else {
      document.getElementById('messageConfirm').innerHTML = '';
      setDisableBtn(false);
    }
  };

  const validatePW = (event) => {
    event.target.setCustomValidity('');
    var pw = document.getElementById('password').value;
    var regularExpression = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/;
    if (regularExpression?.test(pw)) {
      setDisableBtn(false);
      document.getElementById('message').innerHTML = '';
    } else {
      setDisableBtn(true);
      document.getElementById('message').innerHTML = CONSTANT.PASSWORD_RULE;
    }
  };

  const [showErrorEmail, setShowErrorEmail] = useState(false);
  function handleChangeEmail(email) {
    email.target.setCustomValidity('');
    const isEmail = validateEmail(email.target.value);
    setShowErrorEmail(!isEmail);
    const freeEmailProviders = ['gmail.com', 'yahoo.com', 'hotmail.com', 'aol.com', 'outlook.com'];
    const emailDomain = email.target.value.split('@')[1];
    if (freeEmailProviders.includes(emailDomain)) {
      setDisableBtn(true);
      document.getElementById('emailFree').innerHTML = '無料のメールを利用しないでください。';
    } else {
      setDisableBtn(false);
      document.getElementById('emailFree').innerHTML = '';
    }
  }
  const signupContentStep1 = (
    <form name='signup1' onSubmit={(e) => handleSignup(e)} className='form'>
      <fieldset className='form__group'>
        <label htmlFor='email' className='form__label'>
          メールアドレス
        </label>
        <div className='form__field'>
          <input
            required
            onInvalid={(e) => handleRequireInvalid(e)}
            onChange={(e) => handleChangeEmail(e)}
            className='textfield'
            name='email'
          />
          <span id='emailFree' style={{ color: 'red', whiteSpace: 'pre-line' }}></span>
          {showErrorEmail && <span className='text-[red]'>{CONSTANT.ERROR_EMAIL}</span>}
        </div>
      </fieldset>
      <fieldset className='form__group'>
        <label htmlFor='password' className='form__label'>
          パスワード
        </label>
        <div className='form__field flex items-center relative'>
          <input
            required
            onInvalid={(e) => handleRequireInvalid(e)}
            onChange={(e) => validatePW(e)}
            type='password'
            className='textfield'
            name='password'
            id='password'
          />
          <span
            role='button'
            aria-hidden
            onClick={showPw}
            className='fa fa-fw fa-eye field-icon toggle-password absolute right-[20px] cursor-pointer'
          ></span>
        </div>
        <span id='message' style={{ color: 'red', whiteSpace: 'pre-line' }}></span>
      </fieldset>
      <fieldset className='form__group'>
        <label htmlFor='confirm-password' className='form__label'>
          パスワード（確認用）
        </label>
        <div className='form__field flex items-center relative'>
          <input
            required
            onInvalid={(e) => handleRequireInvalid(e)}
            onChange={(e) => handleChangePW(e)}
            type='password'
            className='textfield'
            name='confirm-password'
            id='confirmPassword'
          />
          <span
            role='button'
            aria-hidden
            onClick={showConfirmPw}
            className='fa fa-fw fa-eye field-icon toggle-password absolute right-[20px] cursor-pointer'
          ></span>
        </div>
        <span id='messageConfirm' style={{ color: 'red' }}></span>
      </fieldset>
      <p className='checkbox-wrapper form__group'>
        {/* <Link to={PRIVACY_POLICY}>プライバシーポリシー</Link>に同意してご登録ください。 */}
        <label className='checkbox'>
          <input
            type='checkbox'
            name='check-privacy'
            onChange={(e) => setIsChecked(e.target.checked)}
            checked={isChecked}
            className='checkbox__input'
          />
          <span className='checkbox__label'>
            <a href={`${PRIVACY_POLICY}`} target='_blank' rel='noreferrer'>
              プライバシーポリシー
            </a>
            に同意する
          </span>
        </label>
      </p>
      <div className='form__group'>
        <button
          disabled={disableBtn || !isChecked}
          type='submit'
          className={`button -primary -full ${disableBtn || !isChecked ? '-disabled' : ''}`}
        >
          {loading && <i className='fa fa-spinner fa-spin mr-[10px]'></i>}次へ
        </button>
      </div>
    </form>
  );
  return (
    <>
      <div className='layout__container'>
        <div className='u-spacer-top2 u-spacer-bottom3 u-align-center'>
          <img src='logo.svg' alt='CAERU採用管理' width='300' height='54' />
        </div>

        <div className='stepper'>
          <ol className='stepper__list'>
            <li className={'stepper__item -active'}>
              <span className='stepper__number'>1</span>
              <p className='stepper__text'>アカウント情報入力</p>
            </li>
            <li className={'stepper__item'}>
              <span className='stepper__number'>2</span>
              <p className='stepper__text'>認証コード入力</p>
            </li>
            <li className={'stepper__item '}>
              <span className='stepper__number'>3</span>
              <p className='stepper__text'>アカウント登録完了</p>
            </li>
          </ol>
        </div>
      </div>
      <div className='layout__container -narrow'>
        <div className='panel u-spacer-top3'>
          <div className='panel__body -large'>{signupContentStep1}</div>
        </div>
        <p className='u-spacer-top3 u-align-center'>
          <Link to='/'>ログイン画面に戻る</Link>
        </p>
      </div>
    </>
  );
};
export default Signup;
