import React, { useEffect, useState } from 'react';
import ModalEditProgressInfo from '../../../../../Helper/modalEditProgressInfo';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { HandleSetEvaluateApplicant, getDataProgress } from '../../../../../../services/RecruiterService';
import ModalSubmitSendMail from './ModalSubmitSendMail';
import { toast } from '../../../../../../utils/notification';
import ModalDeleteProgress from './ModalDeleteProgress';
import {
  displayModal,
  resetRequireValidate,
  handleRequireInvalid,
  onHandleChatNewTab,
} from '../../../../../common/FuntionCommon';
import * as CONSTANT from '../../../../../common/constant';
import { URL_API_COMPANY_PROGRESS } from '../../../../../../services';
import useSWR from 'swr';

// 応募情報
const DetailApplication = ({ data, getDataDetail }) => {
  const [itemProgressDelete, setItemProgressDelete] = useState();
  const [typeAction, setTypeAction] = useState('');
  const openModalDelete = (item, record) => {
    setItemProgressDelete(item);
    setItemApplication(record);
  };
  const [dataDetailEvaluation, setDataDetailEvaluation] = useState();
  const arrayApplications = data?.applications;
  const [itemApplication, setItemApplication] = useState('');

  const openPopupEditProgress = async (progressId, record) => {
    await record?.progress?.map((item) => {
      if (item?._id === progressId) {
        setDataDetailEvaluation(item);
      }
      setOpenModalProgress(true);
    });
    setItemApplication(record);
    setTypeAction(CONSTANT.ACTION_EDIT);
  };
  const openModalCreate = (item) => {
    setOpenModalProgress(true);
    setTypeAction(CONSTANT.ACTION_ADD_NEW);
    setItemApplication(item);
  };

  const [openModalProgress, setOpenModalProgress] = useState(false);

  const fillData = (value) => {
    if (typeof value !== 'number') {
      arrayApplications?.map((item, index) => {
        document.forms[`detailApplication${index}`]['appliedDate'].value = moment(item?.appliedDate)?.format(
          'YYYY-MM-DD'
        );
        document.forms[`detailApplication${index}`]['status'].value = item?.evaluationStatus;
        document.forms[`detailApplication${index}`]['evaluationDate'].value = item?.evaluationDate
          ? moment(item?.evaluationDate)?.format('YYYY-MM-DD')
          : '';
        document.forms[`detailApplication${index}`]['rating'].value = item?.rating || '';
        document.forms[`detailApplication${index}`]['comment'].value = item?.note ?? '';
      });
    } else {
      arrayApplications?.map((item, index) => {
        if (value === index) {
          document.forms[`detailApplication${index}`]['appliedDate'].value = moment(item?.appliedDate)?.format(
            'YYYY-MM-DD'
          );
          document.forms[`detailApplication${index}`]['status'].value = item?.evaluationStatus;
          document.forms[`detailApplication${index}`]['evaluationDate'].value = item?.evaluationDate
            ? moment(item?.evaluationDate)?.format('YYYY-MM-DD')
            : '';
          document.forms[`detailApplication${index}`]['rating'].value = item?.rating || '';
          document.forms[`detailApplication${index}`]['comment'].value = item?.note ?? '';
        }
      });
    }
  };
  useEffect(() => {
    fillData();
  }, [data]);

  const { id } = useParams();

  const handleUpdate = async (e, index) => {
    e.preventDefault();
    const payload = {
      appliedDate: document.forms[`detailApplication${index}`]['appliedDate'].value,
      evaluationStatus: document.forms[`detailApplication${index}`]['status'].value,
      evaluationDate: document.forms[`detailApplication${index}`]['evaluationDate'].value,
      rating: document.forms[`detailApplication${index}`]['rating'].value ?? '',
      note: document.forms[`detailApplication${index}`]['comment'].value ?? '',
      jobId: data?.applications?.[index]?.jobId,
    };
    const dataEvaluate = await HandleSetEvaluateApplicant(id, payload);
    if (dataEvaluate) {
      getDataDetail();
      toast('success', CONSTANT.UPDATE_SUCCESSFULLY);
    } else toast('error', CONSTANT.UPDATE_FAIL);
  };

  const onCancel = (value) => {
    fillData(value);
  };

  const showDateFieldOnSelection = () => {
    // 選考ステータス:ラジオボタンの親要素
    const radioGroups = document.querySelectorAll('.js-selectionStatus-radioGroup');

    if (radioGroups.length) {
      radioGroups.forEach((radioGroup) => {
        radioGroup.addEventListener('change', (event) => {
          // 選択されたラジオボタンのテキストを取得
          const selectedText = event.target.nextElementSibling.textContent;

          // 日付を表示エリアとラベル表示する要素を取得
          const selectionResultDateDisplay = event.target.closest('.radio-group').nextElementSibling;
          const selectionResultDateLabel = selectionResultDateDisplay.querySelector('.js-selectionStatus-dateLabel');

          if (selectedText !== '選考中' && selectedText !== null) {
            // 「選考中」以外が選ばれている場合、日付フィールドを表示して
            selectionResultDateDisplay.style.display = 'block';
            selectionResultDateLabel.textContent = `${selectedText}日`;
          } else {
            selectionResultDateDisplay.style.display = 'none';
          }
        });
      });
    }
  };
  useEffect(() => {
    showDateFieldOnSelection();
  }, []);

  useEffect(() => {
    displayModal();
  });
  const [currentApplyRow, setCurrentApply] = useState({});
  const { data: dataProgress } = useSWR(URL_API_COMPANY_PROGRESS, getDataProgress, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  return (
    <>
      {arrayApplications?.map((record, index) => (
        <div key={record?._id} className='panel'>
          <div className='panel__body -large'>
            <section className='panelBox'>
              <div className='panelBox__header'>
                <div className='panelBox__headerStart'>
                  <h2 className='title -secondary'>{record.jobTitle}</h2>
                  <p className='panelBox__titleMeta'>求人ID：{record?.jobCode}</p>
                </div>
                <div className='panelBox__headerEnd'>
                  {record.applyGroupId && record.chatStatus !== CONSTANT.NOT_YET_INVITE ? (
                    <button
                      target='_blank'
                      onClick={() =>
                        onHandleChatNewTab(data?.applications?.[index].applyGroupId, data?.applications?.[index]?.jobId)
                      }
                      type='button'
                      className='button -outline-primary -chat'
                    >
                      {record?.chatStatus}
                    </button>
                  ) : (
                    <button
                      onClick={() => setCurrentApply(record)}
                      type='button'
                      className='button -outline-primary -mail'
                      data-modal-target='chat-invitation'
                    >
                      チャットに招待
                    </button>
                  )}
                </div>
              </div>
              <div className='panelBox__body'>
                <form onSubmit={(e) => handleUpdate(e, index)} name={`detailApplication${index}`} className='form'>
                  <table className='detailTable'>
                    <tbody>
                      <tr>
                        <th>
                          応募日<span className='badge -danger u-spacer-left1'>必須</span>
                        </th>
                        <td>
                          <input
                            required
                            onInvalid={(e) => handleRequireInvalid(e)}
                            onChange={(e) => resetRequireValidate(e)}
                            type='date'
                            className='textfield -short'
                            name='appliedDate'
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>
                          ステータス<span className='badge -danger u-spacer-left1'>必須</span>
                        </th>
                        <td>
                          <div className='radio-group js-selectionStatus-radioGroup'>
                            <label className='radio'>
                              <input type='radio' name='status' className='radio__input' value='選考中' />
                              <span className='radio__label'>選考中</span>
                            </label>
                            <label className='radio'>
                              <input type='radio' name='status' value='内定' className='radio__input' />
                              <span className='radio__label'>内定</span>
                            </label>
                            <label className='radio'>
                              <input type='radio' name='status' value='辞退' className='radio__input' />
                              <span className='radio__label'>辞退</span>
                            </label>
                            <label className='radio'>
                              <input type='radio' name='status' value='不合格' className='radio__input' />
                              <span className='radio__label'>不合格</span>
                            </label>
                          </div>
                          <div
                            className={`u-spacer-top2 u-display-none ${
                              record?.evaluationStatus !== '選考中' ? '!block' : ''
                            }`}
                          >
                            <p className='js-selectionStatus-dateLabel'>
                              {record?.evaluationStatus !== '選考中' ? record?.evaluationStatus + '日' : ''}
                            </p>
                            <input type='date' name='evaluationDate' className='textfield -short' />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>評価</th>
                        <td>
                          <div className='form__inline'>
                            <span className='star'></span>
                            <select className='select -x_short' name='rating'>
                              <option value=''>-</option>
                              <option value={1}>1</option>
                              <option value={2}>2</option>
                              <option value={3}>3</option>
                              <option value={4}>4</option>
                              <option value={5}>5</option>
                            </select>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>備考</th>
                        <td>
                          <textarea
                            className='textfield'
                            cols='40'
                            name='comment'
                            rows='4'
                            placeholder='選考時のメモとしてご利用いただけます。'
                          ></textarea>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='u-spacer-top4'>
                    <div className='button-wrapper -center'>
                      <button onClick={() => onCancel(index)} type='button' className='button -default'>
                        キャンセル
                      </button>
                      <button type='submit' className='button -primary'>
                        保存
                      </button>
                    </div>
                  </div>
                </form>

                <div className='u-spacer-top6'>
                  <section className='dataList'>
                    <div className='dataList__header'>
                      <h3 className='title -secondary'>選考状況</h3>
                      <div className='dataList__headerAction'>
                        <button
                          onClick={() => openModalCreate(record)}
                          type='button'
                          className='button -outline-primary -add'
                        >
                          新規追加
                        </button>
                      </div>
                    </div>
                    <div className='dataList__body'>
                      <table className='dataList__table -fixed'>
                        <thead>
                          <tr>
                            <th>進捗</th>
                            <th>面接日時</th>
                            <th>結果</th>
                            <th style={{ width: '192px' }}></th>
                          </tr>
                        </thead>
                        <tbody>
                          {record?.progress?.map((item) => (
                            <tr key={item._id}>
                              <td>{item?.step}</td>
                              {item?.interviewStart ? (
                                <td>
                                  {moment(item?.interviewStart).format('YYYY/MM/DD')}{' '}
                                  <span className='u-display-inlineBlock'>
                                    {' '}
                                    {moment(item?.interviewStart).format('HH:mm')}-
                                    {item?.interviewEnd ? moment(item?.interviewEnd).format('HH:mm') : ''}
                                  </span>
                                </td>
                              ) : (
                                <td></td>
                              )}
                              <td>{item?.result}</td>
                              <td>
                                <div className='dataList__tableAction'>
                                  <button
                                    onClick={() => openPopupEditProgress(item?._id, record)}
                                    type='button'
                                    className='button -default -edit -small mr-[5px]'
                                  >
                                    編集
                                  </button>
                                  <button
                                    onClick={() => openModalDelete(item, record)}
                                    type='button'
                                    className='button -default -delete -small'
                                    data-modal-target='selection-delete'
                                  >
                                    削除
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {record?.progress?.length === 0 && (
                        <div className='w-fit p-[50px] m-auto text-[#d3d4d4]'>{CONSTANT.NO_DATA}</div>
                      )}
                    </div>
                  </section>
                </div>
              </div>
            </section>
          </div>
        </div>
      ))}

      {openModalProgress && (
        <ModalEditProgressInfo
          getDataDetail={getDataDetail}
          typeAction={typeAction}
          currentProgress={dataDetailEvaluation}
          isModalOpen={openModalProgress}
          handleCancel={() => setOpenModalProgress(false)}
          jobId={itemApplication?.jobId}
          listSettingCompanyProgress={dataProgress?.progress?.progress}
        />
      )}
      {Object.keys(currentApplyRow).length > 0 && (
        <ModalSubmitSendMail
          mode={'sendMailForEachJob'}
          refetchContext={getDataDetail}
          jobTitle={currentApplyRow.jobTitle}
          jobId={currentApplyRow.jobId}
          replicaId={data._id}
          applicantEmail={data.email}
          firstName={data.firstName}
          lastName={data.lastName}
        />
      )}
      <ModalDeleteProgress getData={getDataDetail} replicaId={id} progress={itemProgressDelete} />
    </>
  );
};
export default DetailApplication;
