import { useDispatch, useSelector } from 'react-redux';
import { dataSlice, setTagNameHeader } from '../../../../redux/reducer';
import { useEffect, useState } from 'react';
import * as URL from '../../../../../routes/constantRouter';
import { Link, useNavigate } from 'react-router-dom';
import React from 'react';
import { ROLE_ADMIN, ROLE_CHAT_ONLY } from '../../../../common/constant';

const HeaderSetting = () => {
  const navigate = useNavigate();
  const dataLandingPage = useSelector(dataSlice);
  const dataSetting = JSON.parse(localStorage.getItem('dataSetting'));
  const dispatch = useDispatch();

  const handleLogout = async () => {
    localStorage.clear('token');
    navigate('/login');
  };
  const toggleAccountDropdown = () => {
    const accountButton = document.getElementById('js-headerAccountButton');
    if (!accountButton) {
      return;
    }
    const dropdown = accountButton.nextElementSibling;
    dropdown.style.display = dropdown.style.display === 'block' ? 'none' : 'block';
    if (dropdown.style.display === 'block') {
      document.addEventListener('click', (event) => {
        if (!event.target.closest('.header__account')) {
          dropdown.style.display = 'none';
        }
      });
    }
  };

  const onChangeTag = (e) => {
    navigate(e);
    setActiveKeyValue(e);
    dispatch(setTagNameHeader(e));
  };
  const [activeKeyValue, setActiveKeyValue] = useState(dataLandingPage?.tagValueHeader);

  useEffect(() => {
    setActiveKeyValue(dataLandingPage?.tagValueHeader);
  }, [dataLandingPage?.tagValueHeader]);

  useEffect(() => {
    const dataTagName = [URL.URL_JOBS, URL.URL_APPLICANTS, URL.URL_EMPLOYEES, URL.URL_SETTINGS_COMPANY];
    dataTagName.map((item) => {
      if (window.location.pathname.includes(item)) {
        setActiveKeyValue(item);
        dispatch(setTagNameHeader(item));
      }
    });
    return;
  }, [window.location.pathname]);

  const roleUser = JSON.parse(localStorage?.getItem('role'));

  return (
    <header className='layout__header header'>
      <div className='header__inner'>
        <div className='header__start'>
          <a href='/jobs' className='header__logo'>
            <img src='/logo.svg' alt='かえる採用管理' width='200' height='36' />
          </a>
          <ul className='header__companyInfo'>
            <li className='header__companyInfoItem'>
              会社ID：
              <span className='u-font-bold'>
                {dataSetting?.companyCode ?? dataLandingPage?.dataInitial?.companyCode}
              </span>
            </li>
            <li className='header__companyInfoItem'>
              {dataSetting?.companyName ?? dataLandingPage?.dataInitial?.companyName}
            </li>
          </ul>
        </div>
        <div className='header__end'>
          <nav className='header__nav'>
            <ul className='header__navList'>
              {roleUser !== ROLE_CHAT_ONLY && (
                <>
                  <li>
                    <div
                      role='button'
                      aria-hidden
                      onClick={() => onChangeTag(URL.URL_JOBS)}
                      className={`header__navLink ${activeKeyValue === URL.URL_JOBS ? '-active' : ''}`}
                    >
                      求人管理
                    </div>
                  </li>
                  <li>
                    <div
                      role='button'
                      aria-hidden
                      onClick={() => onChangeTag(URL.URL_APPLICANTS)}
                      className={`header__navLink ${activeKeyValue === URL.URL_APPLICANTS ? '-active' : ''}`}
                    >
                      応募管理
                    </div>
                  </li>
                  <li>
                    <div
                      role='button'
                      aria-hidden
                      onClick={() => onChangeTag(URL.URL_EMPLOYEES)}
                      className={`header__navLink ${activeKeyValue === URL.URL_EMPLOYEES ? '-active' : ''}`}
                    >
                      従業員管理
                    </div>
                  </li>
                </>
              )}
              <li>
                {roleUser === ROLE_ADMIN && (
                  <div
                    role='button'
                    aria-hidden
                    onClick={() => onChangeTag(URL.URL_SETTINGS_COMPANY)}
                    className={`header__navLink ${activeKeyValue === URL.URL_SETTINGS_COMPANY ? '-active' : ''}`}
                  >
                    設定
                  </div>
                )}
              </li>
            </ul>
          </nav>
          <div className='header__account'>
            <button className='header__accountButton' id='js-headerAccountButton' onClick={toggleAccountDropdown}>
              {dataSetting?.userName || dataLandingPage?.dataInitial?.userName}
            </button>
            <ul className='header__accountDropdown'>
              <li>
                <div className='header__accountDropdownInfo'>
                  <p>{dataSetting?.userName || dataLandingPage?.dataInitial?.userName}</p>
                  <span>{dataSetting?.email ?? dataLandingPage?.dataInitial?.email}</span>
                </div>
              </li>
              <li>
                <Link to={URL.URL_PASSWORD_CHANGE} className='header__accountDropdownLink'>
                  パスワード変更
                </Link>
              </li>
              <li>
                <Link to={URL.URL_LOGIN} onClick={handleLogout} className='header__accountDropdownLink'>
                  ログアウト
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
};
export default HeaderSetting;
