import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { URL_LOGIN, URL_PASSWORD_CHANGE } from '../../../../../routes/constantRouter';
import { dataSlice } from '../../../../redux/reducer';
import { useDispatch, useSelector } from 'react-redux';
import { GetListRecruitment } from '../../../../../services/RecruiterService';
import { chatSlice, setIdJobSelectChat } from '../../../../redux/reducerChat';

const Header = () => {
  const dataSetting = JSON.parse(localStorage.getItem('dataSetting'));
  const dataLandingPage = useSelector(dataSlice);
  const { jobIDinSelectChat } = useSelector(chatSlice);

  const handleLogout = () => {
    localStorage.clear('token');
  };

  const [listJobs, setListJobs] = useState([
    {
      value: '',
      label: 'すべての求人',
    },
  ]);

  const getListJobs = async () => {
    let dataFinished = [
      {
        value: '',
        label: 'すべての求人',
      },
    ];
    const data = await GetListRecruitment([1, 1000, { onlyMe: true }]);
    const dataJobs = data?.recruitments?.map((item) => ({
      value: item?._id,
      label: item?.jobTitle,
    }));
    dataFinished.push(...dataJobs);
    if (data) {
      setListJobs(dataFinished);
    }
  };
  const token = JSON.parse(localStorage.getItem('token'));
  useEffect(() => {
    getListJobs();
  }, [token]);

  const dispatch = useDispatch();

  const toggleAccountDropdown = () => {
    const accountButton = document.getElementById('js-headerAccountButton');
    if (!accountButton) {
      return;
    }
    const dropdown = accountButton.nextElementSibling;
    dropdown.style.display = dropdown.style.display === 'block' ? 'none' : 'block';
    if (dropdown.style.display === 'block') {
      document.addEventListener('click', (event) => {
        if (!event.target.closest('.header__account')) {
          dropdown.style.display = 'none';
        }
      });
    }
  };
  return (
    <>
      <header className='layout__header header'>
        <div className='header__inner'>
          <div className='header__start'>
            <a href='/jobs' className='header__logo'>
              <img src='logo.svg' alt='かえる採用管理' width='200' height='36' />
            </a>
            <ul className='header__companyInfo'>
              <li className='header__companyInfoItem'>
                会社ID：<span className='u-font-bold'>{dataSetting?.companyCode}</span>
              </li>
              <li className='header__companyInfoItem'>{dataSetting?.companyName}</li>
            </ul>
          </div>
          <div className='header__middle u-spacer-left3'>
            <select
              className='select'
              onChange={(e) => dispatch(setIdJobSelectChat(e.target.value))}
              value={jobIDinSelectChat ? jobIDinSelectChat : ''}
            >
              {listJobs?.map((item) => (
                <option key={item?.value} value={item?.value}>
                  {item?.label}
                </option>
              ))}
            </select>
          </div>
          <div className='header__end'>
            <div className='header__account'>
              <button className='header__accountButton' id='js-headerAccountButton' onClick={toggleAccountDropdown}>
                {' '}
                {dataLandingPage?.dataInitial?.userName || dataSetting?.userName}
              </button>
              <ul className='header__accountDropdown'>
                <li>
                  <div className='header__accountDropdownInfo'>
                    <p> {dataLandingPage?.dataInitial?.userName || dataSetting?.userName}</p>
                    <span>{dataSetting?.email}</span>
                  </div>
                </li>
                <li>
                  <Link to={URL_PASSWORD_CHANGE} target='_blank' className='header__accountDropdownLink'>
                    パスワード変更
                  </Link>
                </li>
                <li>
                  <Link to={URL_LOGIN} onClick={handleLogout} className='header__accountDropdownLink'>
                    ログアウト
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
export default Header;
