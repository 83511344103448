import React, { useEffect, useState } from 'react';
import JobDataBody from './common/JobDataBody';
import ApplicantJobDetail from './common/ApplicantJobDetail';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { GetDetailRecruitment, deleteJob } from '../../../../../services/RecruiterService';
import Loading from '../../../../common/SpinLoading';
import { displayModal, onHandleChatNewTab, statusChat } from '../../../../common/FuntionCommon';
import { toast } from '../../../../../utils/notification';
import * as CONSTANT from '../../../../common/constant';
import { useLocation } from 'react-router-dom';
import { URL_JOBS } from '../../../../../routes/constantRouter';

const JobDetail = () => {
  const [activeKeyValue, setActiveKeyValue] = useState('jobBody');
  const [dataDetail, setDataDetail] = useState();
  const [loadingDetail, setLoadingDetail] = useState(true);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    displayModal();
  });

  const getDataDetail = async () => {
    const data = await GetDetailRecruitment([id]);
    const dataDetail = data?.data;
    if (dataDetail) {
      setDataDetail(dataDetail);
    }
    setLoadingDetail(false);
  };
  useEffect(() => {
    getDataDetail();
  }, [id]);

  const tabActivity = {
    jobBody: <JobDataBody mode='detail' dataDetail={dataDetail} setDataDetail={setDataDetail} />,
    applicantInfo: <ApplicantJobDetail dataDetail={dataDetail} />,
  };
  const [loading, setLoading] = useState(false);
  const handleDeleteJob = async () => {
    setLoading(true);
    const data = await deleteJob(id);
    setLoading(false);
    if (data) {
      navigate('/jobs');
      toast('success', CONSTANT.DELETE_SUCCESSFULLY);
    } else toast('error', CONSTANT.FAIL);
  };

  const pathname = window.location.pathname;
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const tab = params.get('tab');
  useEffect(() => {
    if (tab === 'applicants') {
      setActiveKeyValue('applicantInfo');
    } else {
      setActiveKeyValue('jobBody');
    }
  }, [window.location.search]);

  return (
    <>
      {loadingDetail ? (
        <Loading />
      ) : (
        <>
          <div className='pageHeader'>
            <div className='pageHeader__start'>
              <h1 className='pageHeader__title'>{dataDetail?.jobTitle}</h1>
              <div className='pageHeader__detail'>
                <div
                  className={`statusLight ${
                    dataDetail.status === CONSTANT.CHATTING_STATUS
                      ? '-success'
                      : dataDetail.status === CONSTANT.NEW_CHAT_PENDING_STATUS
                      ? '-warning'
                      : '-neutral'
                  }`}
                >
                  {statusChat(dataDetail?.status)}{' '}
                </div>
                <dl className='pageHeader__definitionList'>
                  <dt>求人ID：</dt>
                  <dd>{dataDetail?.jobCode}</dd>
                  <dt>求人媒体：</dt>
                  <dd>{dataDetail?.medium}</dd>
                  <dt>応募者：</dt>
                  <dd>{dataDetail?.replicaNum}</dd>
                  <dt>内定者：</dt>
                  <dd>{dataDetail?.passedApplicantNum}</dd>
                </dl>
              </div>
            </div>
            <div className='pageHeader__end'>
              <button onClick={() => onHandleChatNewTab(id)} target='blank' className='button -outline-primary -chat'>
                {statusChat(dataDetail?.status)}
              </button>
            </div>
          </div>
          <div className='u-spacer-block2'>
            <ul className='tab'>
              <li className='tab__item'>
                <Link
                  onClick={() => setActiveKeyValue('jobBody')}
                  className={`tab__button ${activeKeyValue === 'jobBody' ? 'is-active' : ''}`}
                >
                  求人情報
                </Link>
              </li>
              <li className='tab__item'>
                <Link
                  to={`${pathname}?tab=applicants`}
                  onClick={() => setActiveKeyValue('applicantInfo')}
                  className={`tab__button ${activeKeyValue === 'applicantInfo' ? 'is-active' : ''}`}
                >
                  応募者一覧
                </Link>
              </li>
            </ul>
          </div>
          {tabActivity?.[activeKeyValue]}
          <div className='u-spacer-top4'>
            <div className='button-wrapper -between'>
              <button onClick={() => navigate(URL_JOBS)} className='button -default -back'>
                一覧に戻る
              </button>
              {activeKeyValue === 'jobBody' && (
                <button
                  disabled={dataDetail?.replicaNum !== 0}
                  type='button'
                  className={`button -outline-danger -delete ${
                    dataDetail?.replicaNum !== 0 ? 'opacity-50 !cursor-not-allowed' : ''
                  }`}
                  data-modal-target='delete'
                >
                  削除
                </button>
              )}
            </div>
          </div>
          <div className='modal -small' id='delete'>
            <div className='modal__inner'>
              <div className='modal__content'>
                <div className='modal__header'>
                  <p className='modal__title'>この求人を削除しますか？</p>
                </div>
                <div className='modal__body'>
                  <p>
                    {dataDetail?.jobTitle}を削除します。よろしいですか？
                    <br />
                    ※この操作は取り消せません
                  </p>
                </div>
                <div className='modal__footer'>
                  <div className='button-wrapper -end'>
                    <button type='button' className='button -default js-modal-close'>
                      キャンセル
                    </button>
                    <button
                      type='button'
                      disabled={loading}
                      className='button -danger'
                      onClick={() => handleDeleteJob()}
                    >
                      {loading && <i className='fa fa-spinner fa-spin mr-[10px]'></i>}
                      削除
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal__overlay'></div>
          </div>
        </>
      )}
    </>
  );
};
export default JobDetail;
