import { URL_CHAT_APPLICANTS } from '../../routes/constantRouter';
import { GetUrlPdfForApplicant, GetUrlPdfForEmployee } from '../../services/RecruiterService';
import { APPLICANT_SETTING, EMPLOYEE_SETTING } from './constant';

export const handleResetAllField = (form) => {
  form.resetFields();
};

export const roleHrUser = (data) => {
  let roleUser = '';
  switch (data) {
    case 'kanri':
      roleUser = '管理者';
      break;
    case 'sub_kanri':
      roleUser = '準管理者';
      break;
    case 'chat_only':
      roleUser = 'チャットのみ参加';
      break;
    default:
      break;
  }
  return roleUser;
};
export const statusChat = (data) => {
  let status = '';
  switch (data) {
    case 'chatting':
      status = 'チャット受付中';
      break;
    case 'new_chat_pending':
      status = '新規チャット停止';
      break;
    case 'chat_pending':
      status = 'チャット停止';
      break;
    default:
      break;
  }
  return status;
};
export const jobType = (data) => {
  const keyJobType = [
    'regular_employee_new_graduate',
    'regular_employee_midcareer',
    'part_time',
    'contract_employee',
    'temporary_staffing',
    'outsourcing',
    'others',
  ];
  const indexData = keyJobType.indexOf(data);
  const keyJobTypeJp = [
    '正社員（新卒)',
    '正社員（中途)',
    'アルバイト・バート',
    '契約社員',
    '派遣委託',
    '業務委託',
    'その他',
  ];
  return keyJobTypeJp[indexData];
};
export const setSex = (data) => {
  let sex = '';
  switch (data) {
    case 'male':
      sex = '男性';
      break;
    case 'female':
      sex = '女性';
      break;
    case 'not_set':
      sex = '回答しない';
      break;
    default:
      sex = '回答しない';
      break;
  }
  return sex;
};
export const displayModal = () => {
  const openButtons = document.querySelectorAll('[data-modal-target]');

  if (!openButtons.length) {
    return;
  }

  const body = document.body;
  const scrollBarWidth = window.innerWidth - body.clientWidth;

  // モーダルを開く処理

  const openModal = (element) => {
    element.classList.add('is-show');
    body.style.overflow = 'hidden';
    body.style.paddingRight = `${scrollBarWidth}px`;
  };

  // モーダルを閉じる処理
  const closeModal = (element, focusElement) => {
    element?.classList?.remove('is-show');
    body.style.overflow = '';
    body.style.paddingRight = '';
    focusElement.focus();
  };

  openButtons.forEach((openButton) => {
    openButton.addEventListener('click', (event) => {
      // `data-modal-target`属性から、対象のモーダルを取得
      const modalId = event.target.dataset.modalTarget;
      const modal = document.getElementById(modalId);

      // 対象のモーダルを表示
      if (modal) {
        openModal(modal);
        const focusableElements = modal.querySelectorAll(
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
        );

        // モーダルを開く直前のフォーカスの取得
        const beforeFocusedElement = document.activeElement;

        // モーダル内のフォーカス可能な要素を取得

        if (focusableElements.length > 0) {
          focusableElements[0].focus();
        }

        // モーダル内の閉じるボタンが押された場合
        const closeButton = modal.querySelector('.js-modal-close');
        closeButton.addEventListener('click', () => {
          closeModal(modal, beforeFocusedElement);
        });

        // モーダルの背景がクリックされた場合
        document.addEventListener('click', (event) => {
          if (event.target.closest('.modal') && !event.target.closest('.modal__content')) {
            closeModal(modal, beforeFocusedElement);
          }
        });
      }
    });
  });
};
export const handleRequireInvalid = (event) => {
  event.target.setCustomValidity('この項目は必須です');
};

export const resetRequireValidate = (event) => {
  event.target.setCustomValidity('');
};

export const closeModalOpen = (element) => {
  const closeModal = (element) => {
    const body = document.body;
    element?.classList?.remove('is-show');
    body.style.overflow = '';
    body.style.paddingRight = '';
  };
  closeModal(element);
};

// ignore action when enter submit
export const handleKeyPress = (event) => {
  if (event.key === 'Enter' && event.target.type !== 'textarea') {
    event.preventDefault();
  }
};

// validate Zipcode
export const validateZipcode = (zipcode) => {
  const zipcodePattern = /^[0-9]{3}-?[0-9]{4}$/;

  if (zipcode === '') {
    return true; // OK
  } else if (!zipcodePattern.test(zipcode)) {
    return false;
  } else {
    return true; // OK zipcode
  }
};
// validate phone number
export const validatePhoneNumber = (phoneNumber) => {
  const phoneNumberPattern = /^(\+\d+)?\d{10,11}$/;

  if (phoneNumber === '') {
    return true;
  } else if (!phoneNumberPattern.test(phoneNumber)) {
    return false;
  } else {
    return true;
  }
};

// Validate Email
export const validateEmail = (email) =>
  String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

//validate url
export const validateURL = (url) => {
  var pattern = /^(https?:\/\/)?[\w.-]+(\.[a-z]{2,6})+\/?.*$/i;
  return pattern.test(url);
};

// get Name for list HR
export const getFullNameForListHR = (listHR) => {
  let finishData = [];
  if (listHR) {
    finishData = listHR?.hrUsers?.map((item) => {
      const dataHr = { id: '', fullName: '' };
      if (item?.status !== 'disable') {
        dataHr.fullName = item?.firstName && item?.lastName ? item?.lastName + ' ' + item?.firstName : item?.username;
        dataHr.id = item?._id;
        dataHr.status = item?.status;
        return dataHr;
      }
    });
    const newArr = finishData.filter(function (item) {
      return item !== undefined;
    });
    return newArr;
  }
};

// get age user
export const getAgeUser = (birthDate) => {
  const today = new Date();
  let age = today.getFullYear() - birthDate?.fullYear;
  const month = today.getMonth() + 1 - birthDate?.month;
  if (month < 0 || (month === 0 && today.getDate() < birthDate?.day)) {
    age--;
  }
  return age;
};

export const onHandleChatNewTab = (groupId, jobId) => {
  const currentUrlOrigin = window.location.origin;
  let url = '';
  if (groupId && jobId) {
    url = `${currentUrlOrigin + URL_CHAT_APPLICANTS}?groupId=${groupId}&jobId=${jobId}`;
  } else if (groupId && !jobId) {
    url = `${currentUrlOrigin + URL_CHAT_APPLICANTS}?groupId=${groupId}`;
  } else if (!groupId && jobId) {
    url = `${currentUrlOrigin + URL_CHAT_APPLICANTS}?jobId=${jobId}`;
  }
  window.name = 'cearu_kanri';
  window.open(url, 'caeru_chat');
};

export const getWeekDay = (dateData) => {
  const japaneseDays = ['日', '月', '火', '水', '木', '金', '土'];
  const dayData = `(${japaneseDays[dateData.getDay()]})`;
  return dayData;
};

// Show Notication when receive new Message
export const handleShowNotiForBrowser = async (fromMess, contentMess) => {
  let notification = {};

  if (!('Notification' in window)) {
    alert('No permission');
  }

  // check permission
  else if (window.Notification.permission === 'granted') {
    notification = await new Notification(fromMess, {
      body: contentMess,
      icon: '/logo.svg',
    });
  }

  // request permission
  else if (window.Notification.permission === 'denied' || window.Notification.permission === 'default') {
    await Notification.requestPermission().then(function (permission) {
      if (permission === 'granted') {
        notification = new Notification(fromMess, {
          body: contentMess,
          icon: '/logo.svg',
        });
      }
    });
  }

  notification.onclick = function () {
    const url = window.location.origin + '/chat';
    window.open(url, '_blank');
  };
};

export const openNewTabPreviewPDF = async (currentUrl, id, typePdf, typeApi) => {
  if (currentUrl) {
    return window.open(currentUrl, '_blank');
  } else {
    let response;
    if (typeApi === APPLICANT_SETTING) {
      response = await GetUrlPdfForApplicant(id, typePdf);
    } else if (typeApi === EMPLOYEE_SETTING) {
      response = await GetUrlPdfForEmployee(id, typePdf);
    }
    const file = await new Blob([response], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    return window.open(fileURL, '_blank');
  }
};
