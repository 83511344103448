import React from 'react';
import JobDataBody from './common/JobDataBody';

const AddNewJob = () => {
  return (
    <>
      <div className='pageHeader'>
        <div className='pageHeader__start'>
          <h1 className='pageHeader__title'>求人の登録</h1>
          <p className='u-text-light'>
            求人を新しく登録すると、チャットスペースが作成され、その求人に応募してきた求職者とチャットが行えます。
            <br />
            求人の募集状況は［ステータス］より、管理を行います。
          </p>
        </div>
      </div>
      <JobDataBody mode='create' />
      <div className='u-spacer-top4'>
        <button onClick={() => history.back()} className='button -default -back'>
          一覧に戻る
        </button>
      </div>
    </>
  );
};
export default AddNewJob;
