import { createSlice } from '@reduxjs/toolkit';
import * as TYPE from './actionType.js';

const initialState = {
  socketConnect: false,
  dataLoginByToken: {},
  groupMessage: {},
  currentActiveGroup: {},
  jobIDinSelectChat: '',
  evaluateInterviewApplicant: {},
  typeChat: 'apply',
  contentMessageSend: {},
  listDataGroupPublic: [],
  listGroupChatAnonymous: [],
  messagesMainChat: [],
  scrollOnBottomChat: true,
};

export const chatRecruiter = createSlice({
  name: 'chatSlice',
  initialState,
  reducers: {
    setSocketConnect: (state, action) => {
      return {...state, socketConnect: action.payload}
    },

    setCurrentActiveGroupData: (state, action) => {
      return { ...state, currentActiveGroup: action.payload };
    },
    setIdJobSelectChat: (state, action) => {
      return { ...state, jobIDinSelectChat: action.payload };
    },
    setInfoEvaluateInterviewApplicant: (state, action) => {
      return { ...state, evaluateInterviewApplicant: action.payload };
    },
    setTypeChat: (state, action) => {
      return { ...state, typeChat: action.payload };
    },
    setMessageSending: (state, action) => {
      return { ...state, contentMessageSend: action.payload };
    },
    setListDataGroupPublic: (state, action) => {
      return { ...state, listDataGroupPublic: action.payload };
    },
    setListGroupChatAnonymous: (state, action) => {
      return { ...state, listGroupChatAnonymous: action.payload };
    },
    // when an element of the list chat changed
    setListPublicElement: (state, action) => {
      const { type, groupId, messageId, updating } = action.payload;
      let updatedList;

      switch (type) {
        case TYPE.LISTEN_DELETE_MESSAGE:
          updatedList = state.listDataGroupPublic.map((item) =>
            item._id === groupId && item.lastMessage._id === messageId
              ? { ...item, lastMessage: { ...item.lastMessage, ...updating } }
              : item
          );

          return { ...state, listDataGroupPublic: updatedList };

        default:
          updatedList = state.listDataGroupPublic.map((item) =>
            item._id === groupId ? { ...item, ...updating } : item
          );

          return { ...state, listDataGroupPublic: updatedList };
      }
    },

    setListAnonymousElement: (state, action) => {
      const { groupId, messageId, updating } = action.payload;
      let updatedList;

      switch (action.type) {
        case TYPE.LISTEN_DELETE_MESSAGE:
          updatedList = state.listGroupChatAnonymous.map((item) =>
            item._id === groupId && item.lastMessage._id === messageId
              ? { ...item, lastMessage: { ...item.lastMessage, ...updating } }
              : item
          );

          return { ...state, listGroupChatAnonymous: updatedList };

        default:
          updatedList = state.listGroupChatAnonymous.map((item) =>
            item._id === groupId ? { ...item, ...updating } : item
          );

          return { ...state, listGroupChatAnonymous: updatedList };
      }
    },

    setListMessagesMainChat: (state, action) => {
      return { ...state, messagesMainChat: action.payload };
    },
    setScrollOnBottomChat: (state, action) => {
      return { ...state, scrollOnBottomChat: action.payload };
    },
  },
});

export const {
  setSocketConnect,
  setCurrentActiveGroupData,
  setIsCancelAction,
  setIdJobSelectChat,
  setInfoEvaluateInterviewApplicant,
  setTypeChat,
  setMessageSending,
  setListDataGroupPublic,
  setListGroupChatAnonymous,
  setListPublicElement,
  setListAnonymousElement,
  setListMessagesMainChat,
  setScrollOnBottomChat,
} = chatRecruiter.actions;
export const chatSlice = (state) => state.chatSlice;
export default chatRecruiter.reducer;
