import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { URL_SETTING_USER_NEW } from '../../../../../../routes/constantRouter';
import PaginationCustom from '../../../../../template/Pagination';
import { GetListHr } from '../../../../../../services/RecruiterService';
import { roleHrUser } from '../../../../../common/FuntionCommon';
import Loading from '../../../../../common/SpinLoading';
import useSWR from 'swr';
import { URL_API_GET_LIST_HR } from '../../../../../../services';
import { LIMIT_PAGE_DEFAULT, NO_DATA } from '../../../../../common/constant';

const User = () => {
  const navigate = useNavigate();
  const [pagination, setPagination] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const onChangePage = (page) => {
    setCurrentPage(page);
  };

  const { data: dataList, isLoading } = useSWR([currentPage, LIMIT_PAGE_DEFAULT, URL_API_GET_LIST_HR], GetListHr, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  useEffect(() => {
    if (dataList) {
      setPagination(dataList?.pageInfo);
    }
  }, [isLoading]);

  return (
    <>
      <div className='panel'>
        {isLoading ? (
          <Loading />
        ) : (
          <div className='panel__body'>
            <div className='dataList'>
              <div className='dataList__header -center'>
                {dataList?.hrUsers?.length > 0 && (
                  <PaginationCustom currentPage={currentPage} onChangePage={onChangePage} pagination={pagination} />
                )}
                <div className='dataList__headerAction'>
                  <Link to={URL_SETTING_USER_NEW} className='button -outline-primary -add'>
                    新規登録
                  </Link>
                </div>
              </div>
              <div className='dataList__body'>
                <table className='dataList__table -fixed'>
                  <thead>
                    <tr>
                      <th>メールアドレス</th>
                      <th>氏名</th>
                      <th>権限</th>
                      <th>ステータス</th>
                      <th data-column='detail'></th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataList?.hrUsers?.map((item) => (
                      <tr key={item?._id}>
                        <td>{item?.email}</td>
                        <td>
                          {item?.firstName && item?.lastName ? item?.lastName + ' ' + item?.firstName : item?.username}
                        </td>
                        <td>{roleHrUser(item?.role)}</td>
                        <td>
                          <div className={`statusLight ${item?.status === 'enable' ? '-success' : '-neutral'}`}>
                            {item?.status === 'enable' ? '有効' : '無効'}
                          </div>
                        </td>
                        <td>
                          <button
                            type='button'
                            onClick={() => navigate(`/settings-users-detail/${item?._id}`)}
                            className='button -primary -small'
                          >
                            詳細
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className='dataList__footer'>
                {dataList?.hrUsers?.length > 0 ? (
                  <PaginationCustom currentPage={currentPage} onChangePage={onChangePage} pagination={pagination} />
                ) : (
                  <div className='w-fit m-auto text-[#d3d4d4]'>{NO_DATA}</div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default User;
