import React, { useEffect, useState } from 'react';
import { GetListRecruitment } from '../../../../../services/RecruiterService';
import { useLocation, useNavigate } from 'react-router-dom';

import ApplicantBasicInfo from './common/ApplicantBasicInfo';
import { useSelector } from 'react-redux';
import { dataSlice } from '../../../../redux/reducer';

// 新規作成
const ApplicantCreateNew = () => {
  // data from job
  const navigate = useNavigate();
  const location = useLocation();
  const jobIdFormPageJob = location?.state?.jobId;
  const { dataRouterBackButton } = useSelector(dataSlice);

  const [listJobs, setListJobs] = useState([]);
  const getListJobs = async () => {
    const data = await GetListRecruitment([1, 1000, { onlyMe: true }]);
    const dataJob = data?.recruitments?.map((item) => ({
      value: item?._id,
      label: item?.jobTitle + ', [求人ID : ' + item?.jobCode + ']',
    }));
    if (data) {
      setListJobs(dataJob);
    }
  };
  useEffect(() => {
    getListJobs();
  }, []);

  return (
    <>
      <div className='pageHeader'>
        <div className='pageHeader__start'>
          <h1 className='pageHeader__title'>応募者の登録</h1>
        </div>
      </div>
      <ApplicantBasicInfo mode='create' jobIdFormPageJob={jobIdFormPageJob} listJobs={listJobs} />
      <div className='u-spacer-top4'>
        <button
          onClick={() =>
            dataRouterBackButton?.from_url === 'job'
              ? navigate(`/jobs-detail-basic/${dataRouterBackButton?.jobId}?tab=applicants`)
              : navigate(dataRouterBackButton?.from_url)
          }
          className='button -default -back'
        >
          一覧に戻る
        </button>
      </div>
    </>
  );
};
export default ApplicantCreateNew;
