import React, { useEffect, useRef, useState } from 'react';
import ModalConfirmBtn from '../../../../Helper/modalConfirmBtn';
import { changeListHrChat } from '../../../../../services/RecruiterService';
import { useDispatch, useSelector } from 'react-redux';
import {
  chatSlice,
  setCurrentActiveGroupData,
  setListDataGroupPublic,
  setListGroupChatAnonymous,
} from '../../../../redux/reducerChat';
import { toast } from '../../../../../utils/notification';
import { CHAT_TYPE_PUBLIC, LIST_HR_NULL, ROLE_CHAT_ONLY } from '../../../../common/constant';
import Choices from 'choices.js';
import { handleRequireInvalid, resetRequireValidate } from '../../../../common/FuntionCommon';

const ParticipatingUsers = ({ hrListForGroup, dataListHr, groupId }) => {
  const { currentActiveGroup, listDataGroupPublic, listGroupChatAnonymous } = useSelector(chatSlice);
  const [isOpenModalConfirm, setIsOpenModalConfirm] = useState(false);
  const dispatch = useDispatch();
  const { typeChat } = useSelector(chatSlice);
  const role_user = JSON.parse(localStorage?.getItem('role'));
  const choicesRef = useRef(null);

  const changeHrChat = async (dataSelected) => {
    const hrSelected = dataSelected
      ? dataSelected
      : [...choicesRef.current.options].filter((option) => option.selected).map((option) => option.value);

    if (hrSelected?.length === 0) {
      setIsOpenModalConfirm(false);
      return toast('error', LIST_HR_NULL);
    } else {
      const payload = {
        recruiterMembers: hrSelected,
        groupType: typeChat,
      };
      const data = await changeListHrChat(groupId, payload);
      if (data) {
        if (!hrSelected.includes(JSON.parse(localStorage?.getItem('id')))) {
          // Delete the HR who is logging in
          if (typeChat === CHAT_TYPE_PUBLIC) {
            const newListDataGroupPublic = listDataGroupPublic.filter((obj) => obj._id !== data?.group?._id);
            dispatch(setListDataGroupPublic(newListDataGroupPublic));
          } else {
            const newListDataGroupAnonymous = listGroupChatAnonymous.filter((obj) => obj._id !== data?.group?._id);
            dispatch(setListGroupChatAnonymous(newListDataGroupAnonymous));
          }
          dispatch(setCurrentActiveGroupData({}));
        } else {
          const dataGroupCurrentClone = { ...currentActiveGroup };
          dataGroupCurrentClone.recruiterMembers = data?.group?.recruiterMembers;
          dispatch(setCurrentActiveGroupData(dataGroupCurrentClone));
          const listChatClone = typeChat === CHAT_TYPE_PUBLIC ? listDataGroupPublic : listGroupChatAnonymous;
          const dataFinish = listChatClone?.map((item) => {
            if (item?._id === data?.group?._id) {
              const itemClone = { ...item };
              itemClone.recruiterMembers = data?.group?.recruiterMembers;
              return itemClone;
            }
            return item;
          });
          typeChat === CHAT_TYPE_PUBLIC
            ? dispatch(setListDataGroupPublic(dataFinish))
            : dispatch(setListGroupChatAnonymous(dataFinish));
        }
      }
      setIsOpenModalConfirm(false);
    }
  };

  const [listHr, setListHr] = useState([]);
  const [namHR, setNameHR] = useState('');
  const handleConfirm = (value, name) => {
    setIsOpenModalConfirm(true);
    setListHr(hrListForGroup.filter((item) => item !== value));
    setNameHR(name);
  };
  const [openSelectOptionHR, setOpenSelectOptionHR] = useState(false);
  const toggleChatUsersForm = () => {
    const toggleButton = document.getElementById('js-chatUsersFormToggleButton');
    if (!toggleButton) {
      return;
    }
    const form = toggleButton.nextElementSibling;
    toggleButton.classList.toggle('is-expanded');
    toggleButton.textContent = toggleButton.classList.contains('is-expanded') ? 'キャンセル' : 'ユーザーの追加';
    form.style.display = toggleButton.classList.contains('is-expanded') ? 'block' : 'none';
  };

  useEffect(() => {
    const userMultiSelect = document.querySelector('.js-choicesUser');
    const choices = new Choices(userMultiSelect, {
      allowHTML: true,
      removeItems: true,
      removeItemButton: true,
      searchEnabled: true,
      itemSelectText: '',
      noChoicesText: '選択肢がありません',
      noResultsText: '一致する選択肢がありません',
    });
    const dataOptionsItem = dataListHr?.map((item) => {
      let itemClone = {};
      itemClone.selected = hrListForGroup?.includes(item.id);
      itemClone.value = item.id;
      itemClone.label = item.fullName;
      return itemClone;
    });
    choices.setChoices(dataOptionsItem, 'value', 'label', true);
    // アイテムが1つ以上ある場合、プレースホルダーを非表示
    userMultiSelect.addEventListener('change', (event) => {
      const selectedItemsHr = choices.getValue(true);
      const placeholder = event.target.parentNode.querySelector('[name="search_terms"]');
      selectedItemsHr.length > 0
        ? (placeholder.placeholder = '')
        : (placeholder.placeholder = '求人媒体を選択してください');
    });

    return () => {
      choices.destroy();
    };
  }, [currentActiveGroup?._id, hrListForGroup]);

  return (
    <>
      <section className='js-tabContent' id='users'>
        <h3 className='chatInfo__title'>会話に参加しているユーザー</h3>
        <p className='u-text-light'>{hrListForGroup?.length}人</p>
        <div className='chatUsers'>
          {role_user !== ROLE_CHAT_ONLY && (
            <button
              type='button'
              onClick={() => (setOpenSelectOptionHR(!openSelectOptionHR), toggleChatUsersForm())}
              className='chatUsers__textButton'
              id='js-chatUsersFormToggleButton'
            >
              ユーザーの追加
            </button>
          )}
          <form name='formUser' className='chatUsers__form'>
            <select
              required
              onInvalid={(e) => handleRequireInvalid(e)}
              onChange={(e) => resetRequireValidate(e)}
              className={'js-choicesUser'}
              multiple
              ref={choicesRef}
            >
              <option value=''>担当者を選択してください</option>
            </select>
            <div className='u-spacer-top2 u-align-right'>
              <div className='button-wrapper -end'>
                <button onClick={() => changeHrChat()} type='button' className='button -primary -small'>
                  追加
                </button>
              </div>
            </div>
          </form>
          <ul className='chatUsers__list'>
            {hrListForGroup?.map((item, index) => {
              const data = dataListHr?.find((e) => {
                if ([item].includes(e?.id)) return e;
              });
              return (
                <>
                  <li className='chatUsers__addedUser' key={index}>
                    {data?.fullName ?? ''}
                    {role_user !== ROLE_CHAT_ONLY && (
                      <button
                        onClick={() => handleConfirm(item, data?.fullName)}
                        type='button'
                        className='iconButton -remove'
                      ></button>
                    )}
                  </li>
                </>
              );
            })}
          </ul>
        </div>
        <p className='u-spacer-top3 u-text-light'>
          ［求人担当者］は、応募した求人に設定された担当者です。［求人担当者］の情報を変更するには、管理画面の求人詳細画面から編集を行ってください。
        </p>
      </section>
      {isOpenModalConfirm && (
        <ModalConfirmBtn
          changeHrChat={changeHrChat}
          listHr={listHr}
          handleCancel={() => setIsOpenModalConfirm(false)}
          namHR={namHR}
        />
      )}
    </>
  );
};
export default ParticipatingUsers;
