import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HandleSetEvaluateApplicant } from '../../../services/RecruiterService';
import moment from 'moment';
import { toast } from '../../../utils/notification';
import { setInfoEvaluateInterviewApplicant } from '../../redux/reducerChat';
import * as CONSTANT from '../../common/constant';
import { closeModalOpen } from '../../common/FuntionCommon';
import { chatSlice } from '../../redux/reducerChat';

const ModalReplicaEvaluate = ({ dataApplication, applicantId }) => {
  const [loading, setLoading] = useState(false);
  const { currentActiveGroup } = useSelector(chatSlice);
  useEffect(() => {
    document.forms['editChatBox']['status'].value = dataApplication?.evaluationStatus;
    document.forms['editChatBox']['evaluationDate'].value = moment(dataApplication?.evaluationDate)?.format(
      'YYYY-MM-DD'
    );
    document.forms['editChatBox']['rating'].value = dataApplication?.rating ?? '';
    document.forms['editChatBox']['comment'].value = dataApplication?.note ?? '';
  }, [dataApplication]);

  const dispatch = useDispatch();

  const handleFinish = async () => {
    setLoading(true);
    const dataForm = document.forms['editChatBox'];
    const dateEvaluation = dataForm['evaluationDate']?.value ? new Date(dataForm['evaluationDate']?.value) : null;
    const payload = {
      evaluationStatus: dataForm['status'].value,
      evaluationDate: dateEvaluation ? dateEvaluation?.toISOString() : '',
      rating: dataForm['rating'].value,
      note: dataForm['comment'].value ?? '',
      jobId: dataApplication?.jobId ?? currentActiveGroup?.recruitmentId?._id,
    };
    const data = await HandleSetEvaluateApplicant(applicantId, payload);
    if (data) {
      dispatch(
        setInfoEvaluateInterviewApplicant(
          data?.data?.updatedApplicantReplica?.applications?.find((item) => item?._id === dataApplication?._id)
        )
      );
      toast('success', CONSTANT.UPDATE_SUCCESSFULLY);
    } else toast('error', CONSTANT.UPDATE_FAIL);
    setLoading(false);
    closeModalOpen(document.getElementById('selection1'));
  };
  const showDateFieldOnSelection = () => {
    // 選考ステータス:ラジオボタンの親要素
    const radioGroups = document.querySelectorAll('.js-selectionStatus-radioGroup');

    if (radioGroups.length) {
      radioGroups.forEach((radioGroup) => {
        radioGroup.addEventListener('change', (event) => {
          // 選択されたラジオボタンのテキストを取得
          const selectedText = event.target.nextElementSibling.textContent;

          // 日付を表示エリアとラベル表示する要素を取得
          const selectionResultDateDisplay = event.target.closest('.radio-group').nextElementSibling;
          const selectionResultDateLabel = selectionResultDateDisplay.querySelector('.js-selectionStatus-dateLabel');

          if (selectedText !== '選考中' && selectedText !== null) {
            // 「選考中」以外が選ばれている場合、日付フィールドを表示して
            selectionResultDateDisplay.style.display = 'block';
            selectionResultDateLabel.textContent = `${selectedText}日`;
          } else {
            selectionResultDateDisplay.style.display = 'none';
          }
        });
      });
    }
  };
  useEffect(() => {
    showDateFieldOnSelection();
  }, []);

  return (
    <>
      <div className='modal' id='selection1'>
        <div className='modal__inner'>
          <form name='editChatBox' className='form modal__content'>
            <div className='modal__header'>
              <p className='modal__title'>選考情報</p>
            </div>
            <div className='modal__body'>
              <table className='detailTable'>
                <tbody>
                  <tr>
                    <th>
                      ステータス<span className='badge -danger u-spacer-left1'>必須</span>
                    </th>
                    <td>
                      <div className='radio-group js-selectionStatus-radioGroup'>
                        <label className='radio'>
                          <input value={'選考中'} type='radio' name='status' className='radio__input' defaultChecked />
                          <span className='radio__label'>選考中</span>
                        </label>
                        <label className='radio'>
                          <input value={'内定'} type='radio' name='status' className='radio__input' />
                          <span className='radio__label'>内定</span>
                        </label>
                        <label className='radio'>
                          <input value={'辞退'} type='radio' name='status' className='radio__input' />
                          <span className='radio__label'>辞退</span>
                        </label>
                        <label className='radio'>
                          <input value={'不合格'} type='radio' name='status' className='radio__input' />
                          <span className='radio__label'>不合格</span>
                        </label>
                      </div>
                      <div className='u-spacer-top2 u-display-none'>
                        <p className='js-selectionStatus-dateLabel'></p>
                        <input type='date' className='textfield -short' name='evaluationDate' />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>評価</th>
                    <td>
                      <div className='form__inline'>
                        <span className='star'></span>
                        <select className='select -x_short' name='rating'>
                          <option value=''>-</option>
                          <option value='1'>1</option>
                          <option value='2'>2</option>
                          <option value='3'>3</option>
                          <option value='4'>4</option>
                          <option value='5'>5</option>
                        </select>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>備考</th>
                    <td>
                      <textarea
                        name='comment'
                        className='textfield'
                        cols='40'
                        rows='4'
                        placeholder='選考時のメモとしてご利用いただけます。'
                      ></textarea>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='modal__footer'>
              <div className='button-wrapper -end'>
                <button type='button' className='button -default js-modal-close'>
                  キャンセル
                </button>
                <button
                  onClick={() => handleFinish()}
                  disabled={loading}
                  type='button'
                  className='button -primary js-modal-close'
                >
                  {loading && <i className='fa fa-spinner fa-spin mr-[10px]'></i>}保存
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className='modal__overlay'></div>
      </div>
    </>
  );
};
export default ModalReplicaEvaluate;
