/* eslint-disable no-unused-vars */
import { useLocation, useNavigate } from 'react-router-dom';
import { RecruiterSettingPasswordFirstTime } from '../../../../services/RecruiterService';
import { toast } from '../../../../utils/notification';
import React, { useState } from 'react';
import * as CONSTANT from '../../../common/constant';
import { handleRequireInvalid } from '../../../common/FuntionCommon';

const SettingPasswordFirstTime = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const resetPassword = async (e) => {
    e.preventDefault();
    localStorage.setItem('token', JSON.stringify(params.get('token')));
    const data = await RecruiterSettingPasswordFirstTime(document.forms['resetPw']['newPw'].value);
    localStorage.clear('token');
    if (data) {
      navigate('/login');
      toast('success', data?.message);
    }
  };
  const [disableBtn, setDisableBtn] = useState(false);
  const validatePW = (event) => {
    event.target.setCustomValidity('');
    var pw = document.getElementById('password').value;
    var pwConfirm = document.getElementById('confirmPassword').value;
    var regularExpression = /^(?=.*\d)(?=.*[a-zA-Z])[a-zA-Z0-9]{8,}$/;
    if (regularExpression?.test(pw)) {
      setDisableBtn(false);
      document.getElementById('message').innerHTML = '';
    } else {
      setDisableBtn(true);
      document.getElementById('message').innerHTML = CONSTANT.PASSWORD_RULE;
    }
    if (pwConfirm && pw !== pwConfirm) {
      setDisableBtn(true);
      document.getElementById('messageConfirm').innerHTML = CONSTANT.PASSWORD_CONFIRM_ERROR;
    } else {
      document.getElementById('messageConfirm').innerHTML = '';
      setDisableBtn(false);
    }
  };

  const handleConfirmPW = (event) => {
    event.target.setCustomValidity('');
    var pw1 = document.getElementById('password').value;
    var pw2 = document.getElementById('confirmPassword').value;
    if (pw1 !== pw2) {
      setDisableBtn(true);
      document.getElementById('messageConfirm').innerHTML = CONSTANT.PASSWORD_CONFIRM_ERROR;
    } else {
      document.getElementById('messageConfirm').innerHTML = '';
      setDisableBtn(false);
    }
  };

  function showPw() {
    var x = document.getElementById('password');
    if (x.type === 'password') {
      x.type = 'text';
    } else {
      x.type = 'password';
    }
  }

  function showConfirmPw() {
    var x = document.getElementById('confirmPassword');
    if (x.type === 'password') {
      x.type = 'text';
    } else {
      x.type = 'password';
    }
  }
  return (
    <>
      <div className='layout__container -narrow'>
        <div className='u-spacer-top2 u-align-center'>
          <img src='logo.svg' alt='CAERU採用管理' width='300' height='54' />
        </div>
        <div className='panel u-spacer-top3'>
          <div className='panel__body -large'>
            <h2 className='panel__title'>パスワードの設定</h2>
            <p className='u-spacer-top1 u-spacer-bottom2'>パスワードは8文字以上でご登録ください。</p>
            <form name='resetPw' onSubmit={(e) => resetPassword(e)} className='form'>
              <fieldset className='form__group'>
                <label htmlFor='password' className='form__label'>
                  パスワード
                </label>
                <div className='form__field items-center relative'>
                  <input
                    type='password'
                    name='newPw'
                    required
                    onInvalid={(e) => handleRequireInvalid(e)}
                    onChange={(e) => validatePW(e)}
                    className='textfield'
                    id='password'
                  />
                  <span
                    style={{ transform: 'translateY(-50%)' }}
                    role='button'
                    aria-hidden
                    onClick={showPw}
                    className='fa fa-fw fa-eye field-icon toggle-password absolute right-[20px] cursor-pointer top-[50%]'
                  ></span>
                </div>
                <p
                  id='message'
                  style={{
                    color: 'red',
                    whiteSpace: 'pre-line',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                ></p>
              </fieldset>
              <fieldset className='form__group'>
                <label htmlFor='confirm-password' minLength={8} className='form__label'>
                  パスワード（確認用）
                </label>
                <div className='form__field items-center relative'>
                  <input
                    type='password'
                    name='oldPw'
                    required
                    onChange={(e) => handleConfirmPW(e)}
                    className='textfield'
                    id='confirmPassword'
                  />
                  <span
                    style={{ transform: 'translateY(-50%)' }}
                    role='button'
                    aria-hidden
                    onClick={showConfirmPw}
                    className='fa fa-fw fa-eye field-icon toggle-password absolute right-[20px] cursor-pointer top-[50%]'
                  ></span>
                </div>
                <p
                  id='messageConfirm'
                  style={{
                    color: 'red',
                    whiteSpace: 'pre-line',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                ></p>
              </fieldset>
              <div className='form__group'>
                <button type='submit' disabled={disableBtn} className='button -primary -full'>
                  パスワードを設定
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default SettingPasswordFirstTime;
