/* eslint-disable quotes */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PaginationCustom from '../../../../../template/Pagination';
import moment from 'moment';
import { GetListEmployees } from '../../../../../../services/RecruiterService';
import { displayModal, getAgeUser, onHandleChatNewTab, setSex } from '../../../../../common/FuntionCommon';
import ModalSubmitSendMail from '../../Applicants/common/ModalSubmitSendMail';
import { LIMIT_PAGE_DEFAULT, NOT_YET_INVITE, NO_DATA, TYPE_ASC, TYPE_DESC } from '../../../../../common/constant';
import SearchEmployees from './SearchEmployees';
import Loading from '../../../../../common/SpinLoading';

const BodyTableListResult = () => {
  const navigate = useNavigate();

  const [sortOrder, setSortOrder] = useState(TYPE_DESC);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState();
  const [listEmployees, setListEmployees] = useState();
  const [loading, setLoading] = useState(false);
  const [querySearch, setQuerySearch] = useState({
    chatStatus: '招待する,参加待ち,チャット中,退出済み',
    status: '在職中',
    sortField: 'employeeCompanyEntranceDate',
    sortOrder: TYPE_DESC,
  });
  const getListEmployees = async (valueSearch = querySearch, page = currentPage, limit = LIMIT_PAGE_DEFAULT) => {
    setLoading(true);
    const data = await GetListEmployees(valueSearch, page, limit);
    if (data) {
      setListEmployees(data?.returnEmployees);
      setPagination(data?.pageInfo);
    }
    setLoading(false);
  };
  useEffect(() => {
    getListEmployees();
  }, [currentPage]);

  const onChangePage = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    displayModal();
  });

  const handleSortDateCreate = () => {
    const typeSort = sortOrder === TYPE_DESC ? TYPE_ASC : TYPE_DESC;
    setSortOrder(typeSort);
    const paramSearch = { ...querySearch, sortOrder: typeSort };
    getListEmployees(paramSearch);
  };

  const [dataDetailEmployee, setDataDetailEmployee] = useState();
  return (
    <>
      <SearchEmployees getListEmployees={getListEmployees} setQuerySearch={setQuerySearch} />

      <div className='panel relative'>
        {loading ? (
          <Loading />
        ) : (
          <div className='panel__body'>
            <div className='dataList'>
              <div className='dataList__header -center'>
                {listEmployees?.length > 0 && (
                  <PaginationCustom currentPage={currentPage} onChangePage={onChangePage} pagination={pagination} />
                )}
              </div>
              <div className='dataList__body'>
                <table className='dataList__table -applicants'>
                  <thead>
                    <tr>
                      <th data-column='id'>従業員ID</th>
                      <th data-column='name'>従業員</th>
                      <th data-column='chat'>チャット</th>
                      <th data-column='status'>ステータス</th>
                      <th data-column='startDate' className='dataList__tableSort'>
                        <button
                          type='button'
                          className={`dataList__tableSortButton ${sortOrder === TYPE_ASC ? 'sortAsc' : ''}`}
                          data-sort='false'
                          onClick={handleSortDateCreate}
                        >
                          入社年月日
                        </button>
                      </th>
                      <th data-column='detail'></th>
                    </tr>
                  </thead>
                  <tbody>
                    {listEmployees?.map((item) => (
                      <tr key={item?._id} className={item?.hiddenInList ? 'row-hidden' : ''}>
                        <td>{item.employeeCode}</td>
                        <td>
                          <p className='dataList__tableMainText'>{`${item.lastName} ${item.firstName}`}</p>
                          <p className='dataList__tableSubText'>{`${getAgeUser({
                            fullYear: +item?.dateOfBirth_YYYY,
                            month: +item?.dateOfBirth_MM,
                            day: +item?.dateOfBirth_DD,
                          })}歳 /${setSex(item.sex ?? 'not_set')} `}</p>
                        </td>
                        <td>
                          <div className='dataList__tableChatButton'>
                            {item?.application?.applyGroupId && item?.application?.chatStatus !== NOT_YET_INVITE ? (
                              <button
                                onClick={() => onHandleChatNewTab(item?.application?.applyGroupId)}
                                className='button -outline-primary -chat -small'
                                target='_blank'
                              >
                                {' '}
                                {item?.application?.chatStatus}
                              </button>
                            ) : (
                              <button
                                onClick={() => setDataDetailEmployee(item)}
                                data-modal-target='chat-invitation'
                                className='button -outline-primary -mail -small'
                              >
                                招待する
                              </button>
                            )}
                          </div>
                        </td>
                        <td>
                          <div
                            className={`statusLight ${
                              item.employeeStatus === '在職中'
                                ? '-success'
                                : item.employeeStatus === '休職中'
                                ? '-warning'
                                : item.employeeStatus === '退職済み'
                                ? '-neutral'
                                : ''
                            }`}
                            style={{ textAlign: 'left' }}
                          >
                            {item.employeeStatus}
                          </div>
                        </td>
                        <td>{moment(item?.employeeCompanyEntranceDate).format('YYYY/MM/DD')}</td>
                        <td>
                          <button
                            onClick={() => navigate(`/employees-detail-basic/${item?._id}`)}
                            className='button -primary -small'
                          >
                            詳細
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className='dataList__footer'>
                {listEmployees?.length > 0 ? (
                  <PaginationCustom currentPage={currentPage} onChangePage={onChangePage} pagination={pagination} />
                ) : (
                  <div className='w-fit m-auto text-[#d3d4d4]'>{NO_DATA}</div>
                )}
              </div>
              <ModalSubmitSendMail
                mode={'sendMailInListEmployee'}
                refetchContext={getListEmployees}
                jobTitle={dataDetailEmployee?.employeeJobTitle}
                jobId={dataDetailEmployee?.employeeJobId}
                replicaId={dataDetailEmployee?.applicantReplicaId}
                applicantEmail={dataDetailEmployee?.email}
                firstName={dataDetailEmployee?.firstName}
                lastName={dataDetailEmployee?.lastName}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default BodyTableListResult;
