import IO from 'socket.io-client';
import { urlServer } from '../components/common/constant';
import { loginByToken } from './ChatService';
import { setSocketConnect } from '../components/redux/reducerChat';

import store from '../components/redux/store';



const options = {
  transports: ["polling", "websocket"],
};
export const socketIO = IO(urlServer, options);

socketIO.on('connect', async() => {
  
  store.dispatch(setSocketConnect(true));

  console.log('SOCKET IO CONNECTED !!!');

  // await sleep(500)
  // const dataLogin = await loginByToken();


});

socketIO.on('disconnect', () => {
  store.dispatch(setSocketConnect(false))
  console.log('SOCKET IO disconnected :((((');
});

export function fetch(event, data) {

  return new Promise((resolve) => {
    socketIO.emit(event, data, (res) => {
      if (typeof res === 'string') {
        console.log(`Event: ${event} , Socket Respone error: ${res}`)
        resolve(res);
      } else {
        resolve(res);
      }
    });
  });
}



export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}