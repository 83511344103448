import React, { useCallback, useEffect, useState } from 'react';
import { Table } from 'antd';
import { Link } from 'react-router-dom';
import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { URL_SETTING_PROGRESS_RESULT } from '../../../../../../routes/constantRouter';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { getDataProgress, updateListProgress } from '../../../../../../services/RecruiterService';
import { CSS } from '@dnd-kit/utilities';
import Loading from '../../../../../common/SpinLoading';
import { debounce } from 'lodash';
import useSWR from 'swr';
import { URL_API_COMPANY_PROGRESS } from '../../../../../../services';

const Progress = () => {
  const columns = [
    {
      key: 'sort',
      width: '50px',
    },
    {
      title: '進捗',
      dataIndex: 'step',
      key: 'step',
      width: '30%',
    },
    {
      title: '結果',
      dataIndex: 'result',
      key: 'result',
      width: '30%',
      render: (record) => (
        <>
          {record?.map((item, index) => (
            <div key={index}>{item}</div>
          ))}
        </>
      ),
    },
    {
      title: 'ステータス',
      dataIndex: 'enable',
      key: 'status',
      width: '30%',
      render: (record) => (
        <div className={`statusLight ${record ? '-success' : '-neutral'}`}>{record ? '有効' : '無効'}</div>
      ),
    },
    {
      title: '',
      key: 'btn',
      width: '120px',
      render: (record) => (
        <Link to={`/settings-progress-results-detail/${record?._id}`} className='button -primary -small min-w-[76px]'>
          詳細
        </Link>
      ),
    },
  ];

  const Row = ({ children, ...props }) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
      id: props['data-row-key'],
    });
    const style = {
      ...props.style,
      transform: CSS.Transform?.toString(
        transform && {
          ...transform,
          scaleY: 1,
        }
      ),
      transition,
      cursor: 'grab',
      ...(isDragging
        ? {
            position: 'relative',
            zIndex: 9999,
          }
        : {}),
    };
    return (
      <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners}>
        {React?.Children?.map(children, (child) => {
          return child;
        })}
      </tr>
    );
  };

  const [dataSource, setDataSource] = useState([]);

  const updateOderListProgress = async (dataUpdate) => {
    const payload = { progress: dataUpdate };
    await updateListProgress(payload);
  };
  const debounceCallApi = useCallback(
    debounce((data) => updateOderListProgress(data), 1000),
    []
  );
  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      const updateOder = () => {
        const activeIndex = dataSource?.findIndex((i) => i.order === active.id);
        const overIndex = dataSource?.findIndex((i) => i.order === over?.id);
        return arrayMove(dataSource, activeIndex, overIndex);
      };
      const data = updateOder();
      setDataSource((previous) => {
        const activeIndex = previous?.findIndex((i) => i.order === active.id);
        const overIndex = previous?.findIndex((i) => i.order === over?.id);
        return arrayMove(previous, activeIndex, overIndex);
      });
      debounceCallApi(data);
    }
  };

  const {
    data: dataProgress,
    error,
    isLoading,
  } = useSWR(URL_API_COMPANY_PROGRESS, getDataProgress, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  useEffect(() => {
    if (dataProgress) {
      setDataSource(dataProgress?.progress?.progress);
    }
  }, [dataProgress]);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 1,
      },
    })
  );
  return (
    <>
      <div className='panel'>
        {isLoading ? (
          <Loading />
        ) : (
          <div className='panel__body !px-0'>
            <div className='dataList mb-[15px] px-[16px]'>
              <div className='dataList__header -center'>
                <div className='dataList__headerAction'>
                  <Link to={URL_SETTING_PROGRESS_RESULT} className='button -outline-primary -add'>
                    新規登録
                  </Link>
                </div>
              </div>
            </div>
            <div className='table-not-pagination'>
              <DndContext sensors={sensors} modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
                <SortableContext items={dataSource?.map((i) => i?.order)} strategy={verticalListSortingStrategy}>
                  <Table
                    columns={columns}
                    dataSource={dataSource}
                    components={{
                      body: {
                        row: Row,
                      },
                    }}
                    rowKey='order'
                    loading={isLoading}
                    pagination={false}
                    className='table-progress'
                  />
                </SortableContext>
              </DndContext>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default Progress;
