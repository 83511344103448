// unAuthentication
export const URL_LOGIN = '/login';
export const URL_SIGNUP1 = '/signup';
export const URL_VERIFY_EMAIL = '/verify-email';
export const URL_FINISHED_SIGNUP = '/finished-signup';
export const URL_FORGOT_PW = '/forgot-password';
export const URL_RESET_PW = '/firsttime-setpass';
export const PRIVACY_POLICY = '/privacy-policy';
export const USER_DELETION = '/user-deletion';
export const TERM_OF_SERVICE = '/term-of-service';
//Authentication
export const URL_SETTING_INTERNAL = '/initial-settings';
export const URL_PASSWORD_CHANGE = '/password-change';
export const URL_JOBS = '/jobs';
export const URL_APPLICANTS = '/applicants';
export const URL_EMPLOYEES = '/employees';
export const URL_SETTINGS_COMPANY = '/settings';
//job
export const URL_ADD_NEW_JOB = '/jobs-new';
export const URL_JOB_DETAIL = '/jobs-detail-basic/:id';
//applicants
export const URL_APPLICANT_PASS = '/applicants-pass';
export const URL_APPLICANT_DECLINE = '/applicants-decline';
export const URL_APPLICANT_FAILURE = '/applicants-failure';
export const URL_APPLICANT_NEW = '/applicants-new';
export const URL_APPLICANT_DETAIL = '/applicants-detail-basic/:id';
//employees
export const URL_EMPLOYEES_DETAIL = '/employees-detail-basic/:id';
// setting
export const URL_SETTING_USER_NEW = '/settings-users-new';
export const URL_SETTING_DETAIL_USER = '/settings-users-detail/:id';
export const URL_SETTING_PROGRESS_RESULT = '/settings-progress-results-new';
export const URL_SETTING_PROGRESS_RESULT_DETAIL = '/settings-progress-results-detail/:id';

// chat
export const URL_CHAT_APPLICANTS = '/chat';

//error page
export const URL_ERROR_PAGE = '/error-page';
