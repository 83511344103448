/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
import ModalSubmitAddNewSetting from './ModalSubmitAddNewSetting';
import { URL_SETTING_USER_NEW } from '../../../../../../routes/constantRouter';
import { CreateNewUser, GetDetailHr, GetListHr, UpdateHrUser } from '../../../../../../services/RecruiterService';
import {
  displayModal,
  resetRequireValidate,
  handleRequireInvalid,
  handleKeyPress,
  roleHrUser,
  validateEmail,
} from '../../../../../common/FuntionCommon';
import { toast } from '../../../../../../utils/notification';
import { useDispatch, useSelector } from 'react-redux';
import { dataSlice, setDataInitial, setTagNameSettingCompany } from '../../../../../redux/reducer';
import { useParams } from 'react-router-dom';
import ModalResendMailInviteHR from './ModalResendMailInviteHR';
import Loading from '../../../../../common/SpinLoading';
import {
  UPDATE_SUCCESSFULLY,
  LIMIT_PAGE_DEFAULT,
  ERROR_EMAIL,
  PLEASE_CHECK_INPUT,
} from '../../../../../common/constant';
import { URL_API_GET_LIST_HR } from '../../../../../../services';
import useSWR, { mutate } from 'swr';

const SettingUser = () => {
  const [dataNewUser, setDataNewUser] = useState(null);
  const [loading, setLoading] = useState({ loadingDetail: true, loadingAddUpdate: false });
  const [openModalSubmit, setOpenModalSubmit] = useState(false);
  const type = window.location.pathname;
  const { id } = useParams();
  const dispatch = useDispatch();
  const { dataInitial } = useSelector(dataSlice);
  const validatesRef = useRef({
    fFirstName: null,
    fLastName: null,
    fEmail: null,
  });

  const { dataListHr } = useSWR([1, LIMIT_PAGE_DEFAULT, URL_API_GET_LIST_HR], GetListHr, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const onHandleCancel = () => {
    clearErrorMessage();
    if (type == URL_SETTING_USER_NEW) {
      document.forms['settingUser'].reset();
    } else {
      fillDataUSer(dataDetailUserHr);
    }
  };

  const clearErrorMessage = () => {
    setShowErrorEmail('');
    validatesRef?.current?.fFirstName?.setCustomValidity('');
    validatesRef?.current?.fLastName?.setCustomValidity('');
    validatesRef?.current?.fEmail?.setCustomValidity('');
  };
  const [showErrorEmail, setShowErrorEmail] = useState(false);
  const validateInput = () => {
    let validatedItem = true;

    //validate email
    const isEmail = validateEmail(document.forms['settingUser']['fEmail'].value);
    if (!isEmail) {
      setShowErrorEmail(ERROR_EMAIL);
      validatedItem = false;
    }

    return validatedItem;
  };
  const [dataDetailUserHr, setDataDetailUserHr] = useState(null);
  const fillDataUSer = (data) => {
    const dataForm = document.forms['settingUser'];
    dataForm['fStatus'].value = data?.status;
    dataForm['fFirstName'].value = data?.firstName;
    dataForm['fLastName'].value = data?.lastName;
    dataForm['fEmail'].value = data?.email;
    dataForm['fRole'].value = data?.role;
  };
  const getDataDetail = async () => {
    const data = await GetDetailHr(id);
    if (data) {
      setDataDetailUserHr(data?.HR);
    }
    setLoading((prev) => ({ ...prev, loadingDetail: false }));
  };
  useEffect(() => {
    if (type !== URL_SETTING_USER_NEW) {
      getDataDetail();
    }
  }, [id]);

  useEffect(() => {
    if (!loading.loadingDetail && dataDetailUserHr) {
      fillDataUSer(dataDetailUserHr);
    }
  }, [dataDetailUserHr, loading.loadingDetail]);

  const onBackBtn = async () => {
    await dispatch(setTagNameSettingCompany('user'));
    history.back();
  };
  const createNewUser = async () => {
    const dataForm = document.forms['settingUser'];
    const data = await CreateNewUser({
      firstName: dataForm['fFirstName'].value,
      lastName: dataForm['fLastName'].value,
      email: dataForm['fEmail'].value,
      role: dataForm['fRole'].value,
    });
    return data;
  };
  const onSubmit = async () => {
    const validated = validateInput();

    if (!validated) {
      // If there are any error in validate, then do nothing
      toast('error', PLEASE_CHECK_INPUT);
      return;
    }
    setLoading((prev) => ({ ...prev, loadingAddUpdate: true }));
    if (type === URL_SETTING_USER_NEW) {
      const data = await createNewUser();
      if (data) {
        setDataNewUser(data?.HR);
        setOpenModalSubmit(true);
        mutate([1, LIMIT_PAGE_DEFAULT, URL_API_GET_LIST_HR], dataListHr, true);
      }
    } else {
      const dataForm = document.forms['settingUser'];
      const data = await UpdateHrUser(id, {
        status: dataForm['fStatus'].value,
        firstName: dataForm['fFirstName'].value,
        lastName: dataForm['fLastName'].value,
        email: dataForm['fEmail'].value,
        role: dataForm['fRole'].value,
      });
      if (data) {
        toast('success', UPDATE_SUCCESSFULLY);
        setDataDetailUserHr(data?.HR);
        const dataSetting = JSON.parse(localStorage?.getItem('dataSetting'));
        if (id === JSON.parse(localStorage.getItem('id'))) {
          dispatch(
            setDataInitial({
              ...dataInitial,
              userName: data?.HR?.lastName + ' ' + data?.HR?.firstName,
              email: data?.HR?.email,
            })
          );
          localStorage.setItem(
            'dataSetting',
            JSON.stringify({
              ...dataSetting,
              userName: data?.HR?.lastName + ' ' + data?.HR?.firstName,
              email: data?.HR?.email,
            })
          );
        }
      }
    }
    setLoading((prev) => ({ ...prev, loadingAddUpdate: false }));
    mutate([1, LIMIT_PAGE_DEFAULT, URL_API_GET_LIST_HR]);
  };

  useEffect(() => {
    if (type !== URL_SETTING_USER_NEW) {
      displayModal();
    }
  });

  return (
    <>
      {loading.loadingDetail && type !== URL_SETTING_USER_NEW ? (
        <Loading />
      ) : (
        <>
          <div className='pageHeader'>
            {type === URL_SETTING_USER_NEW ? (
              <h1 className='pageHeader__title'> ユーザーの登録</h1>
            ) : (
              <>
                <div className='pageHeader__start'>
                  <h1 className='pageHeader__title'>{dataDetailUserHr?.username}</h1>
                  <div className='pageHeader__detail'>
                    <div className={`statusLight ${dataDetailUserHr?.status === 'enable' ? '-success' : '-neutral'}`}>
                      {dataDetailUserHr?.status === 'enable' ? '有効' : '無効'}
                    </div>
                    <dl className='pageHeader__definitionList'>
                      <dt>メールアドレス：</dt>
                      <dd>{dataDetailUserHr?.email}</dd>
                      <dt>権限：</dt>
                      <dd>{roleHrUser(dataDetailUserHr?.role)}</dd>
                    </dl>
                  </div>
                </div>
                {dataDetailUserHr?._id !== JSON.parse(localStorage.getItem('id')) && (
                  <div className='pageHeader__end'>
                    <button
                      type='button'
                      className='button -outline-primary -mail'
                      data-modal-target='resend-invitation'
                    >
                      招待メールを再送
                    </button>
                  </div>
                )}
              </>
            )}
          </div>
          {dataDetailUserHr?._id === JSON.parse(localStorage?.getItem('id')) && (
            <div className='callout -warning u-spacer-bottom2'>
              ※自身のアカウントの<strong>［ステータス］、［権限］</strong>
              は変更できません。変更の際は他の管理者に依頼してください。
            </div>
          )}
          <div className='panel'>
            <div className='panel__body -large'>
              <form
                onKeyDown={handleKeyPress}
                onSubmit={(e) => (e.preventDefault(), onSubmit())}
                name='settingUser'
                className='form'
              >
                <table className='detailTable'>
                  {type !== URL_SETTING_USER_NEW && (
                    <tr
                      className={`${
                        dataDetailUserHr?._id === JSON.parse(localStorage.getItem('id')) ? 'opacity-50' : ''
                      }`}
                    >
                      <th>ステータス</th>
                      <td>
                        <div className='radio-group'>
                          <label className='radio'>
                            <input
                              disabled={dataDetailUserHr?._id === JSON.parse(localStorage.getItem('id')) ? true : false}
                              type='radio'
                              name='fStatus'
                              value={'enable'}
                              className='radio__input'
                              defaultChecked
                            />
                            <span className='radio__label'>有効</span>
                          </label>
                          <label className='radio'>
                            <input
                              disabled={dataDetailUserHr?._id === JSON.parse(localStorage.getItem('id')) ? true : false}
                              type='radio'
                              name='fStatus'
                              value={'disable'}
                              className='radio__input'
                            />
                            <span className='radio__label'>無効</span>
                          </label>
                        </div>
                        <p className='detailTable__note'>
                          ※無効にされたユーザーは、管理画面やチャット画面にログインできなくなります。（アカウントやデータは削除されません）
                        </p>
                      </td>
                    </tr>
                  )}
                  <tr>
                    <th>
                      氏名<span className='badge -danger u-spacer-left1'>必須</span>
                    </th>
                    <td>
                      <div className='form__inline'>
                        <input
                          type='text'
                          required
                          onInvalid={(e) => handleRequireInvalid(e)}
                          onChange={(e) => resetRequireValidate(e)}
                          className='textfield -short'
                          name='fLastName'
                          placeholder='姓'
                          ref={(e) => (validatesRef.current.fLastName = e)}
                        />
                        <input
                          type='text'
                          required
                          onInvalid={(e) => handleRequireInvalid(e)}
                          onChange={(e) => resetRequireValidate(e)}
                          className='textfield -short'
                          name='fFirstName'
                          placeholder='名'
                          ref={(e) => (validatesRef.current.fFirstName = e)}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      メールアドレス<span className='badge -danger u-spacer-left1'>必須</span>
                    </th>
                    <td>
                      <div className='flex gap-[20px] items-center'>
                        <input
                          name='fEmail'
                          required
                          onInvalid={(e) => handleRequireInvalid(e)}
                          onChange={(e) => resetRequireValidate(e)}
                          className='textfield'
                          placeholder='例）taro@example.com'
                          ref={(e) => (validatesRef.current.fEmail = e)}
                        />
                        {showErrorEmail && <span className='text-[red]'>{showErrorEmail}</span>}
                      </div>
                      <p className='detailTable__note'>※入力されたメールアドレス宛てに招待メールが届きます。</p>
                    </td>
                  </tr>
                  <tr
                    className={`${
                      dataDetailUserHr?._id === JSON.parse(localStorage.getItem('id')) ? 'opacity-50' : ''
                    }`}
                  >
                    <th>
                      権限<span className='badge -danger u-spacer-left1'>必須</span>
                    </th>
                    <td>
                      <div className='radio-group'>
                        <label className='radio'>
                          <input
                            disabled={dataDetailUserHr?._id === JSON.parse(localStorage.getItem('id')) ? true : false}
                            type='radio'
                            name='fRole'
                            value='kanri'
                            className='radio__input'
                            defaultChecked
                          />
                          <span className='radio__label'>管理者</span>
                        </label>
                        <label className='radio'>
                          <input
                            disabled={dataDetailUserHr?._id === JSON.parse(localStorage.getItem('id')) ? true : false}
                            type='radio'
                            name='fRole'
                            value='sub_kanri'
                            className='radio__input'
                          />
                          <span className='radio__label'>準管理者</span>
                        </label>
                        <label className='radio'>
                          <input
                            disabled={dataDetailUserHr?._id === JSON.parse(localStorage.getItem('id')) ? true : false}
                            type='radio'
                            name='fRole'
                            value='chat_only'
                            className='radio__input'
                          />
                          <span className='radio__label'>チャットのみ参加</span>
                        </label>
                      </div>
                      <div className='detailTable__noteBox'>
                        <dl>
                          <dt>【管理者】</dt>
                          <dd>すべての機能が利用できます。</dd>
                          <dt>【準管理者】</dt>
                          <dd>
                            管理者画面「設定」を除く機能が利用できます。会社情報、ユーザー、進捗の追加や編集はできません。
                          </dd>
                          <dt>【チャットのみ参加】</dt>
                          <dd>招待された応募者とのチャットにのみ参加できます。管理画面へのログインはできません。</dd>
                        </dl>
                      </div>
                    </td>
                  </tr>
                </table>
                <div className='u-spacer-top4'>
                  <div className='button-wrapper -center'>
                    <button onClick={() => onHandleCancel()} type='button' className='button -default'>
                      キャンセル
                    </button>
                    <button type='submit' disabled={loading.loadingAddUpdate} className={'button -primary'}>
                      {loading?.loadingAddUpdate && <i className='fa fa-spinner fa-spin'></i>}保存
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div className='u-spacer-top4'>
            <button type='button' onClick={() => onBackBtn()} className='button -default -back'>
              一覧に戻る
            </button>
          </div>
          <ModalResendMailInviteHR dataDetail={dataDetailUserHr} />
          {openModalSubmit && (
            <ModalSubmitAddNewSetting
              openModalSubmit={openModalSubmit}
              dataUser={dataNewUser}
              setOpenModalSubmit={setOpenModalSubmit}
            />
          )}
        </>
      )}
    </>
  );
};
export default SettingUser;
