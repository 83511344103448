import { Navigate } from 'react-router';
import Footer from '../../template/Footer';
import React from 'react';

const LayoutUnAuthentication = ({children}) => {
  const token = localStorage.getItem('token');
  if(token) {
    return <Navigate to={'/jobs'}/>
  } else 
    return(
      <div className="layout">
        <div className="layout__main">
          {children}
        </div>
        <Footer />
      </div>
    )
}
export default LayoutUnAuthentication;