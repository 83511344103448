import React, { useEffect } from 'react';
import { RecruiterResendOTP, RecruiterVerifyOTP } from '../../../../services/RecruiterService';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { resetRequireValidate, handleRequireInvalid } from '../../../common/FuntionCommon';
import { toast } from '../../../../utils/notification';
import * as CONSTANT from '../../../common/constant';
import { URL_FINISHED_SIGNUP, URL_SIGNUP1 } from '../../../../routes/constantRouter';

const SignupVerifyOtp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const email = location?.state?.email;

  useEffect(() => {
    if (!email) {
      navigate(URL_SIGNUP1);
    }
  }, []);

  const handleSignupStep2SenOTP = async (e) => {
    e.preventDefault();
    const dataStep2 = document.forms['step2'];
    const data = await RecruiterVerifyOTP(email, dataStep2['otp'].value);
    if (data) {
      return navigate(URL_FINISHED_SIGNUP, { state: { type: 'success' } });
    }
  };
  const handleSignupResendSenOTP = async () => {
    const response = await RecruiterResendOTP(email);
    if (response) {
      toast('success', CONSTANT.RESEND_MAIL_SUCCESSFULLY);
    }
  };

  const signupContentStep2 = (
    <>
      <p className='u-spacer-bottom2'>
        あなたのメールアドレスに宛てに認証メールをお送りしました。
        <br />
        メールに記載された認証コードを入力してください。
      </p>
      <form name='step2' onSubmit={(e) => handleSignupStep2SenOTP(e)} className='form'>
        <fieldset className='form__group'>
          <label htmlFor='authentication-code' className='form__label'>
            認証コード
          </label>
          <div className='form__field'>
            <input
              name='otp'
              required
              onInvalid={(e) => handleRequireInvalid(e)}
              onChange={(e) => resetRequireValidate(e)}
              className='textfield'
              id='authentication-code'
            />
          </div>
        </fieldset>
        <div className='form__group'>
          <button type='submit' className='button -primary -full'>
            次へ
          </button>
        </div>
      </form>
      <div className='u-spacer-top6'>
        <p className='u-spacer-bottom2'>認証メールが届かない場合は、以下より認証コードの再送信を行なってください。</p>
        <button onClick={() => handleSignupResendSenOTP()} type='button' className='button -outline-primary -full'>
          認証コードを再送信
        </button>
      </div>
    </>
  );
  return (
    <>
      <div className='layout__container'>
        <div className='u-spacer-top2 u-spacer-bottom3 u-align-center'>
          <img src='logo.svg' alt='CAERU採用管理' width='300' height='54' />
        </div>

        <div className='stepper'>
          <ol className='stepper__list'>
            <li className={'stepper__item'}>
              <span className='stepper__number'>1</span>
              <p className='stepper__text'>アカウント情報入力</p>
            </li>
            <li className={'stepper__item -active'}>
              <span className='stepper__number'>2</span>
              <p className='stepper__text'>認証コード入力</p>
            </li>
            <li className={'stepper__item'}>
              <span className='stepper__number'>3</span>
              <p className='stepper__text'>アカウント登録完了</p>
            </li>
          </ol>
        </div>
      </div>
      <div className='layout__container -narrow'>
        <div className='panel u-spacer-top3'>
          <div className='panel__body -large'>{signupContentStep2}</div>
        </div>
        <p className='u-spacer-top3 u-align-center'>
          <Link to='/'>ログイン画面に戻る</Link>
        </p>
      </div>
    </>
  );
};
export default SignupVerifyOtp;
