import React from 'react';
import { Link } from 'react-router-dom';
import * as URL from '../../../../../../routes/constantRouter';

const HeaderSearchApplicants = ({ dataAmount }) => {
  return (
    <div className='pageHeader'>
      <div className='pageHeader__start'>
        <h1 className='pageHeader__title'>応募管理</h1>
        <div className='segmentedControl'>
          <Link
            to={URL.URL_APPLICANTS}
            className={`segmentedControl__item ${window.location.pathname === URL.URL_APPLICANTS ? '-active' : ''}`}
          >
            {`選考中（${dataAmount?.選考中 ?? 0}）`}
          </Link>
          <Link
            to={URL.URL_APPLICANT_PASS}
            className={`segmentedControl__item ${window.location.pathname === URL.URL_APPLICANT_PASS ? '-active' : ''}`}
          >
            {`内定（${dataAmount?.内定 ?? 0}）`}
          </Link>
          <Link
            to={URL.URL_APPLICANT_DECLINE}
            className={`segmentedControl__item ${
              window.location.pathname === URL.URL_APPLICANT_DECLINE ? '-active' : ''
            }`}
          >
            {`辞退（${dataAmount?.辞退 ?? 0}）`}
          </Link>
          <Link
            to={URL.URL_APPLICANT_FAILURE}
            className={`segmentedControl__item ${
              window.location.pathname === URL.URL_APPLICANT_FAILURE ? '-active' : ''
            }`}
          >
            {`不合格（${dataAmount?.不合格 ?? 0}）`}
          </Link>
        </div>
      </div>
    </div>
  );
};
export default HeaderSearchApplicants;
