import React from 'react';
import { initialSetting } from '../../../../services/RecruiterService';
import { useNavigate } from 'react-router-dom';
import { URL_JOBS } from '../../../../routes/constantRouter';
import { resetRequireValidate, handleRequireInvalid } from '../../../common/FuntionCommon';
import Footer from '../../../template/Footer';
import { toast } from '../../../../utils/notification';

import * as CONSTANT from '../../../common/constant';

const InitialSetting = () => {
  const navigate = useNavigate();
  const dataSetting = JSON.parse(localStorage?.getItem('dataSetting'));

  const handleSetting = async (e) => {
    e.preventDefault();
    const dataForm = document.forms['initialSetting'];
    let payload = {
      firstName: dataForm['firstName'].value,
      lastName: dataForm['lastName'].value,
      companyCode: dataForm['companyCode'].value,
      companyName: dataForm['companyName'].value,
    };
    const data = await initialSetting(payload);
    if (data) {
      dataSetting.companyCode = data?.company.companyCode;
      dataSetting.companyName = data?.company.companyName;
      dataSetting.userName = dataForm['lastName'].value + dataForm['firstName'].value;
      localStorage.setItem('dataSetting', JSON.stringify(dataSetting));
      toast('success', CONSTANT.SETTING_SUCCESSFULLY);
      navigate(URL_JOBS);
    }
  };

  return (
    <>
      <div className='layout__main'>
        <div className='layout__container -narrow'>
          <div className='u-spacer-top2 u-align-center'>
            <img src='logo.svg' alt='CAERU採用管理' width='300' height='54' />
          </div>

          <p className='callout -warning u-spacer-top2'>会社IDは後から変更することはできませんので、ご注意ください。</p>

          <div className='panel u-spacer-top3'>
            <div className='panel__body -large'>
              <h2 className='panel__title'>初期設定</h2>
              <form name='initialSetting' onSubmit={(e) => handleSetting(e)} className='form u-spacer-top2'>
                <fieldset className='form__group'>
                  <>
                    <label htmlFor='username' className='form__label'>
                      氏名（あなたのお名前）
                    </label>
                    <div className='form__field flex gap-[10px]'>
                      <input
                        name='lastName'
                        type='text'
                        required
                        onInvalid={(e) => handleRequireInvalid(e)}
                        onChange={(e) => resetRequireValidate(e)}
                        className='textfield'
                        id='lastName'
                      />
                      <input
                        name='firstName'
                        type='text'
                        required
                        onInvalid={(e) => handleRequireInvalid(e)}
                        onChange={(e) => resetRequireValidate(e)}
                        className='textfield'
                        id='firstName'
                      />
                    </div>
                  </>
                </fieldset>
                <fieldset className='form__group'>
                  <label htmlFor='company-id' className='form__label'>
                    会社ID（半角英字）
                  </label>
                  <div className='form__field'>
                    <input
                      name='companyCode'
                      type='text'
                      className='textfield'
                      id='company-id'
                      required
                      onInvalid={(e) => handleRequireInvalid(e)}
                      onChange={(e) => resetRequireValidate(e)}
                    />
                    <p className='u-spacer-top1 u-text-light'>
                      ※求職者が求人を探すときにも使用されます。分かりやすい名前をつけるのがオススメです。
                    </p>
                  </div>
                </fieldset>
                <fieldset className='form__group'>
                  <label htmlFor='company-name' className='form__label'>
                    会社名
                  </label>
                  <div className='form__field'>
                    <input
                      name='companyName'
                      type='text'
                      className='textfield'
                      id='company-name'
                      required
                      onInvalid={(e) => handleRequireInvalid(e)}
                      onChange={(e) => resetRequireValidate(e)}
                    />
                  </div>
                </fieldset>
                <div className='form__group'>
                  <button type='submit' className='button -primary -full'>
                    登録
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default InitialSetting;
