import { useDispatch } from 'react-redux';
import { URL_CHAT_APPLICANTS, URL_JOBS, URL_LOGIN, URL_SETTING_INTERNAL } from '../../../../routes/constantRouter';
import Footer from '../../../template/Footer';
import HeaderSetting from './HeaderSetting';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { setCurrentActiveGroupData } from '../../../redux/reducerChat';
import { ROLE_CHAT_ONLY } from '../../../common/constant';
import { displayModal } from '../../../common/FuntionCommon';

const SettingComponent = ({ children, type }) => {
  const token = JSON.parse(localStorage?.getItem('token'));
  const role = JSON.parse(localStorage?.getItem('role'));
  const dataSettingCompany = JSON.parse(localStorage?.getItem('dataSetting'));

  const dispatch = useDispatch();
  useEffect(() => {
    if (window.location.pathname !== 'chat') {
      dispatch(setCurrentActiveGroupData({}));
    }
  }, [window.location.pathname]);
  const myData = window?.location?.href;
  const navigate = useNavigate();
  useEffect(() => {
    if (!token) {
      return navigate(URL_LOGIN, { state: { myData } });
    } else if (token && role === ROLE_CHAT_ONLY && window.location.pathname !== '/password-change') {
      return navigate(URL_CHAT_APPLICANTS);
    } else if (token && !dataSettingCompany?.companyCode) {
      return navigate(URL_SETTING_INTERNAL);
    } else if (token && dataSettingCompany?.companyCode && window.location.pathname === URL_SETTING_INTERNAL) {
      return navigate(URL_JOBS);
    }
  }, [window.location.pathname]);
  useEffect(() => {
    displayModal();
  });
  return (
    <>
      {token && (
        <>
          <div className='layout'>
            {!type ? (
              <>
                <HeaderSetting />
                <div className='layout__main'>
                  <div className='layout__container'>{children}</div>
                </div>
                <Footer />
              </>
            ) : (
              <>{children}</>
            )}
          </div>
        </>
      )}
    </>
  );
};
export default SettingComponent;
