import React, { useEffect } from 'react';
import moment from 'moment';
import { displayModal } from '../../../../../common/FuntionCommon';
import { NO_DATA } from '../../../../../common/constant';
const DetailApplication = ({ data }) => {
  const dataApplications = data?.applications?.reverse();

  const showDateFieldOnSelection = () => {
    // 選考ステータス:ラジオボタンの親要素
    const radioGroups = document.querySelectorAll('.js-selectionStatus-radioGroup');

    if (radioGroups.length) {
      radioGroups.forEach((radioGroup) => {
        radioGroup.addEventListener('change', (event) => {
          // 選択されたラジオボタンのテキストを取得
          const selectedText = event.target.nextElementSibling.textContent;

          // 日付を表示エリアとラベル表示する要素を取得
          const selectionResultDateDisplay = event.target.closest('.radio-group').nextElementSibling;
          const selectionResultDateLabel = selectionResultDateDisplay.querySelector('.js-selectionStatus-dateLabel');

          if (selectedText !== '選考中' && selectedText !== null) {
            // 「選考中」以外が選ばれている場合、日付フィールドを表示して
            selectionResultDateDisplay.style.display = 'block';
            selectionResultDateLabel.textContent = `${selectedText}日`;
          } else {
            selectionResultDateDisplay.style.display = 'none';
          }
        });
      });
    }
  };
  useEffect(() => {
    showDateFieldOnSelection();
  }, []);

  useEffect(() => {
    displayModal();
  });

  return (
    <>
      {dataApplications?.map((record, index) => (
        <div key={record?._id} className='panel'>
          <div className='panel__body -large'>
            <section className='panelBox'>
              <div className='panelBox__header'>
                <div className='panelBox__headerStart'>
                  <h2 className='title -secondary'>{record.jobTitle}</h2>
                  <p className='panelBox__titleMeta'>求人ID：{record?.jobCode}</p>
                </div>
              </div>
              <div className='panelBox__body'>
                <form name={`detailApplication${index}`} className='form'>
                  <table className='detailTable'>
                    <tr>
                      <th>
                        応募日<span className='badge -danger u-spacer-left1'>必須</span>
                      </th>
                      <td>
                        <p>{moment(record?.appliedDate)?.format('YYYY-MM-DD')}</p>
                        {/* <input type="date" className="textfield -short" name='dateTime'/> */}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        ステータス<span className='badge -danger u-spacer-left1'>必須</span>
                      </th>
                      <td>
                        <div className='radio-group js-selectionStatus-radioGroup'>
                          <p>{record?.evaluationStatus}</p>
                        </div>
                        {record?.evaluationDate && (
                          <div
                            className={`u-spacer-top2 u-display-none ${
                              record?.evaluationStatus !== '選考中' ? '!block' : ''
                            }`}
                          >
                            <p className='js-selectionStatus-dateLabel'>
                              {record?.evaluationStatus !== '選考中' ? record?.evaluationStatus + '日' : ''}
                            </p>
                            <p>{moment(record?.evaluationDate)?.format('YYYY-MM-DD')}</p>
                          </div>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>評価</th>
                      <td>
                        <div className='form__inline'>
                          <span className='star'></span>
                          <p>{record?.rating}</p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>備考</th>
                      <td>
                        <p>{record?.note}</p>
                      </td>
                    </tr>
                  </table>
                </form>

                <div className='u-spacer-top6'>
                  <section className='dataList'>
                    <div className='dataList__header'>
                      <h3 className='title -secondary'>選考状況</h3>
                    </div>
                    <div className='dataList__body'>
                      <table className='dataList__table -fixed'>
                        <thead>
                          <tr>
                            <th>進捗</th>
                            <th>面接日時</th>
                            <th>結果</th>
                            {/* <th style={{width: '192px'}}></th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {record?.progress?.map((item) => (
                            <tr key={item._id}>
                              <td>{item?.step}</td>
                              {item?.interviewStart ? (
                                <td>
                                  {moment(item?.interviewStart).format('YYYY/MM/DD')}
                                  <span className='u-display-inlineBlock'>
                                    {' '}
                                    {moment(item?.interviewStart).format('HH:mm')}-
                                    {item?.interviewEnd ? moment(item?.interviewEnd).format('HH:mm') : ''}
                                  </span>
                                </td>
                              ) : (
                                <td></td>
                              )}
                              <td className='!text-left'>{item?.result}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {record?.progress?.length === 0 && (
                        <div className='w-fit p-[50px] m-auto text-[#d3d4d4]'>{NO_DATA}</div>
                      )}
                    </div>
                  </section>
                </div>
              </div>
            </section>
          </div>
        </div>
      ))}
    </>
  );
};
export default DetailApplication;
