import React from 'react';
import { LIMIT_PAGE_DEFAULT } from '../../common/constant';

const PaginationCustom = ({ onChangePage, currentPage, type, pagination }) => {
  let itemPage = [];
  for (let i = 0; i < pagination?.totalCount / pagination?.limit ?? LIMIT_PAGE_DEFAULT; i++) {
    itemPage.push(
      <div key={i} role='button' aria-hidden onClick={() => onChangePage(i + 1)} className='pagination__item'>
        <span className={`pagination__button ${currentPage === i + 1 ? '-active' : ''}`}>{i + 1}</span>
      </div>
    );
  }

  return (
    <div className='pagination'>
      {!type && (
        <span className='pagination__detail'>
          {pagination?.totalCount}件中 1-{pagination?.limit}
        </span>
      )}
      <ul className='pagination__list'>
        <li className='pagination__item'>
          <button
            onClick={() => (currentPage == 1 ? null : onChangePage(currentPage - 1))}
            className={`pagination__button -first ${currentPage == 1 ? '-disabled' : ''}`}
          />
        </li>
        {itemPage}
        <li className='pagination__item'>
          <button
            onClick={() =>
              pagination?.totalCount / LIMIT_PAGE_DEFAULT <= currentPage ? null : onChangePage(currentPage + 1)
            }
            className={`pagination__button -last ${
              pagination?.totalCount / LIMIT_PAGE_DEFAULT <= currentPage ? '-disabled' : ''
            }`}
          />
        </li>
      </ul>
    </div>
  );
};
export default PaginationCustom;
