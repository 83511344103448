//UnAuthentication
export const URL_API_RECRUITER_LOGIN = 'recruiter/auth/login';
export const URL_API_LOGOUT = 'recruiter/logout';
export const URL_API_RECRUITER_REGISTER = 'recruiter/auth/register';
export const URL_API_RECRUITER_VERIFY_OTP = 'recruiter/auth/verifyOTP';
export const URL_API_RECRUITER_RESEND_OTP = 'recruiter/auth/resendOTP';
export const URL_API_RECRUITER_FORGOT_PW = 'recruiter/auth/forgot';
export const URL_API_RECRUITER_RESET_PW = 'recruiter/auth/reset-password';
export const URL_API_RECRUITER_SET_PASSWORD_FIRSTTIME = 'recruiter/auth/firsttime-setpass';
export const URL_API_INITIAL_SETTINGS = 'company/init';
export const URL_API_CHANGE_NEW_PASSWORD = 'recruiter/auth/change-password';
//job
export const URL_API_CREATE_NEW_RECRUITMENT = '/recruitment/';
export const URL_API_UPDATE_RECRUITMENT = '/recruitment/';
export const URL_API_GET_LIST_RECRUITMENT = '/recruitment/';
export const URL_API_GET_DETAIL_RECRUITMENT = '/recruitment/';
export const URL_API_DELETE_RECRUITMENT = '/recruitment/';
export const URL_API_REGISTER_USER_APPLICANT = '/recruitment/employee';
//setting
export const URL_API_CREATE_NEW_USER = '/company/hr';
export const URL_API_GET_LIST_HR = '/company/hr';
export const URL_API_GET_DETAIL_HR = '/company/hr/';
export const URL_API_UPDATE_HR = '/company/hr/';
export const URL_API_RESEND_EMAIL = '/company/invite-hr-again/';
export const URL_API_SETTING_COMPANY = '/company/setting';
export const URL_API_DETAIL_COMPANY = '/company/detail';
export const URL_API_COMPANY_PROGRESS = '/company/progress/';
export const URL_API_COMPANY_PROGRESS_UPDATE_ODER = '/company/progress-order';
//applicant
export const URL_API_GET_ALL_APPLICANT = 'company/applicant';
export const URL_API_CREATE_APPLICANT = 'company/applicant/';
export const URL_API_DETAIL_APPLICANT = 'company/applicant/';
export const URL_API_UPDATE_APPLICANT = 'company/applicant/';
export const URL_API_INVITE_APPLICANT = 'company/invite';
export const URL_API_INVITE_APPLICANT_AGAIN = 'company/invite-again';
export const URL_API_REFLECT_APPLICANT_INFO = 'company/applicant/reflect';
export const URL_API_REFLECT_PDF_CV = 'company/reflect/pdfcv';
export const URL_API_REFLECT_PDF_WORK_HISTORY = 'company/reflect/pdfworkhistory';
export const URL_API_EVALUATE = 'company/evaluate/';
export const URL_API_LIST_APPLICANT_JOB = '/recruitment-applying/';
export const URL_API_ADD_PROGRESS_FOR_APPLICANT = '/company/applicant/progress/';
export const URL_API_DELETE_PROGRESS_FOR_APPLICANT = '/company/applicant/progress/';
export const URL_API_UPDATE_HR_FOR_GROUP_CHAT = '/recruitment/group/';
export const URL_API_GET_URL_PDF_CV_FOR_APPLICANT = '/pdfCV/';
export const URL_API_GET_URL_PDF_WORK_HISTORY_FOR_APPLICANT = '/pdfWorkHistory/';
export const URL_API_GET_URL_PDF_CV_FOR_EMPLOYEE = '/employee/pdfCV/';
export const URL_API_GET_URL_PDF_WORK_HISTORY_FOR_EMPLOYEE = '/employee/pdfWorkHistory/';
export const URL_API_GET_URL_PDF_CV_FOR_CHAT_PAGE = '/previewchat/pdfCV/';
export const URL_API_GET_URL_PDF_WORK_HISTORY_FOR_CHAT_PAGE = '/previewchat/pdfWorkHistory/';
//employee
export const URL_API_CREATE_EMPLOYEE = '/recruitment/employee/';
export const URL_API_GET_ALL_EMPLOYEE = '/recruitment-employee/';
export const URL_API_DETAIL_EMPLOYEE = '/recruitment-employee/';
export const URL_API_UPDATE_EMPLOYEE = '/recruitment/employee/';
export const URL_API_DELETE_EMPLOYEE = '/recruitment/employee/';

export const URL_API_GET_TOTAL_DATA_APPLICANT = '/company/applicant-amount';
export const URL_API_GET_TOTAL_DATA_RECRUITMENT = 'recruitment-amount/';
export const URL_API_GET_TOTAL_DATA_EMPLOYEE = '/employee-amount';
