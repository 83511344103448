import React from 'react';
import Footer from '../../../template/Footer';
const PrivacyPolicy = () => {
  return (
    <div className='layout !min-w-[unset]'>
      <main className='layout__main'>
        <div className='layout__container -agreement'>
          <div className='panel u-spacer-top3 panel__body -large'>
            <h1 className='agreement__title u-font-bold'>プライバシーポリシー</h1>
            <div className='paragraph'>
              CAERU株式会社（以下「当社」）は、以下のとおり個人情報保護方針を定め、個人情報保護の仕組みを構築し、全従業員に個人情報保護の重要性の認識と取組みを徹底させることにより、個人情報の保護を推進致します
            </div>
            <div className='paragraph'>
              <h2 className='agreement__subtitle u-font-bold'>個人情報の管理</h2>
              <p>
                当社は、お客さまの個人情報を正確かつ最新の状態に保ち、個人情報への不正アクセス・紛失・破損・改ざん・漏洩などを防止するため、セキュリティシステムの維持・管理体制の整備・社員教育の徹底等の必要な措置を講じ、安全対策を実施し個人情報の厳重な管理を行ないます。
              </p>
            </div>
            <div className='paragraph'>
              <h2 className='agreement__subtitle u-font-bold'>個人情報の利用目的</h2>
              <p>
                お客さまからお預かりした個人情報は、当社からのご連絡やご質問に対する回答として、電子メールや資料のご送付に利用いたします。
              </p>
            </div>
            <div className='paragraph'>
              <h2 className='agreement__subtitle u-font-bold'>個人情報の安全対策</h2>
              <p>当社は、個人情報の正確性及び安全性確保のために、セキュリティに万全の対策を講じています。</p>
            </div>
            <div className='paragraph'>
              <h2 className='agreement__subtitle u-font-bold'>法令、規範の遵守と見直し</h2>
              <p>
                当社は、保有する個人情報に関して適用される日本の法令、その他規範を遵守するとともに、本ポリシーの内容を適宜見直し、その改善に努めます。
              </p>
            </div>
            <div className='paragraph'>
              <h2 className='agreement__subtitle u-font-bold'>プライバシーポリシーに関するお問い合わせ</h2>
              <p>CAERU株式会社</p>
              <p>css@caeru.asia</p>
              <p>〒735-0026 広島県安芸郡府中町桃山1丁目16‐5</p>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};
export default PrivacyPolicy;
