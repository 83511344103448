import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { URL_ADD_NEW_JOB } from '../../../../../routes/constantRouter';
import React from 'react';
import { getFullNameForListHR, jobType, onHandleChatNewTab, statusChat } from '../../../../common/FuntionCommon';
import PaginationCustom from '../../../../template/Pagination';
import { GetListHr, GetListRecruitment, getTotalRecuiterForStatus } from '../../../../../services/RecruiterService';
import moment from 'moment';
import Loading from '../../../../common/SpinLoading';
import {
  CHATTING_STATUS,
  CHAT_PENDING_STATUS,
  LIMIT_PAGE_DEFAULT,
  NEW_CHAT_PENDING_STATUS,
  NO_DATA,
  TYPE_ASC,
  TYPE_DESC,
} from '../../../../common/constant';

/**
 * Search list jobs
 * @returns  list jobs
 */

const JobsBody = () => {
  // STATEs
  const [currentPage, setCurrentPage] = useState(1);

  const [dataJobs, setDataJobs] = useState();
  const [pagination, setPagination] = useState();
  const [dataListHr, setDataListHr] = useState();
  const [dataTotal, setDataTotal] = useState({});
  const [loading, setLoading] = useState(false);

  const [querySearchValue, setQuerySearchValue] = useState({
    status: 'new_chat_pending,chatting',
    onlyMe: true,
    sortField: 'jobCode',
    sortOrder: TYPE_DESC,
  });
  const [sortOrder, setSortOrder] = useState(TYPE_DESC);

  // FUNCTIONS
  const onChangePage = (page) => {
    setCurrentPage(page);
  };

  const getDataListHr = async () => {
    const data = await GetListHr([currentPage, 1000]);
    const finishData = getFullNameForListHR(data);
    setDataListHr(finishData);
  };

  const handleSearch = async (typeSort = TYPE_DESC) => {
    const searchData = document?.forms['formSearchJob'];
    const dateStartFrom = searchData['startDateFrom']?.value ? new Date(searchData['startDateFrom']?.value) : null;
    const dateStartTo = searchData['startDateTo']?.value ? new Date(searchData['startDateTo']?.value) : null;
    const dateEndFrom = searchData['endDateFrom']?.value ? new Date(searchData['endDateFrom']?.value) : null;
    const dateEndTo = searchData['endDateTo']?.value ? new Date(searchData['endDateTo']?.value) : null;

    const chatPending = searchData['chatPending']?.checked ? 'chat_pending' : '';
    const chatting = searchData['chatting']?.checked ? 'chatting' : '';
    const newChatPending = searchData['newChatPending']?.checked ? 'new_chat_pending' : '';
    const statusJob = [chatting, chatPending, newChatPending].filter(Boolean).join(',');

    const querySearch = {
      status: statusJob,
      onlyMe: searchData['onlyMe']?.checked,
      jobCode: searchData['jobCode']?.value,
      jobTitle: searchData['jobTitle']?.value,
      medium: searchData['medium']?.value,
      jobType: searchData['jobType']?.value,
      hrCharge: searchData['hrCharge']?.value,
      workAddress: searchData['workAddress']?.value,
      mediumURL: searchData['mediumURL']?.value,
      startDateFrom: dateStartFrom ? dateStartFrom.toISOString() : '',
      startDateTo: dateStartTo ? dateStartTo?.toISOString() : '',
      endDateFrom: dateEndFrom ? dateEndFrom?.toISOString() : '',
      endDateTo: dateEndTo ? dateEndTo?.toISOString() : '',
      sortField: 'recruitmentIndex',
      sortOrder: typeSort,
    };
    setQuerySearchValue(querySearch);
    if (searchData['onlyMe']?.checked) {
      querySearch.onlyMe = true;
    }
    await getListJobs(1, LIMIT_PAGE_DEFAULT, querySearch);
  };

  const handleClearSearch = async () => {
    document?.forms['formSearchJob'].reset();
  };
  const toggleSearchAdvanced = () => {
    const expandButton = document.getElementById('js-searchExpandButton');

    if (!expandButton) {
      return;
    }

    expandButton.addEventListener('click', (event) => {
      event.target.closest('.search').classList.toggle('is-expanded');
    });
  };

  const getListJobs = async (page = currentPage, limit = LIMIT_PAGE_DEFAULT, searchQuery = querySearchValue) => {
    setLoading(true);
    const data = await GetListRecruitment([page, limit, searchQuery]);
    const dataFinished = data?.recruitments?.map((item) => {
      item.info = {
        jobTitle: item?.jobTitle,
        startDate: item?.startDate,
        endDate: item?.endDate,
      };
      return item;
    });
    if (data) {
      setDataJobs(dataFinished);
      setPagination(data?.pageInfo);
    }
    setLoading(false);
  };

  const getTotalData = async () => {
    const data = await getTotalRecuiterForStatus();
    setDataTotal(data?.data);
  };

  const SortByIdList = () => {
    const typeSort = sortOrder === TYPE_DESC ? TYPE_ASC : TYPE_DESC;
    setSortOrder(typeSort);
    handleSearch(typeSort);
  };

  // use Effects

  useEffect(() => {
    getDataListHr();
    toggleSearchAdvanced();
  }, []);

  useEffect(() => {
    getListJobs();
  }, [currentPage]);

  useEffect(() => {
    getTotalData();
  }, []);

  return (
    <>
      <div className='pageHeader'>
        <h1 className='pageHeader__title'>求人管理</h1>
      </div>

      <div className='panel search'>
        <form className='form' name='formSearchJob' onSubmit={(e) => (e.preventDefault(), handleSearch())}>
          <div className='search__condition'>
            <div className='search__row -col2'>
              <fieldset className='search__group'>
                <legend className='form__label u-text-light'>ステータス</legend>
                <div className='form__field'>
                  <div className='checkbox-wrapper'>
                    <label className='checkbox'>
                      <input
                        type='checkbox'
                        defaultChecked
                        className='checkbox__input'
                        name='chatting'
                        value='chatting'
                      />
                      <span className='checkbox__label'>{`チャット受付中（${dataTotal?.chatting ?? 0}）`}</span>
                    </label>
                    <label className='checkbox'>
                      <input
                        type='checkbox'
                        defaultChecked
                        className='checkbox__input'
                        name='newChatPending'
                        value='new_chat_pending'
                      />
                      <span className='checkbox__label'>{`新規チャット停止（${
                        dataTotal?.new_chat_pending ?? 0
                      }）`}</span>
                    </label>
                    <label className='checkbox'>
                      <input type='checkbox' className='checkbox__input' name='chatPending' value='chat_pending' />
                      <span className='checkbox__label'>{`チャット停止（${dataTotal?.chat_pending ?? 0}）`}</span>
                    </label>
                  </div>
                </div>
              </fieldset>
              <fieldset className='search__group'>
                <legend className='form__label u-text-light'>表示設定</legend>
                <div className='form__field'>
                  <label className='checkbox'>
                    <input
                      defaultChecked
                      type='checkbox'
                      className='checkbox__input'
                      name='onlyMe'
                      value='settingStatus'
                    />
                    <span className='checkbox__label'>自分が担当の求人のみ表示</span>
                  </label>
                </div>
              </fieldset>
            </div>
            <div className='search__row -col3'>
              <fieldset className='search__group'>
                <legend className='form__label u-text-light'>求人ID</legend>
                <div className='form__field'>
                  <input type='text' className='textfield' name='jobCode' />
                </div>
              </fieldset>
              <fieldset className='search__group'>
                <legend className='form__label u-text-light'>求人タイトル</legend>
                <div className='form__field'>
                  <input type='text' className='textfield' name='jobTitle' />
                </div>
              </fieldset>
              <fieldset className='search__group'>
                <legend className='form__label u-text-light'>求人媒体</legend>
                <div className='form__field'>
                  <select className='select' name='medium'>
                    <option value=''>選択してください</option>
                    <option value='リクナビ'>リクナビ</option>
                    <option value='リクナビNEXT'>リクナビNEXT</option>
                    <option value='マイナビ'>マイナビ</option>
                    <option value='エン転職'>エン転職</option>
                  </select>
                </div>
              </fieldset>
            </div>
            <div className='search__row -center'>
              <button type='button' className='search__more' id='js-searchExpandButton'>
                詳細検索
              </button>
            </div>
            <div className='search__detail'>
              <div className='search__row -col3'>
                <fieldset className='search__group'>
                  <legend className='form__label u-text-light'>雇用形態</legend>
                  <div className='form__field'>
                    <select className='select' name='jobType'>
                      <option value=''>選択してください</option>
                      <option value='regular_employee_new_graduate'>正社員（新卒）</option>
                      <option value='regular_employee_midcareer'>正社員（中途）</option>
                      <option value='part_time'>アルバイト・パート</option>
                      <option value='contract_employee'>契約社員</option>
                      <option value='temporary_staffing'>派遣社員</option>
                      <option value='outsourcing'>業務委託</option>
                      <option value='others'>その他</option>
                    </select>
                  </div>
                </fieldset>
                <fieldset className='search__group'>
                  <legend className='form__label u-text-light'>担当者</legend>
                  <div className='form__field'>
                    <select className='select' name='hrCharge'>
                      <option value=''>選択してください</option>
                      {dataListHr?.map((item) => (
                        <>
                          {item?.status === 'enable' && (
                            <option key={item.id} value={item.id}>
                              {item?.fullName}
                            </option>
                          )}
                        </>
                      ))}
                    </select>
                  </div>
                </fieldset>
                <fieldset className='search__group'>
                  <legend className='form__label u-text-light'>勤務地の住所</legend>
                  <div className='form__field'>
                    <input name='workAddress' type='text' className='textfield' />
                  </div>
                </fieldset>
              </div>
              <div className='search__row -col3'>
                <fieldset className='search__group'>
                  <legend className='form__label u-text-light'>求人URL</legend>
                  <div className='form__field'>
                    <input name='mediumURL' type='text' className='textfield' />
                  </div>
                </fieldset>
                <fieldset className='search__group'>
                  <legend className='form__label u-text-light'>募集開始日</legend>
                  <div className='form__field'>
                    <div className='form__inline'>
                      <input type='date' name='startDateFrom' className='textfield' />
                      〜
                      <input type='date' name='startDateTo' className='textfield' />
                    </div>
                  </div>
                </fieldset>
                <fieldset className='search__group'>
                  <legend className='form__label u-text-light'>募集終了日</legend>
                  <div className='form__field'>
                    <div className='form__inline'>
                      <input type='date' name='endDateFrom' className='textfield' />
                      〜
                      <input type='date' name='endDateTo' className='textfield' />
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
          <div className='search__action'>
            <button
              type='button'
              onClick={() => handleClearSearch()}
              className='button -default -reset search__resetButton'
            >
              検索条件をリセット
            </button>
            <button type='submit' className='button -accent search__primaryButton'>
              検索
            </button>
          </div>
        </form>
      </div>
      <div className='panel relative'>
        {loading ? (
          <Loading />
        ) : (
          <div className='panel__body'>
            <div className='dataList'>
              <div className='dataList__header -center'>
                {dataJobs?.length > 0 && (
                  <PaginationCustom currentPage={currentPage} onChangePage={onChangePage} pagination={pagination} />
                )}
                <div className='dataList__headerAction'>
                  <Link to={URL_ADD_NEW_JOB} className='button -outline-primary -add'>
                    新規登録
                  </Link>
                </div>
              </div>
              <div className='dataList__body'>
                <table className='dataList__table -jobs'>
                  <thead>
                    <tr>
                      <th data-column='id' className='dataList__tableSort'>
                        <button
                          type='button'
                          className={`dataList__tableSortButton ${sortOrder === TYPE_ASC ? 'sortAsc' : ''}`}
                          onClick={SortByIdList}
                          data-sort='true'
                        >
                          求人ID
                        </button>
                      </th>
                      <th data-column='media'>求人媒体</th>
                      <th data-column='title'>求人情報</th>
                      <th data-column='chat'>チャット</th>
                      <th data-column='status'>ステータス</th>
                      <th data-column='workType'>雇用形態</th>
                      <th data-column='staff'>担当者</th>
                      <th data-column='numbers'>応募者 / 内定者</th>
                      <th data-column='detail'></th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataJobs?.map((item) => (
                      <tr key={item?._id}>
                        <td>{item.jobCode}</td>
                        <td>{item.medium}</td>
                        <td>
                          <p className='dataList__tableMainText'>{item?.jobTitle}</p>
                          <span className='dataList__tableSubText'>
                            {' '}
                            募集期間：{item?.startDate ? moment(item?.startDate).format('YYYY/MM/DD') : ''} -{' '}
                            {item?.endDate ? moment(item?.endDate).format('YYYY/MM/DD') : ''}
                          </span>
                        </td>
                        <td>
                          {item?.HRinCharge?.includes(JSON.parse(localStorage?.getItem('id'))) &&
                          item?.status !== CHAT_PENDING_STATUS ? (
                            <div className='dataList__tableChatButton'>
                              <button
                                target='_blank'
                                onClick={() => onHandleChatNewTab('', item?._id)}
                                className='button -outline-primary -small -chat'
                              >
                                チャット中
                              </button>
                            </div>
                          ) : (
                            <div className='text-center'>{statusChat(item?.status)}</div>
                          )}
                        </td>
                        <td>
                          <div
                            className={`statusLight ${
                              item.status === CHATTING_STATUS
                                ? '-success'
                                : item.status === NEW_CHAT_PENDING_STATUS
                                ? '-warning'
                                : '-neutral'
                            }`}
                          >
                            {statusChat(item.status)}
                          </div>
                        </td>
                        <td>{jobType(item?.jobType)}</td>
                        <td className=''>
                          <ul>
                            {item.HRinCharge?.map((value, index) => {
                              const data = dataListHr?.find((e) => {
                                if ([value].includes(e?.id)) return e;
                              });
                              return (
                                <>
                                  {index < 2 && (
                                    <li
                                      className='m-w-[150px] whitespace-nowrap overflow-hidden overflow-ellipsis'
                                      key={index}
                                    >
                                      {data?.fullName ?? ''}
                                    </li>
                                  )}
                                </>
                              );
                            })}
                            {item.HRinCharge.length > 2 && (
                              <li className='text-[#788087]'>他{item.HRinCharge.length - 2}名...</li>
                            )}
                          </ul>
                        </td>
                        <td>
                          {' '}
                          {item.replicaNum}/{item?.passedApplicantNum}
                        </td>
                        <td>
                          <Link to={`/jobs-detail-basic/${item?._id}`} className='button -primary -small'>
                            詳細
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className='dataList__footer'>
                {dataJobs?.length > 0 ? (
                  <PaginationCustom currentPage={currentPage} onChangePage={onChangePage} pagination={pagination} />
                ) : (
                  <div className='w-fit m-auto text-[#d3d4d4]'>{NO_DATA}</div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default JobsBody;
