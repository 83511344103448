import React from 'react';
import logoImage from '../../../designCaeru/public/images/logo.svg';
const ErrorPage = () => {
  return (
    <>
      <section className='pageHeader__title u-align-center u-spacer-top6'>
        <img src={logoImage} alt='かえる採用管理' />
      </section>
      <section className='u-align-center u-spacer-top3'>
        <h1 className='pageHeader__title'>ページが表示できません</h1>
        <p className=' u-spacer-top2'>エラーが発生しました。</p>
        <p className=' u-spacer-top1'>
          ご不便をおかけし申し訳ございません。しばらくたってからアクセスをお願いいたします。
        </p>
        <p className=' u-spacer-top1'>
          しばらくたって再度アクセスしても改善されない場合はお手数ですが、弊社サポート（css@caeru.asia）までご連絡をお願いいたします。
        </p>
      </section>
    </>
  );
};
export default ErrorPage;
