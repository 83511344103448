import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  RecruiterForgotPW,
  RecruiterResendOTP,
  RecruiterResetPassword,
  RecruiterVerifyOTP,
} from '../../../../services/RecruiterService';
import { toast } from '../../../../utils/notification';
import { URL_LOGIN } from '../../../../routes/constantRouter';
import { resetRequireValidate, handleRequireInvalid } from '../../../common/FuntionCommon';
import * as CONSTANT from '../../../common/constant';

const ForgotPassword = () => {
  const navigate = useNavigate();

  const [step, setStep] = useState('1');
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [disableBtn, setDisableBtn] = useState(false);


  const handleSendMailForgotPW = async (e) => {
    e.preventDefault();
    const data = await RecruiterForgotPW(document.forms['sendMail']['email'].value);
    setEmail(document.forms['sendMail']['email'].value);
    if (data) {
      toast('success', CONSTANT.SEND_MAIL_SUCCESSFULLY);
      setStep('2');
    }
  };
  const verifyOTP = async (e) => {
    e.preventDefault();
    const data = await RecruiterVerifyOTP(email, document.forms['verifyOTP']['otp'].value);
    if (data) {
      toast('success', CONSTANT.VERIFY_SUCCESSFULLY);

      setOtp(document.forms['verifyOTP']['otp'].value);

      // Set Step must be the final
      setStep('3');
    
    }
  };
  const resendOTP = async () => {
    const data = await RecruiterResendOTP(email);
    if (data) {
      toast('success', CONSTANT.RESEND_MAIL_SUCCESSFULLY);
    }
  };
  const resetPassword = async (e) => {
    e.preventDefault();
    const payload = {
      email: email,
      OTP: otp,
      new_password: document.forms['resetPw']['newPw'].value,
    };
    const data = await RecruiterResetPassword(payload);
    if (data) {
      toast('success', CONSTANT.CHANGE_PASSWORD_SUCCESSFULLY);
      navigate('/login');
    }
  };

  const validatePW = (event) => {
    event.target.setCustomValidity('');
    var pw = document.getElementById('password').value;
    var pwConfirm = document.getElementById('confirmPassword').value;
    var regularExpression = /^(?=.*\d)(?=.*[a-zA-Z])[a-zA-Z0-9]{8,}$/;
    if (regularExpression?.test(pw)) {
      setDisableBtn(false);
      document.getElementById('message').innerHTML = '';
    } else {
      setDisableBtn(true);
      document.getElementById('message').innerHTML = CONSTANT.PASSWORD_RULE;
    }
    if (pwConfirm && pw !== pwConfirm) {
      setDisableBtn(true);
      document.getElementById('messageConfirm').innerHTML = CONSTANT.PASSWORD_CONFIRM_ERROR;
    } else {
      document.getElementById('messageConfirm').innerHTML = '';
      setDisableBtn(false);
    }
  };

  const handleConfirmPW = (event) => {
    event.target.setCustomValidity('');
    var pw1 = document.getElementById('password').value;
    var pw2 = document.getElementById('confirmPassword').value;
    if (pw1 !== pw2) {
      setDisableBtn(true);
      document.getElementById('messageConfirm').innerHTML = CONSTANT.PASSWORD_CONFIRM_ERROR;
    } else {
      document.getElementById('messageConfirm').innerHTML = '';
      setDisableBtn(false);
    }
  };

  function showPw() {
    var x = document.getElementById('password');
    if (x.type === 'password') {
      x.type = 'text';
    } else {
      x.type = 'password';
    }
  }
  function showConfirmPw() {
    var x = document.getElementById('confirmPassword');
    if (x.type === 'password') {
      x.type = 'text';
    } else {
      x.type = 'password';
    }
  }
  return (
    <>
      <div className='layout__container -narrow'>
        <div className='u-spacer-top2 u-align-center'>
          <img src='logo.svg' alt='CAERU採用管理' width='300' height='54' />
        </div>
        {step === '1' && (
          <>
            <div className='panel u-spacer-top3'>
              <div className='panel__body -large'>
                <p className='u-spacer-bottom2'>アカウントに登録したメールアドレスを入力してください。</p>
                <form onSubmit={(e) => handleSendMailForgotPW(e)} name='sendMail' className='form'>
                  <fieldset className='form__group'>
                    <label htmlFor='email' className='form__label'>
                      メールアドレス
                    </label>
                    <div className='form__field'>
                      <input type='email' name='email' className='textfield' id='email' />
                    </div>
                  </fieldset>
                  <div className='form__group'>
                    <button type='submit' className='button -primary -full'>
                      メールを送信
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <p className='u-spacer-top3 u-align-center'>
              <Link to={URL_LOGIN}>ログイン画面に戻る</Link>
            </p>
          </>
        )}
        {step === '2' && (
          <div className='panel u-spacer-top3'>
            <div className='panel__body -large'>
              <p className='u-spacer-bottom2'>
                あなたのメールアドレスに宛てに認証メールをお送りしました。
                <br />
                メールに記載された認証コードを入力してください。
              </p>
              <form name='verifyOTP' onSubmit={(e) => verifyOTP(e)} className='form'>
                <fieldset className='form__group'>
                  <label htmlFor='authentication-code' className='form__label'>
                    認証コード
                  </label>
                  <div className='form__field'>
                    <input
                      name='otp'
                      required
                      onInvalid={(e) => handleRequireInvalid(e)}
                      onChange={(e) => resetRequireValidate(e)}
                      className='textfield'
                      id='authentication-code'
                    />
                  </div>
                </fieldset>
                <div className='form__group'>
                  <button type='submit' className='button -primary -full'>
                    次へ
                  </button>
                </div>
              </form>
              <div className='u-spacer-top6'>
                <p className='u-spacer-bottom2'>
                  認証メールが届かない場合は、以下より認証コードの再送信を行なってください。
                </p>
                <button onClick={() => resendOTP()} type='button' className='button -outline-primary -full'>
                  認証コードを再送信
                </button>
              </div>
            </div>
          </div>
        )}
        {step === '3' && (
          <div className='panel u-spacer-top3'>
            <div className='panel__body -large'>
              <h2 className='panel__title'>パスワードの再設定</h2>
              <p className='u-spacer-top1 u-spacer-bottom2'>パスワードは8文字以上でご登録ください。</p>
              <form name='resetPw' onSubmit={(e) => resetPassword(e)} className='form'>
                <fieldset className='form__group'>
                  <label htmlFor='password' className='form__label'>
                    パスワード
                  </label>
                  <div className='form__field items-center relative'>
                    <input
                      type='password'
                      name='newPw'
                      required
                      onInvalid={(e) => handleRequireInvalid(e)}
                      onChange={(e) => validatePW(e)}
                      className='textfield'
                      id='password'
                    />
                    <span
                      style={{ transform: 'translateY(-50%)' }}
                      role='button'
                      aria-hidden
                      onClick={showPw}
                      className='fa fa-fw fa-eye field-icon toggle-password absolute right-[20px] cursor-pointer top-[50%]'
                    ></span>
                  </div>
                  <p
                    id='message'
                    style={{
                      color: 'red',
                      whiteSpace: 'pre-line',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  ></p>
                </fieldset>
                <fieldset className='form__group'>
                  <label htmlFor='confirm-password' minLength={8} className='form__label'>
                    パスワード（確認用）
                  </label>
                  <div className='form__field items-center relative'>
                    <input
                      type='password'
                      name='oldPw'
                      required
                      onChange={(e) => handleConfirmPW(e)}
                      className='textfield'
                      id='confirmPassword'
                    />
                    <span
                      style={{ transform: 'translateY(-50%)' }}
                      role='button'
                      aria-hidden
                      onClick={showConfirmPw}
                      className='fa fa-fw fa-eye field-icon toggle-password absolute right-[20px] cursor-pointer top-[50%]'
                    ></span>
                  </div>
                  <p
                    id='messageConfirm'
                    style={{
                      color: 'red',
                      whiteSpace: 'pre-line',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  ></p>
                </fieldset>
                <div className='form__group'>
                  <button type='submit' disabled={disableBtn} className='button -primary -full'>
                    パスワードを再設定
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default ForgotPassword;
