/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useRef, useState } from 'react';
import { CreateNewRecruitment, GetListHr, UpdateRecruitment } from '../../../../../../services/RecruiterService';
import { get } from 'japan-postal-code';
import { toast } from '../../../../../../utils/notification';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import {
  getFullNameForListHR,
  resetRequireValidate,
  handleRequireInvalid,
  handleKeyPress,
  validateZipcode,
} from '../../../../../common/FuntionCommon';
import Choices from 'choices.js';
import * as CONSTANT from '../../../../../common/constant';
import { getListJobs } from '../../Applicants/common/SearchApplicants';
import { mutate } from 'swr';
import { URL_API_GET_LIST_RECRUITMENT } from '../../../../../../services';

const JobDataBody = ({ mode, dataDetail, setDataDetail }) => {
  const dataListJobs = getListJobs();
  const { id } = useParams();
  const [loadingAddUpdate, setLoadingAddUpdate] = useState(false);
  const [dataListHr, setDataListHr] = useState();
  const [defaultJobTanto, setDefaultJobTanto] = useState();
  const selectRef = useRef();
  const validatesRef = useRef({
    jobTitle: null,
    medium: null,
    zipCode: null,
    workAddress: null,
  });
  // Error message State
  const [showErrorFindAddress, setShowErrorFindAddress] = useState('');
  const [showErrorZipcode, setShowErrorZipcode] = useState('');
  const [errorValidateDate, setErrorValidateDate] = useState('');

  const clearErrorMessage = () => {
    setShowErrorFindAddress('');
    setShowErrorZipcode('');
    setErrorValidateDate('');
    // reset message some field require
    validatesRef.current.jobTitle.setCustomValidity('');
    validatesRef.current.medium.setCustomValidity('');
    validatesRef.current.zipCode.setCustomValidity('');
    validatesRef.current.workAddress.setCustomValidity('');
  };

  const [isCancel, setIsCancel] = useState(false);
  const onHandleCancel = () => {
    clearErrorMessage();
    fillDataDetail();
    setDataListHr(dataListHr);
    setIsCancel(!isCancel);
  };

  const fillDataDetail = async () => {
    document.forms['myForm']['status'].value = dataDetail?.status ?? 'chatting';
    document.forms['myForm']['jobType'].value = dataDetail?.jobType ?? 'regular_employee_new_graduate';
    document.forms['myForm']['jobTitle'].value = dataDetail?.jobTitle ?? '';
    document.forms['myForm']['medium'].value = dataDetail?.medium ?? '';
    document.forms['myForm']['mediumURL'].value = dataDetail?.mediumURL ?? '';
    document.forms['myForm']['zipCode'].value = dataDetail?.zipCode ?? '';
    document.forms['myForm']['workAddress'].value = dataDetail?.workAddress ?? '';
    document.forms['myForm']['startDate'].value = dataDetail?.startDate
      ? moment(dataDetail?.startDate)?.format('YYYY-MM-DD')
      : '';
    document.forms['myForm']['endDate'].value = dataDetail?.endDate
      ? moment(dataDetail?.endDate)?.format('YYYY-MM-DD')
      : '';
  };

  useEffect(() => {
    if (mode === 'detail' && Object.keys(dataDetail).length !== 0) {
      fillDataDetail();
    }
  }, [dataDetail, id]);

  const getDataAddress = async () => {
    let search = false;
    await new Promise((resolve, reject) => {
      get(document.forms['myForm']['zipCode'].value, function (address) {
        search = true;

        if (address) {
          document.forms['myForm']['workAddress'].value = `${address.prefecture ?? ''} ${address.city ?? ''} ${
            address.area ?? ''
          } ${address.street ?? ''}`;
          setShowErrorFindAddress('');
          validatesRef.current.workAddress.setCustomValidity('');
          resolve(address);
        }
      });

      // reject after 4 seconds
      setTimeout(() => {
        resolve('Operation timed out.');
      }, 4000);
    });

    if (!search) {
      setShowErrorFindAddress(CONSTANT.ADDRESS_NOT_FOUND);
    }
  };

  const navigate = useNavigate();

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    const selectedOptions = [...selectRef.current.options]
      .filter((option) => option.selected)
      .map((option) => option.value);

    const validated = validateInput();

    if (!validated) {
      // If there are any error in validate, then do nothing
      toast('error', CONSTANT.PLEASE_CHECK_INPUT);
      return;
    } else {
      setLoadingAddUpdate(true);
      let payload = {
        status: document.forms['myForm']['status'].value,
        jobTitle: document.forms['myForm']['jobTitle'].value,
        medium: document.forms['myForm']['medium'].value,
        mediumURL: document.forms['myForm']['mediumURL'].value,
        HRinCharge: selectedOptions,
        jobType: document.forms['myForm']['jobType'].value,
        zipCode: document.forms['myForm']['zipCode'].value,
        workAddress: document.forms['myForm']['workAddress'].value,
      };
      if (document.forms['myForm']['startDate'].value || document.forms['myForm']['startDate'].value === '') {
        payload.startDate = document.forms['myForm']['startDate'].value;
      }
      if (document.forms['myForm']['endDate'].value || document.forms['myForm']['endDate'].value === '') {
        payload.endDate = document.forms['myForm']['endDate'].value;
      }
      if (mode === 'detail') {
        const data = await UpdateRecruitment(id, payload);
        if (data) {
          toast('success', CONSTANT.UPDATE_SUCCESSFULLY);
          setDataDetail(data.recruitment);
        }
      } else {
        const data = await CreateNewRecruitment(payload);
        if (data) {
          toast('success', CONSTANT.CREATE_SUCCESSFULLY);
          navigate(`/jobs-detail-basic/${data?.recruitment?._id}`);
        }
      }
    }
    mutate([1, 1000, {}, URL_API_GET_LIST_RECRUITMENT], dataListJobs, true);
    setLoadingAddUpdate(false);
  };

  const choicesJsForFieldMedia = async () => {
    const jobMediaSingleSelect = document.querySelector('.js-choicesJobMedia');
    const defaultOptions = {
      allowHTML: false,
      noResultsText: '一致する選択肢がありません',
      itemSelectText: '',
    };
    if (jobMediaSingleSelect) {
      const choice = new Choices(jobMediaSingleSelect, {
        ...defaultOptions,
      });
    }
  };
  useEffect(() => {
    const getDataListHr = async () => {
      const data = await GetListHr([1, 100]);
      setDefaultJobTanto(data?.defaultJobTanto);
      const finishData = getFullNameForListHR(data);
      setDataListHr(finishData);
    };
    getDataListHr();
  }, []);

  useEffect(() => {
    choicesJsForFieldMedia();
    const userMultiSelect = document.querySelector('.js-choicesUser');
    const choices = new Choices(userMultiSelect, {
      allowHTML: true,
      removeItems: true,
      removeItemButton: true,
      searchEnabled: true,
      itemSelectText: '',
      noChoicesText: '選択肢がありません',
      noResultsText: '一致する選択肢がありません',
    });
    const dataOptionsItem = dataListHr?.map((item) => {
      let itemClone = {};
      itemClone.selected = dataDetail?.HRinCharge?.includes(item.id);
      itemClone.value = item.id;
      itemClone.label = item.fullName;
      return itemClone;
    });
    choices.setChoices(dataOptionsItem, 'value', 'label', true);

    // When create new job, by default add the login user as Tanto
    if (mode === 'create') {
      choices.setChoiceByValue(defaultJobTanto?._id);
    }

    // アイテムが1つ以上ある場合、プレースホルダーを非表示
    userMultiSelect.addEventListener('change', (event) => {
      const selectedItemsHr = choices.getValue(true);
      const placeholder = event.target.parentNode.querySelector('[name="search_terms"]');
      selectedItemsHr.length > 0
        ? (placeholder.placeholder = '')
        : (placeholder.placeholder = '求人媒体を選択してください');
    });
    return () => {
      choices.destroy();
    };
  }, [dataListHr, isCancel]);

  const validateInput = () => {
    let validatedItem = true;
    // Zip code
    const trueZipcode = validateZipcode(document.forms['myForm']['zipCode'].value);
    if (!trueZipcode) {
      setShowErrorZipcode(CONSTANT.ZIP_CODE_FORMAT);
      validatedItem = false;
    } else {
      setShowErrorZipcode('');
    }

    // StartDate and EndDate
    let startDate = document.getElementById('startDate').value;
    let endDate = document.getElementById('endDate').value;

    if (startDate && endDate && new Date(startDate) > new Date(endDate)) {
      setErrorValidateDate(CONSTANT.VALIDATE_DATE);
      validatedItem = false;
    }

    return validatedItem; // No error
  };

  return (
    <div className='panel'>
      <div className='panel__body -large'>
        <form name='myForm' onSubmit={(e) => handleOnSubmit(e)} onKeyDown={handleKeyPress} className='form'>
          <table className='detailTable'>
            <tr>
              <th>求人ID</th>
              <td>{mode === 'detail' ? dataDetail?.jobCode : ''}</td>
            </tr>
            <tr>
              <th>
                ステータス<span className='badge -danger u-spacer-left1'>必須</span>
              </th>
              <td>
                <div className='radio-group'>
                  <label className='radio'>
                    <input type='radio' name='status' className='radio__input' value={'chatting'} defaultChecked />
                    <span className='radio__label'>チャット受付中</span>
                  </label>
                  <label className='radio'>
                    <input type='radio' name='status' className='radio__input' value={'new_chat_pending'} />
                    <span className='radio__label'>新規チャット停止</span>
                  </label>
                  <label className='radio'>
                    <input type='radio' name='status' className='radio__input' value={'chat_pending'} />
                    <span className='radio__label'>チャット停止</span>
                  </label>
                </div>
                <p className='detailTable__note'>
                  ※［新規チャット停止］は、新規の応募チャットやコンタクトチャットを受け付けない状態になります。
                  <br />
                  なお、現在すでに繋がっている求職者とは引き続きメッセージのやり取りを行うことができます。
                </p>
              </td>
            </tr>
            <tr>
              <th>
                求人タイトル<span className='badge -danger u-spacer-left1'>必須</span>
              </th>
              <td>
                <input
                  required
                  onInvalid={(e) => handleRequireInvalid(e)}
                  onChange={(e) => resetRequireValidate(e)}
                  type='text'
                  name='jobTitle'
                  className='textfield'
                  placeholder='求人タイトルを入力してください'
                  ref={(e) => (validatesRef.current.jobTitle = e)}
                />
              </td>
            </tr>
            <tr>
              <th>
                求人媒体<span className='badge -danger u-spacer-left1'>必須</span>
              </th>
              <td>
                <select
                  required
                  onInvalid={(e) => handleRequireInvalid(e)}
                  onChange={(e) => resetRequireValidate(e)}
                  className='select js-choicesJobMedia'
                  name='medium'
                  ref={(e) => (validatesRef.current.medium = e)}
                >
                  <option value='' style={{ color: 'rgba(0, 0, 0, 0.4)' }}>
                    求人媒体を選択してください
                  </option>
                  <option value='リクナビ'>リクナビ</option>
                  <option value='リクナビNEXT'>リクナビNEXT</option>
                  <option value='マイナビ'>マイナビ</option>
                  <option value='エン転職'>エン転職</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>求人URL</th>
              <td>
                <input
                  name='mediumURL'
                  type='text'
                  className='textfield'
                  placeholder='掲載した求人のURLを入力してください'
                />
              </td>
            </tr>
            <tr>
              <th>
                担当者<span className='badge -danger u-spacer-left1'>必須</span>
              </th>
              <td>
                <select
                  required
                  onInvalid={(e) => handleRequireInvalid(e)}
                  onChange={(e) => resetRequireValidate(e)}
                  className={'select js-choicesUser'}
                  multiple
                  ref={selectRef}
                >
                  <option value=''>担当者を選択してください</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>
                雇用形態<span className='badge -danger u-spacer-left1'>必須</span>
              </th>
              <td>
                <div className='radio-group'>
                  <label className='radio'>
                    <input
                      defaultChecked
                      value={'regular_employee_new_graduate'}
                      type='radio'
                      name='jobType'
                      className='radio__input'
                    />
                    <span className='radio__label'>正社員（新卒）</span>
                  </label>
                  <label className='radio'>
                    <input value={'regular_employee_midcareer'} type='radio' name='jobType' className='radio__input' />
                    <span className='radio__label'>正社員（中途）</span>
                  </label>
                  <label className='radio'>
                    <input value={'part_time'} type='radio' name='jobType' className='radio__input' />
                    <span className='radio__label'>アルバイト・パート</span>
                  </label>
                  <label className='radio'>
                    <input value={'contract_employee'} type='radio' name='jobType' className='radio__input' />
                    <span className='radio__label'>契約社員</span>
                  </label>
                  <label className='radio'>
                    <input value={'temporary_staffing'} type='radio' name='jobType' className='radio__input' />
                    <span className='radio__label'>派遣社員</span>
                  </label>
                  <label className='radio'>
                    <input value={'outsourcing'} type='radio' name='jobType' className='radio__input' />
                    <span className='radio__label'>業務委託</span>
                  </label>
                  <label className='radio'>
                    <input value={'others'} type='radio' name='jobType' className='radio__input' />
                    <span className='radio__label'>その他</span>
                  </label>
                </div>
              </td>
            </tr>
            <tr>
              <th>
                勤務地の郵便番号<span className='badge -danger u-spacer-left1'>必須</span>
              </th>
              <td>
                <div className='form__inline'>
                  <input
                    name='zipCode'
                    id='zipCode'
                    type='text'
                    maxLength={8}
                    placeholder='郵便番号を入力してください'
                    className='textfield -short'
                    required
                    onInvalid={(e) => handleRequireInvalid(e)}
                    onChange={(e) => (resetRequireValidate(e), setShowErrorFindAddress(''))}
                    ref={(e) => (validatesRef.current.zipCode = e)}
                  />
                  <button type='button' onClick={() => getDataAddress()} className='button -secondary'>
                    住所自動入力
                  </button>
                  {showErrorFindAddress && <span className='text-[red]'>{showErrorFindAddress}</span>}
                  {showErrorZipcode && <span className='text-[red] customError'>{showErrorZipcode}</span>}
                </div>
              </td>
            </tr>
            <tr>
              <th>
                勤務地の住所<span className='badge -danger u-spacer-left1'>必須</span>
              </th>
              <td>
                <input
                  type='text'
                  name='workAddress'
                  className='textfield'
                  required
                  onInvalid={(e) => handleRequireInvalid(e)}
                  onChange={(e) => resetRequireValidate(e)}
                  ref={(e) => (validatesRef.current.workAddress = e)}
                />
              </td>
            </tr>
            <tr>
              <th>募集開始日</th>
              <td>
                <div className='flex gap-[20px] items-center'>
                  <input type='date' name='startDate' id='startDate' className='textfield -short' />
                  {errorValidateDate && <span className='text-[red] customError'>{errorValidateDate}</span>}
                </div>
                <p className='detailTable__note'>※求人媒体で設定した日付を入力します。必要があれば設定してください。</p>
              </td>
            </tr>
            <tr>
              <th>募集終了日</th>
              <td>
                <div className='flex gap-[20px] items-center'>
                  <input type='date' name='endDate' id='endDate' className='textfield -short' />
                  {errorValidateDate && <span className='text-[red] customError'>{errorValidateDate}</span>}
                </div>
                <p className='detailTable__note'>※求人媒体で設定した日付を入力します。必要があれば設定してください。</p>
              </td>
            </tr>
            {mode === 'detail' && (
              <>
                <tr>
                  <th>応募者</th>
                  <td>{dataDetail?.replicaNum}名</td>
                </tr>
                <tr>
                  <th>内定者</th>
                  <td>{dataDetail?.passedApplicantNum}名</td>
                </tr>
              </>
            )}
          </table>
          <div className='u-spacer-top4'>
            <div className='button-wrapper -center'>
              <button type='button' onClick={onHandleCancel} className='button -default'>
                キャンセル
              </button>
              <button type='submit' disabled={loadingAddUpdate} className='button -primary'>
                {loadingAddUpdate && <i className='fa fa-spinner fa-spin mr-[10px]'></i>}保存
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default JobDataBody;
