import React, { useEffect, useState } from 'react';
import DetailApplication from './common/DetailApplication';
import ModalSubmitSendMail from './common/ModalSubmitSendMail';
import ModalRegisterNewEmployee from './common/ModalRegisterNewEmployee';
import { GetDetailApplicants } from '../../../../../services/RecruiterService';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { displayModal, getAgeUser, onHandleChatNewTab, setSex } from '../../../../common/FuntionCommon';
import { NOT_YET_INVITE } from '../../../../common/constant';
import Loading from '../../../../common/SpinLoading';
import ApplicantBasicInfo from './common/ApplicantBasicInfo';
import { useSelector } from 'react-redux';
import { dataSlice } from '../../../../redux/reducer';

const ApplicantDetail = () => {
  const [activeKeyValue, setActiveKeyValue] = useState('basicDetail');
  const { dataRouterBackButton } = useSelector(dataSlice);
  const navigate = useNavigate();
  const { id } = useParams();
  const [dataDetail, setDataDetail] = useState({});
  const [loading, setLoading] = useState(true);

  const getDataDetail = async () => {
    const data = await GetDetailApplicants(id);
    if (data) {
      setDataDetail(data?.detailReplica);
    }
    setLoading(false);
  };

  useEffect(() => {
    getDataDetail();
  }, [id]);

  const [dataApplications, setDataApplications] = useState([]);

  useEffect(() => {
    setDataApplications(dataDetail?.applications);
  }, [dataDetail]);

  const tabActivity = {
    basicDetail: <ApplicantBasicInfo mode='detail' dataDetail={dataDetail} setDataDetail={setDataDetail} />,
    detailApplication: <DetailApplication data={dataDetail} getDataDetail={getDataDetail} />,
  };

  const onHandleChat = (e) => {
    e.preventDefault();
    onHandleChatNewTab(dataApplications?.[0].applyGroupId);
  };
  useEffect(() => {
    displayModal();
  });

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className='pageHeader'>
            <div className='pageHeader__start'>
              <h1 className='pageHeader__title'>
                {dataDetail?.lastName + ' ' + dataDetail?.firstName + ' '}
                <span className='pageHeader__titleMeta'>
                  {getAgeUser({
                    fullYear: +dataDetail?.dateOfBirth_YYYY,
                    month: +dataDetail?.dateOfBirth_MM,
                    day: +dataDetail?.dateOfBirth_DD,
                  })}
                  歳 /{setSex(dataDetail?.sex)}
                </span>
              </h1>
              {dataApplications?.map((item) => (
                <div className='pageHeader__detail' key={item?._id}>
                  <div
                    className={`statusLight ${
                      item?.evaluationStatus === '選考中'
                        ? '-success'
                        : item?.evaluationStatus === '内定'
                        ? '-info'
                        : '-neutral'
                    }`}
                  >
                    {' '}
                    {item?.evaluationStatus}
                  </div>
                  <dl className='pageHeader__definitionList'>
                    <dt>評価：</dt>
                    <dd>
                      <span className='star'>{item?.rating}</span>
                    </dd>
                    <dt>応募した求人：</dt>
                    <dd>
                      <Link to={`/jobs-detail-basic/${item?.jobId}`} target='_blank'>
                        {item?.jobTitle}
                      </Link>
                    </dd>
                  </dl>
                </div>
              ))}

              {activeKeyValue === 'basicDetail' && dataDetail?.hiddenInList && (
                <div className='badge -default'>一覧から非表示</div>
              )}
            </div>
            <div className='pageHeader__end'>
              <div className='button-wrapper'>
                <button type='button' className='button -default' data-modal-target='employee-registration'>
                  従業員として登録
                </button>
                {dataApplications?.[0].applyGroupId && dataApplications?.[0].chatStatus !== NOT_YET_INVITE ? (
                  <Link
                    target='_blank'
                    onClick={(e) => onHandleChat(e)}
                    type='button'
                    className='button -outline-primary -chat'
                  >
                    {dataApplications?.[0]?.chatStatus}
                  </Link>
                ) : (
                  <button type='button' className='button -outline-primary -mail' data-modal-target='chat-invitation'>
                    チャットに招待
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className='u-spacer-block2'>
            <ul className='tab'>
              <li className='tab__item'>
                <Link
                  onClick={() => setActiveKeyValue('basicDetail')}
                  className={`tab__button ${activeKeyValue === 'basicDetail' ? 'is-active' : ''}`}
                >
                  基本情報
                </Link>
              </li>
              <li className='tab__item'>
                <Link
                  onClick={() => setActiveKeyValue('detailApplication')}
                  className={`tab__button ${activeKeyValue === 'detailApplication' ? 'is-active' : ''}`}
                >
                  応募情報
                </Link>
              </li>
            </ul>
          </div>
          {tabActivity?.[activeKeyValue]}
          <div className='u-spacer-top4'>
            <button
              onClick={() =>
                dataRouterBackButton?.from_url === 'job'
                  ? navigate(`/jobs-detail-basic/${dataRouterBackButton?.jobId}?tab=applicants`)
                  : navigate(dataRouterBackButton?.from_url)
              }
              className='button -default -back'
            >
              一覧に戻る
            </button>
          </div>

          {dataDetail?.applications && (
            <ModalSubmitSendMail
              mode={'sendMailFirstTime'}
              refetchContext={getDataDetail}
              jobTitle={dataDetail?.applications?.[0].jobTitle}
              jobId={dataDetail?.applications?.[0].jobId}
              replicaId={dataDetail?._id}
              applicantEmail={dataDetail?.email}
              firstName={dataDetail?.firstName}
              lastName={dataDetail?.lastName}
            />
          )}

          <ModalRegisterNewEmployee dataApplications={dataApplications} />
        </>
      )}
    </>
  );
};
export default ApplicantDetail;
