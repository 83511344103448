import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { URL_VERIFY_EMAIL } from '../../../../routes/constantRouter';

const FinishedSignUp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const email = location?.state?.type;
  useEffect(() => {
    if (!email) {
      navigate(URL_VERIFY_EMAIL);
    }
  }, []);

  const signupContentStep3 = (
    <>
      <h2 className='panel__title'>アカウント登録が完了しました</h2>
      <p className='u-spacer-top1'>ログイン後、CAERU採用管理をご利用いただけます。</p>
      <p className='u-spacer-top3'>
        <Link to='/login' className='button -default -full'>
          ログイン画面へ
        </Link>
      </p>
    </>
  );

  return (
    <>
      <div className='layout__container'>
        <div className='u-spacer-top2 u-spacer-bottom3 u-align-center'>
          <img src='logo.svg' alt='CAERU採用管理' width='300' height='54' />
        </div>

        <div className='stepper'>
          <ol className='stepper__list'>
            <li className={'stepper__item'}>
              <span className='stepper__number'>1</span>
              <p className='stepper__text'>アカウント情報入力</p>
            </li>
            <li className={'stepper__item '}>
              <span className='stepper__number'>2</span>
              <p className='stepper__text'>認証コード入力</p>
            </li>
            <li className={'stepper__item -active'}>
              <span className='stepper__number'>3</span>
              <p className='stepper__text'>アカウント登録完了</p>
            </li>
          </ol>
        </div>
      </div>
      <div className='layout__container -narrow'>
        <div className='panel u-spacer-top3'>
          <div className='panel__body -large'>{signupContentStep3}</div>
        </div>
      </div>
    </>
  );
};
export default FinishedSignUp;
