import React, { useEffect, useState } from 'react';
import { roleHrUser } from '../../../../../common/FuntionCommon';
import { Link } from 'react-router-dom';

const ModalSubmitAddNewSetting = ({ dataUser, openModalSubmit, setOpenModalSubmit }) => {
  return (
    <div className='modal !opacity-100 !visible' id='user-add-completed'>
      <div className='modal__inner'>
        <div className='modal__content'>
          <div className='modal__header'>
            <p className='modal__title'>ユーザー登録完了</p>
          </div>
          <div className='modal__body'>
            <p>
              ユーザーを登録しました。また登録したユーザーに招待メールを送信しました。
              <br />
              招待メールを受け取ったユーザーは、メールに記載されたURLからログインしてください。
            </p>
            <table className='detailTable u-spacer-top2'>
              <tbody>
                <tr>
                  <th>氏名</th>
                  <td>{dataUser?.lastName + ' ' + dataUser?.firstName}</td>
                </tr>
                <tr>
                  <th>メールアドレス</th>
                  <td>{dataUser?.email}</td>
                </tr>
                <tr>
                  <th>権限</th>
                  <td>{roleHrUser(dataUser?.role)}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className='modal__footer'>
            <div className='button-wrapper -end'>
              <Link
                to={`/settings-users-detail/${dataUser?._id}`}
                onClick={() => setOpenModalSubmit(false)}
                className='button -default'
              >
                閉じる
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className=' modal__overlay'></div>
    </div>
  );
};
export default ModalSubmitAddNewSetting;
