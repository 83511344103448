/* eslint-disable no-unused-vars */
import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import {
  addProgressForApplicant,
  getDataProgress,
  updateProgressForApplicant,
} from '../../../services/RecruiterService';
import { useParams } from 'react-router-dom';
import { toast } from '../../../utils/notification';
import moment from 'moment';
import { resetRequireValidate, handleRequireInvalid } from '../../common/FuntionCommon';
import { useDispatch } from 'react-redux';
import { chatSlice, setInfoEvaluateInterviewApplicant } from '../../redux/reducerChat';
import * as CONSTANT from '../../common/constant';
import { URL_API_COMPANY_PROGRESS } from '../../../services';

const ModalEditProgressInfo = ({
  isModalOpen,
  handleCancel,
  jobId,
  currentProgress, // progress 1 element
  typeAction,
  getDataDetail, // refetching the list of progress in parent component
  idUser, // applicantUser
  listSettingCompanyProgress,
}) => {
  const [optionsResult, setOptionsResult] = useState([{ label: '選択してください', value: '' }]);

  const [optionsProgress, setOptionProgress] = useState([{ label: '選択してください', value: '', enable: true }]);
  const [progressId, setProgressId] = useState('');
  const [selectedStep, setSelectedStep] = useState();

  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const valueForm = document.forms['formProgress'];
    setSelectedStep(currentProgress?.step);
    setProgressId(currentProgress?._id);

    if (currentProgress && typeAction !== 'create') {
      valueForm['step'].value = currentProgress?.step;
      valueForm['dateInterview'].value = currentProgress?.interviewStart
        ? moment(currentProgress?.interviewStart)?.format('YYYY-MM-DD')
        : null;
      valueForm['timeHourInterviewFrom'].value = currentProgress?.interviewStart
        ? moment(currentProgress?.interviewStart)?.format('HH')
        : null;
      valueForm['timeMinuteInterviewFrom'].value = currentProgress?.interviewStart
        ? moment(currentProgress?.interviewStart)?.format('mm')
        : null;
      valueForm['timeHourInterviewTo'].value = currentProgress?.interviewEnd
        ? moment(currentProgress?.interviewEnd)?.format('HH')
        : null;
      valueForm['timeMinuteInterviewTo'].value = currentProgress?.interviewEnd
        ? moment(currentProgress?.interviewEnd)?.format('mm')
        : null;
      valueForm['result'].value = currentProgress?.result ?? '';
    }
  }, [currentProgress, isModalOpen, optionsProgress]);

  const resetFieldsDate = () => {
    const valueForm = document.forms['formProgress'];
    valueForm['dateInterview'].value = '';
    valueForm['timeHourInterviewFrom'].value = '';
    valueForm['timeMinuteInterviewFrom'].value = '';
    valueForm['timeHourInterviewTo'].value = '';
    valueForm['timeMinuteInterviewTo'].value = '';
  };

  useEffect(() => {
    let data = [];
    data = listSettingCompanyProgress?.map((item) => {
      let progress = {};
      progress.value = item?.step;
      progress.label = item?.step;
      progress.id = item?._id;
      progress.enable = item.enable;
      return progress;
    });
    if (data?.length > 0) {
      data?.unshift({ label: '選択してください', value: '', enable: true });
      setOptionProgress(data);
    }
  }, [listSettingCompanyProgress]);

  useMemo(
    () =>
      listSettingCompanyProgress?.map((item) => {
        if (selectedStep === '') {
          setOptionsResult([{ label: '選択してください', value: '' }]);
        }
        if (item?.step === selectedStep) {
          const resultData = item?.result?.map((temp) => {
            let data = {};
            data.value = temp;
            data.label = temp;
            return data;
          });
          return setOptionsResult([{ label: '選択してください', value: '' }, ...resultData]);
        }
      }),
    [selectedStep, listSettingCompanyProgress]
  );

  useEffect(() => {
    if (isModalOpen && typeAction === 'create') {
      document?.forms['formProgress'].reset();
    }
  }, [isModalOpen]);

  const handleFinish = async (e) => {
    e.preventDefault();
    const valueForm = document.forms['formProgress'];
    if (+valueForm['timeHourInterviewFrom'].value > 24 || +valueForm['timeHourInterviewTo'].value > 24) {
      return toast('error', CONSTANT.HOUR_LESS_THAN_24);
    } else if (
      valueForm['timeHourInterviewTo'].value &&
      (+valueForm['timeHourInterviewFrom'].value > +valueForm['timeHourInterviewTo'].value ||
        (+valueForm['timeHourInterviewFrom'].value === +valueForm['timeHourInterviewTo'].value &&
          +valueForm['timeMinuteInterviewFrom'].value > +valueForm['timeMinuteInterviewTo'].value))
    ) {
      return toast('error', CONSTANT.START_END_TIME);
    } else if (+valueForm['timeMinuteInterviewFrom'].value > 59 || +valueForm['timeMinuteInterviewTo'].value > 59) {
      return toast('error', CONSTANT.MINUTE_LESS_THAN_60);
    } else if (
      (valueForm['timeMinuteInterviewFrom'].value && !valueForm['timeHourInterviewFrom'].value) ||
      (valueForm['timeMinuteInterviewTo'].value && !valueForm['timeHourInterviewTo'].value)
    ) {
      return toast('error', CONSTANT.MINUTE_SELECT_AFTER_HOUR);
    } else {
      setLoading(true);
      const interviewDateStart = valueForm['dateInterview'].value
        ? new Date([
            valueForm['dateInterview'].value,
            valueForm['timeHourInterviewFrom'].value
              ? `${valueForm['timeHourInterviewFrom'].value}:${valueForm['timeMinuteInterviewFrom'].value}`
              : '',
          ])
        : null;

      const interviewDateEnd =
        valueForm['dateInterview'].value && valueForm['timeHourInterviewTo'].value
          ? new Date([
              valueForm['dateInterview'].value,
              valueForm['timeHourInterviewTo'].value
                ? `${valueForm['timeHourInterviewTo'].value}:${valueForm['timeMinuteInterviewTo'].value}`
                : '',
            ])
          : null;
      let payload = {
        step: selectedStep ?? valueForm['step'].value ?? currentProgress?.step,
        result: valueForm['result'].value ?? currentProgress?.result,
        jobId: jobId,
        interviewStart: interviewDateStart ? interviewDateStart.toISOString() : '',
        interviewEnd: interviewDateEnd ? interviewDateEnd.toISOString() : '',
      };
      if (progressId) {
        payload = { ...payload, progressId: progressId };
      }
      let dataFinished = null;

      if (
        typeAction === CONSTANT.ACTION_ADD_NEW ||
        (typeAction === CONSTANT.ACTION_PROGRESS_FLEXIBLE && selectedStep != currentProgress?.step)
      ) {
        dataFinished = await addProgressForApplicant(id || idUser, payload);
      }
      if (
        typeAction === CONSTANT.ACTION_EDIT ||
        (typeAction === CONSTANT.ACTION_PROGRESS_FLEXIBLE && selectedStep === currentProgress?.step)
      ) {
        dataFinished = await updateProgressForApplicant(id || idUser, payload);
      }

      dispatch(
        setInfoEvaluateInterviewApplicant(
          dataFinished?.data?.updatedApplicantReplica?.applications?.find((item) => item?.jobId === jobId)
        )
      );

      if (dataFinished) {
        toast('success', CONSTANT.SETTING_SUCCESSFULLY);
        handleCancel();
      }
      setLoading(false);
    }
    if (getDataDetail) {
      getDataDetail();
    }
  };

  const handleChangeSelectStep = (e) => {
    resetRequireValidate(e);
    setSelectedStep(e.target.value);

    // reset date and result
    resetFieldsDate();
    document.forms['formProgress']['result'].value = '';
  };

  return (
    <>
      <div className='modal !opacity-100 !visible'>
        <div className='modal__inner'>
          <div className='modal__content'>
            <form onSubmit={(e) => handleFinish(e)} name='formProgress' className='form'>
              <div className='modal__header'>
                <p className='modal__title'>選考状況</p>
              </div>
              <div className='modal__body'>
                <table className='detailTable'>
                  <tr>
                    <th>
                      進捗<span className='badge -danger u-spacer-left1'>必須</span>
                    </th>
                    <td>
                      <select
                        className='select -short'
                        name='step'
                        required
                        onInvalid={(e) => handleRequireInvalid(e)}
                        onChange={(e) => handleChangeSelectStep(e)}
                      >
                        {optionsProgress?.map((item) => (
                          <>
                            {item?.enable && (
                              <option id={item?.id} key={item?.id} value={item?.value}>
                                {item?.label}
                              </option>
                            )}
                          </>
                        ))}
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th>面接日時</th>
                    <td>
                      <input type='date' className='textfield -short' name='dateInterview' />
                      <div className='form__inline u-spacer-top2'>
                        <input type='text' className='textfield -x_short' name='timeHourInterviewFrom' />時
                        <input type='text' className='textfield -x_short' name='timeMinuteInterviewFrom' />分 〜
                        <input type='text' className='textfield -x_short' name='timeHourInterviewTo' />時
                        <input type='text' className='textfield -x_short' name='timeMinuteInterviewTo' />分
                      </div>
                      <div className='u-spacer-top2'>
                        <button onClick={() => resetFieldsDate()} type='button' className='button -secondary'>
                          クリア
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>結果</th>
                    <td>
                      <select className='select -short' name='result'>
                        {optionsResult?.map((item) => (
                          <option key={item?.value} data={item?.value} value={item?.value}>
                            {item?.label}
                          </option>
                        ))}
                      </select>
                    </td>
                  </tr>
                </table>
              </div>
              <div className='modal__footer'>
                <div className='button-wrapper -end'>
                  <button onClick={() => handleCancel()} type='button' className='button -default js-modal-close'>
                    キャンセル
                  </button>
                  <button type='submit' disabled={loading} className='button -primary js-modal-close'>
                    {loading && <i className='fa fa-spinner fa-spin mr-[10px]'></i>}保存
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className='modal__overlay'></div>
      </div>
    </>
  );
};
export default ModalEditProgressInfo;
