/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { RecruiterLogin, RecruiterResendOTP } from '../../../../services/RecruiterService';
import { toast } from '../../../../utils/notification';
import {
  URL_CHAT_APPLICANTS,
  URL_FORGOT_PW,
  URL_JOBS,
  URL_SETTING_INTERNAL,
  URL_SIGNUP1,
  URL_VERIFY_EMAIL,
} from '../../../../routes/constantRouter';
import { useDispatch } from 'react-redux';
import { setDataInitial } from '../../../redux/reducer';
import { useLocation } from 'react-router-dom';
import { resetRequireValidate, handleRequireInvalid } from '../../../common/FuntionCommon';
import * as CONSTANT from '../../../common/constant';
import { socketIO } from '../../../../services/socket';

const Login = () => {
  const location = useLocation();
  const myData = location?.state?.myData;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogin = async (e) => {
    e.preventDefault();
    const email = document.forms['loginForm']['email'].value;
    const password = document.forms['loginForm']['password'].value;
    const data = await RecruiterLogin(email, password);
    if (data === 'MAIL_NOT_VERIFIED') {
      const handleSignupResendSenOTP = async () => {
        const response = await RecruiterResendOTP(email);
      };
      handleSignupResendSenOTP();
      navigate(URL_VERIFY_EMAIL, {
        state: {
          email: email,
        },
      });
    }
    if (data?.data?.token) {
      const payload = {
        companyCode: data?.data?.companyId?.companyCode,
        companyName: data?.data?.companyId?.companyName,
        email: data?.data?.email,
        role: data?.data?.role,
        userName: data?.data?.lastName + ' ' + data?.data?.firstName,
        lastName: data?.data?.lastName,
        firstName: data?.data?.firstName,
      };
      localStorage.setItem('token', JSON.stringify(data?.data?.token));
      localStorage.setItem('dataSetting', JSON.stringify(payload));
      localStorage.setItem('role', JSON.stringify(data?.data?.role));
      localStorage.setItem('id', JSON.stringify(data?.data?._id));
      dispatch(setDataInitial(payload));
      if (data?.data?.companyId?.companyCode) {
        if (myData) {
          window.location.href = myData;
        } else {
          if (data?.data?.role === 'chat_only') {
            navigate(URL_CHAT_APPLICANTS);
          } else navigate(URL_JOBS);
        }
      } else {
        navigate(URL_SETTING_INTERNAL);
      }
      toast('success', CONSTANT.LOGIN_SUCCESSFULLY);
      socketIO.connect();
    }
  };

  useEffect(() => {
    socketIO.disconnect();
  }, []);

  return (
    <div className='layout__container -narrow'>
      <div className='u-spacer-top2 u-align-center'>
        <img src='logo.svg' alt='CAERU採用管理' width='300' height='54' />
      </div>
      <div className='panel u-spacer-top3'>
        <div className='panel__body -large'>
          <form name='loginForm' onSubmit={(e) => handleLogin(e)} className='form'>
            <fieldset className='form__group'>
              <label htmlFor='email' className='form__label'>
                メールアドレス
              </label>
              <div className='form__field'>
                <input
                  type='email'
                  required
                  onInvalid={(e) => handleRequireInvalid(e)}
                  onChange={(e) => resetRequireValidate(e)}
                  className='textfield'
                  name='email'
                />
              </div>
            </fieldset>
            <fieldset className='form__group'>
              <label htmlFor='password' className='form__label'>
                パスワード
              </label>
              <div className='form__field'>
                <input
                  type='password'
                  required
                  onInvalid={(e) => handleRequireInvalid(e)}
                  onChange={(e) => resetRequireValidate(e)}
                  className='textfield'
                  name='password'
                />
              </div>
            </fieldset>
            <div className='form__group'>
              <button type='submit' className='button -primary -full'>
                ログイン
              </button>
            </div>
          </form>
          <p className='u-spacer-top3 u-align-right'>
            <Link to={URL_FORGOT_PW}>パスワードを忘れた方</Link>
          </p>
        </div>
      </div>
      <p className='u-spacer-top3 u-align-center'>
        <Link to={URL_SIGNUP1}>アカウントの新規作成はこちら</Link>
      </p>
    </div>
  );
};
export default Login;
