import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PaginationCustom from '../../../../../template/Pagination';
import { URL_APPLICANT_NEW } from '../../../../../../routes/constantRouter';
import moment from 'moment';
import { GetListApplicants } from '../../../../../../services/RecruiterService';
import { displayModal, getAgeUser, onHandleChatNewTab, setSex } from '../../../../../common/FuntionCommon';
import ModalSubmitSendMail from './ModalSubmitSendMail';
import {
  APPLICANT_DELETED_ACCOUNT,
  APPLICANT_EXIT,
  LIMIT_PAGE_DEFAULT,
  NOT_YET_INVITE,
  NO_DATA,
  TYPE_ASC,
  TYPE_DESC,
} from '../../../../../common/constant';
import SearchApplicants from './SearchApplicants';
import Loading from '../../../../../common/SpinLoading';
import { setDataRouterBackButton } from '../../../../../redux/reducer';
import { useDispatch } from 'react-redux';

const BodyTableListResult = ({ type }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [sortApplyDateType, setSortApplyDateType] = useState(TYPE_DESC);
  const [sortInterviewDateType, setSortInterviewDateType] = useState(TYPE_DESC);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState();
  const [listApplicants, setListApplicants] = useState();
  const [loading, setLoading] = useState(false);
  const [querySearch, setQuerySearch] = useState({
    evaluationStatus: !type
      ? '選考中'
      : type === 'applicantsPass'
      ? '内定'
      : type === 'applicantsDecline'
      ? '辞退'
      : '不合格',
    chatStatus: '招待する,参加待ち,チャット中,退出済み',
    sortField: 'appliedDate',
    sortOrder: TYPE_DESC,
  });
  const [dataDetailApplicant, setDataDetailApplicant] = useState();

  // FUNCTION
  const getListApplicants = async (valueSearch = querySearch, page = currentPage, limit = LIMIT_PAGE_DEFAULT) => {
    setLoading(true);
    const data = await GetListApplicants(valueSearch, page, limit);
    if (data) {
      setListApplicants(data?.applicants);
      setPagination(data?.pageInfo);
    }
    setLoading(false);
  };

  const onChangePage = (page) => {
    setCurrentPage(page);
  };

  const handleSortDateCreate = () => {
    const typeSort = sortApplyDateType === TYPE_DESC ? TYPE_ASC : TYPE_DESC;
    setSortApplyDateType(typeSort);
    const paramSearch = { ...querySearch, sortField: 'appliedDate', sortOrder: typeSort };
    getListApplicants(paramSearch);
  };

  const handleSortDateInterView = () => {
    const typeSort = sortInterviewDateType === TYPE_DESC ? TYPE_ASC : TYPE_DESC;
    setSortInterviewDateType(typeSort);
    const paramSearch = { ...querySearch, sortField: 'interviewDateFrom', sortOrder: typeSort };
    getListApplicants(paramSearch);
  };

  // Use Effect
  useEffect(() => {
    getListApplicants();
  }, [currentPage]);

  useEffect(() => {
    displayModal();
  });
  const dataPathName = window.location.pathname;
  return (
    <>
      <SearchApplicants type={type} setQuerySearch={setQuerySearch} getListApplicants={getListApplicants} />

      {/* <div className='w-fit mx-auto mt-[12%]'><i className="fa fa-spinner fa-spin"></i></div> : */}
      <div className='panel relative'>
        {loading ? (
          <Loading />
        ) : (
          <div className='panel__body'>
            <div className='dataList'>
              <div className='dataList__header -center'>
                {listApplicants?.length > 0 && (
                  <PaginationCustom currentPage={currentPage} onChangePage={onChangePage} pagination={pagination} />
                )}
                <div className='dataList__headerAction'>
                  <Link
                    to={URL_APPLICANT_NEW}
                    onClick={() => dispatch(setDataRouterBackButton({ from_url: dataPathName }))}
                    className='button -outline-primary -add'
                  >
                    新規登録
                  </Link>
                </div>
              </div>
              <div className='dataList__body'>
                <table className='dataList__table -applicants'>
                  <thead>
                    <tr>
                      <th data-column='entryDate' className='dataList__tableSort'>
                        <button
                          type='button'
                          onClick={handleSortDateCreate}
                          className={`dataList__tableSortButton ${sortApplyDateType === TYPE_ASC ? 'sortAsc' : ''}`}
                          data-sort='false'
                        >
                          応募日
                        </button>
                      </th>
                      <th data-column='name'>応募者</th>
                      <th data-column='chat'>チャット</th>
                      {type === 'applicantsPass' ? (
                        <th data-column='workType'>雇用形態</th>
                      ) : (
                        <>
                          <th data-column='progress'>進捗 / 結果</th>
                          <th data-column='interviewDate' className='dataList__tableSort'>
                            <button
                              type='button'
                              onClick={handleSortDateInterView}
                              className={`dataList__tableSortButton ${
                                sortInterviewDateType === TYPE_ASC ? 'sortAsc' : ''
                              }`}
                              data-sort='false'
                            >
                              面接日
                            </button>
                          </th>
                        </>
                      )}
                      <th data-column='job'>応募した求人</th>
                      <th data-column='detail'></th>
                    </tr>
                  </thead>
                  <tbody>
                    {listApplicants?.map((item) => (
                      <tr key={item?._id + item?.application?._id} className={item?.hiddenInList ? 'row-hidden' : ''}>
                        <td>{moment(item?.application?.appliedDate).format('YYYY/MM/DD')}</td>
                        <td>
                          <p className='dataList__tableMainText'>{item.lastName + ' ' + item.firstName}</p>
                          <p className='dataList__tableSubText'>
                            {`${getAgeUser({
                              fullYear: +item?.dateOfBirth_YYYY,
                              month: +item?.dateOfBirth_MM,
                              day: +item?.dateOfBirth_DD,
                            })}歳 /${setSex(item.sex ?? 'not_set')} `}
                            {item?.application?.rating && (
                              <span className='star u-spacer-left1'>{item?.application?.rating}</span>
                            )}
                          </p>
                        </td>
                        <td>
                          <div className='dataList__tableChatButton'>
                            <>
                              {item?.application?.applyGroupId && item?.application?.chatStatus !== NOT_YET_INVITE ? (
                                <button
                                  onClick={() => onHandleChatNewTab(item?.application?.applyGroupId)}
                                  className={`button -outline-primary -chat -small ${
                                    item?.application?.chatStatus == APPLICANT_EXIT ||
                                    item?.application?.chatStatus == APPLICANT_DELETED_ACCOUNT
                                      ? '!bg-[#d3d4d4]'
                                      : '!bg-transparent'
                                  }`}
                                  target='_blank'
                                >
                                  {' '}
                                  {item?.application?.chatStatus}
                                </button>
                              ) : (
                                <button
                                  onClick={() => setDataDetailApplicant(item)}
                                  data-modal-target='chat-invitation'
                                  className='button -outline-primary -mail -small !bg-transparent'
                                >
                                  {item?.application?.chatStatus}
                                </button>
                              )}
                            </>
                          </div>
                        </td>
                        {type !== 'applicantsPass' ? (
                          <>
                            <td>
                              {item?.application?.progress.length > 0
                                ? `${item?.application?.progress?.[item?.application?.progress.length - 1]?.step}/${
                                    item?.application?.progress?.[item?.application?.progress.length - 1]?.result
                                  }`
                                : null}
                            </td>
                            <td>
                              {item?.application?.progress?.[item?.application?.progress.length - 1]?.interviewStart
                                ? `${moment(
                                    item?.application?.progress?.[item?.application?.progress.length - 1]
                                      ?.interviewStart
                                  ).format('YYYY/MM/DD HH:mm')}-${
                                    item?.application?.progress?.[item?.application?.progress.length - 1]?.interviewEnd
                                      ? moment(
                                          item?.application?.progress?.[item?.application?.progress.length - 1]
                                            ?.interviewEnd
                                        ).format('HH:mm')
                                      : ''
                                  }`
                                : null}
                            </td>
                          </>
                        ) : (
                          <td>正社員（新卒）</td>
                        )}
                        <td>
                          {item?.application?.jobTitle}
                          <p className='dataList__tableSubText'>求人ID: {item?.application?.jobCode}</p>
                        </td>
                        <td>
                          <button
                            onClick={() => (
                              navigate(`/applicants-detail-basic/${item?._id}`),
                              dispatch(setDataRouterBackButton({ from_url: dataPathName }))
                            )}
                            className='button -primary -small'
                          >
                            詳細
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className='dataList__footer'>
                {listApplicants?.length > 0 ? (
                  <PaginationCustom currentPage={currentPage} onChangePage={onChangePage} pagination={pagination} />
                ) : (
                  <div className='w-fit m-auto text-[#d3d4d4]'>{NO_DATA}</div>
                )}
              </div>
              <ModalSubmitSendMail
                mode={'sendMailInListReplica'}
                refetchContext={getListApplicants}
                jobTitle={dataDetailApplicant?.application.jobTitle}
                jobId={dataDetailApplicant?.application.jobId}
                replicaId={dataDetailApplicant?._id}
                applicantEmail={dataDetailApplicant?.email}
                firstName={dataDetailApplicant?.firstName}
                lastName={dataDetailApplicant?.lastName}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default BodyTableListResult;
