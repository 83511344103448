/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useRef, useState } from 'react';
import {
  resetRequireValidate,
  handleKeyPress,
  handleRequireInvalid,
  validateEmail,
  validatePhoneNumber,
  validateZipcode,
  getAgeUser,
  openNewTabPreviewPDF,
} from '../../../../../common/FuntionCommon';
import { toast } from '../../../../../../utils/notification';
import {
  ADDRESS_NOT_FOUND,
  APPLICANT_SETTING,
  CREATE_SUCCESSFULLY,
  ERROR_EMAIL,
  TYPE_PDF_CV,
  TYPE_PDF_WORKHISTORY,
  PHONE_NUMBER_FORMAT,
  PLEASE_CHECK_INPUT,
  UPDATE_SUCCESSFULLY,
  ZIP_CODE_FORMAT,
  currentYear,
} from '../../../../../common/constant';
import {
  CreateNewApplicant,
  GetUrlPdfForApplicant,
  UpdateApplicants,
} from '../../../../../../services/RecruiterService';
import { useNavigate, useParams } from 'react-router-dom';
import { get } from 'japan-postal-code';

export const getOptionsYear = () => {
  const optionsYear = [{ label: '選択', value: '' }];
  for (let i = 1945; i <= 2023; i++) {
    optionsYear.push({
      label: i,
      value: i,
    });
  }
  return optionsYear;
};

export const getOptionsMonth = () => {
  const optionsMonth = [{ label: '選択', value: '' }];
  for (let i = 1; i <= 12; i++) {
    optionsMonth.push({
      label: i,
      value: i,
    });
  }
  return optionsMonth;
};

export const getOptionsDate = () => {
  const optionsDate = [{ label: '選択', value: '' }];
  for (let i = 1; i <= 31; i++) {
    optionsDate.push({
      label: i,
      value: i,
    });
  }
  return optionsDate;
};

export const getOptionsYearGraduation = () => {
  const optionsYearGraduation = [{ label: '選択', value: '' }];
  for (let i = currentYear; i >= 1950; i--) {
    optionsYearGraduation.push({
      label: i,
      value: i,
    });
  }
  return optionsYearGraduation;
};
const ApplicantBasicInfo = ({ mode, dataDetail, jobIdFormPageJob, listJobs, setDataDetail }) => {
  const [fileCV, setFileCV] = useState(null);
  const [fileWorkHistory, setFileWorkHistory] = useState(null);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const validatesRef = useRef({
    jobId: null,
    appliedDate: null,
    firstName: null,
    lastName: null,
    dateOfBirth_YYYY: null,
    dateOfBirth_MM: null,
    dateOfBirth_DD: null,
    email: null,
  });

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    const validated = validateInput();
    if (!validated) {
      // If there are any error in validate, then do nothing
      toast('error', PLEASE_CHECK_INPUT);
      return;
    } else {
      clearErrorMessage();
      setLoading(true);
      const dataForm = document.forms['applicantData'];
      const payload = {
        firstName: dataForm['firstName']?.value,
        lastName: dataForm['lastName']?.value,
        dateOfBirth_DD: dataForm['dateOfBirth_DD']?.value,
        dateOfBirth_MM: dataForm['dateOfBirth_MM']?.value,
        dateOfBirth_YYYY: dataForm['dateOfBirth_YYYY']?.value,
        firstNameFurigana: dataForm['firstNameFurigana']?.value,
        lastNameFurigana: dataForm['lastNameFurigana']?.value,
        sex: dataForm['gender'].value,
        email: dataForm['email']?.value,
        zipCode: dataForm['zipCode']?.value,
        phoneNumber: dataForm['phoneNumber']?.value,
        address: dataForm['address']?.value,
        highestEducation: dataForm['highestEducation']?.value,
        graduatedYear: dataForm['graduatedYear']?.value,
        major: dataForm['major']?.value,
        certificates: dataForm['certificates']?.value,
        workHistory: dataForm['workHistory']?.value,
      };
      if (mode === 'create') {
        payload['jobId'] = dataForm['jobId']?.value;
        payload['appliedDate'] = dataForm['appliedDate']?.value;
      } else {
        payload['hiddenInList'] = dataForm['hiddenInList']?.checked ? 'true' : 'false';
      }
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        formData.append(`${key}`, payload[key]);
      });

      if (fileCV !== null) {
        formData.append('pdfCV', fileCV);
      }
      if (fileWorkHistory !== null) {
        formData.append('pdfWorkHistory', fileWorkHistory);
      }
      let data = null;
      if (mode === 'create') {
        data = await CreateNewApplicant(formData);
        if (data) {
          navigate(`/applicants-detail-basic/${data?.data?._id}`);
        }
      } else {
        data = await UpdateApplicants(id, formData);
        if (data) {
          setDataDetail(data?.data);
        }
      }
      setLoading(false);
      if (data) {
        toast('success', mode === 'create' ? CREATE_SUCCESSFULLY : UPDATE_SUCCESSFULLY);
      }
    }
  };

  // option selects
  const optionsYear = getOptionsYear();
  const optionsMonth = getOptionsMonth();
  const optionsDate = getOptionsDate();
  const optionsYearGraduation = getOptionsYearGraduation();

  // set old user
  const [age, setAge] = useState('');
  const handleChangeYear = async (e) => {
    resetRequireValidate(e);
    setAge(
      getAgeUser({
        fullYear: +document.forms['applicantData']['dateOfBirth_YYYY'].value,
        month: +document.forms['applicantData']['dateOfBirth_MM'].value,
        day: +document.forms['applicantData']['dateOfBirth_DD'].value,
      })
    );
  };
  const handleChangeMonth = async (e) => {
    resetRequireValidate(e);
    setAge(
      getAgeUser({
        fullYear: +document.forms['applicantData']['dateOfBirth_YYYY'].value,
        month: +document.forms['applicantData']['dateOfBirth_MM'].value,
        day: +document.forms['applicantData']['dateOfBirth_DD'].value,
      })
    );
  };
  const handleChangeDay = async (e) => {
    resetRequireValidate(e);
    setAge(
      getAgeUser({
        fullYear: +document.forms['applicantData']['dateOfBirth_YYYY'].value,
        month: +document.forms['applicantData']['dateOfBirth_MM'].value,
        day: +document.forms['applicantData']['dateOfBirth_DD'].value,
      })
    );
  };
  // fill data for form
  const fillDataDetail = () => {
    const dataForm = document.forms['applicantData'];
    setAge(
      getAgeUser({
        fullYear: +dataDetail?.dateOfBirth_YYYY,
        month: +dataDetail?.dateOfBirth_MM,
        day: +dataDetail?.dateOfBirth_DD,
      })
    );
    dataForm['firstName'].value = dataDetail?.firstName ?? '';
    dataForm['lastName'].value = dataDetail?.lastName ?? '';
    dataForm['dateOfBirth_DD'].value = dataDetail?.dateOfBirth_DD ?? '';
    dataForm['dateOfBirth_MM'].value = dataDetail?.dateOfBirth_MM ?? '';
    dataForm['dateOfBirth_YYYY'].value = dataDetail?.dateOfBirth_YYYY ?? '';
    dataForm['firstNameFurigana'].value = dataDetail?.firstNameFurigana ?? '';
    dataForm['lastNameFurigana'].value = dataDetail?.lastNameFurigana ?? '';
    dataForm['gender'].value = dataDetail?.sex ?? 'not_set';
    dataForm['email'].value = dataDetail?.email ?? '';
    dataForm['zipCode'].value = dataDetail?.zipCode ?? '';
    dataForm['phoneNumber'].value = dataDetail?.phoneNumber ?? '';
    dataForm['address'].value = dataDetail?.address ?? '';
    dataForm['highestEducation'].value = dataDetail?.highestEducation ?? '';
    dataForm['graduatedYear'].value = dataDetail?.graduatedYear ?? '';
    dataForm['major'].value = dataDetail?.major ?? '';
    dataForm['certificates'].value = dataDetail?.certificates ?? '';
    dataForm['workHistory'].value = dataDetail?.workHistory ?? '';
  };
  // validate Form
  const [showErrorFindAddress, setShowErrorFindAddress] = useState('');
  const [showErrorPhone, setShowErrorPhone] = useState('');
  const [showErrorZipcode, setShowErrorZipcode] = useState('');
  const [showErrorEmail, setShowErrorEmail] = useState('');
  const [urlFilePdfAfterChange, setUrlFilePdfAfterChange] = useState('');
  const [urlFilePdfHistoryAfterChange, setUrlFilePdfHistoryAfterChange] = useState('');

  const getDataAddress = async () => {
    let search = false;
    await new Promise((resolve, reject) => {
      get(document.forms['applicantData']['zipCode'].value, function (address) {
        search = true;

        if (address) {
          document.forms['applicantData']['address'].value = `${address.prefecture ?? ''} ${address.city ?? ''} ${
            address.area ?? ''
          } ${address.street ?? ''}`;
          setShowErrorFindAddress('');
          resolve(address);
        }
      });

      // reject after 4 seconds
      setTimeout(() => {
        resolve('Operation timed out.');
      }, 4000);
    });

    if (!search) {
      setShowErrorFindAddress(ADDRESS_NOT_FOUND);
    }
  };

  const clearErrorMessage = () => {
    setShowErrorFindAddress('');
    setShowErrorZipcode('');
    setShowErrorEmail('');
    setShowErrorPhone('');
    // reset message some field require
    validatesRef?.current?.jobId?.setCustomValidity('');
    validatesRef?.current?.appliedDate?.setCustomValidity('');
    validatesRef?.current?.firstName?.setCustomValidity('');
    validatesRef?.current?.lastName?.setCustomValidity('');
    validatesRef?.current?.dateOfBirth_YYYY?.setCustomValidity('');
    validatesRef?.current?.dateOfBirth_MM?.setCustomValidity('');
    validatesRef?.current?.dateOfBirth_DD?.setCustomValidity('');
    validatesRef?.current?.email?.setCustomValidity('');
  };

  const onHandleCancel = () => {
    clearErrorMessage();
    setUrlFilePdfAfterChange('');
    setUrlFilePdfHistoryAfterChange('');
    if (mode === 'create') {
      document?.forms['applicantData'].reset();
    } else {
      fillDataDetail();
    }
    // reset select file fpf
    const fileFields = document.querySelectorAll('.js-fileField');
    fileFields.forEach((fileField, index) => {
      const fileInput = fileField.querySelector('.fileField__input');
      const fileName = fileField.querySelector('.fileField__name');

      const resetSelectFile = () => {
        fileInput.value = '';
        fileField.classList.remove('is-fileSet');
        fileName.textContent = '選択されていません';
        fileName.removeAttribute('tabindex');
      };
      const fillDataFilePdf = async () => {
        if (dataDetail?.pdfCV && mode === 'detail' && index === 0) {
          fileField.classList.add('is-fileSet');
          fileName.textContent = '履歴書（PDF）';
          fileName.tabIndex = '0';
        } else if (!dataDetail?.pdfCV && mode === 'detail' && index === 0) {
          resetSelectFile();
        }
        if (dataDetail?.pdfWorkHistory && mode === 'detail' && index === 1) {
          fileField.classList.add('is-fileSet');
          fileName.textContent = '職務経歴書（PDF）';
          fileName.tabIndex = '0';
        } else if (!dataDetail?.pdfWorkHistory && mode === 'detail' && index === 1) {
          resetSelectFile();
        }
      };
      if (mode !== 'detail') {
        resetSelectFile();
      } else {
        fillDataFilePdf();
      }
    });
    //
    if (mode === 'detail') {
      document.forms['applicantData']['hiddenInList'].checked = dataDetail?.hiddenInList;
    }
  };

  const validateInput = () => {
    let validatedItem = true;
    // Zip code
    const trueZipcode = validateZipcode(document.forms['applicantData']['zipCode'].value);
    if (!trueZipcode) {
      setShowErrorZipcode(ZIP_CODE_FORMAT);
      validatedItem = false;
    }

    // phone Number
    const truePhone = validatePhoneNumber(document.forms['applicantData']['phoneNumber'].value);

    if (!truePhone) {
      setShowErrorPhone(PHONE_NUMBER_FORMAT);
      validatedItem = false;
    }

    //validate email
    const isEmail = validateEmail(document.forms['applicantData']['email'].value);
    if (!isEmail) {
      setShowErrorEmail(ERROR_EMAIL);
      validatedItem = false;
    }
    return validatedItem;
  };

  const customizeInputFile = () => {
    const fileFields = document.querySelectorAll('.js-fileField');

    if (!fileFields.length) {
      return;
    }

    fileFields.forEach((fileField, index) => {
      const fileInput = fileField.querySelector('.fileField__input');
      const fileName = fileField.querySelector('.fileField__name');
      const clearButton = fileField.querySelector('.fileField__clearButton');
      let fileUrl;

      if (dataDetail?.pdfCV && mode === 'detail' && index === 0) {
        fileField.classList.add('is-fileSet');
        fileName.textContent = '履歴書（PDF）';
        fileName.tabIndex = '0';
      }
      if (dataDetail?.pdfWorkHistory && mode === 'detail' && index === 1) {
        fileField.classList.add('is-fileSet');
        fileName.textContent = '職務経歴書（PDF）';
        fileName.tabIndex = '0';
      }

      // ファイルが選択された場合
      fileInput.addEventListener('change', (event) => {
        if (fileInput.files.length) {
          fileField.classList.add('is-fileSet');
          fileName.textContent = event.target.files[0].name;
          fileUrl = URL.createObjectURL(fileInput.files[0]);
          if (index === 0) {
            setFileCV(event.target.files[0]);
            setUrlFilePdfAfterChange(fileUrl);
          } else {
            setFileWorkHistory(event.target.files[0]);
            setUrlFilePdfHistoryAfterChange(fileUrl);
          }
          fileName.tabIndex = '0';
        }
      });

      clearButton.addEventListener('click', () => {
        fileInput.value = '';
        fileField.classList.remove('is-fileSet');
        fileName.textContent = '選択されていません';
        fileName.removeAttribute('tabindex');
      });
    });
  };
  useEffect(() => {
    if (mode === 'detail' && Object.keys(dataDetail).length > 0) {
      fillDataDetail();
    }
    customizeInputFile();
  }, [dataDetail]);

  return (
    <div className='panel'>
      <div className='panel__body -large'>
        <form name='applicantData' onKeyDown={handleKeyPress} className='form' onSubmit={(e) => handleOnSubmit(e)}>
          {mode === 'create' && (
            <section>
              <h2 className='title -primary'>応募情報</h2>
              <table className='detailTable u-spacer-top2'>
                <tr>
                  <th>
                    応募した求人<span className='badge -danger u-spacer-left1'>必須</span>
                  </th>
                  <td>
                    <select
                      defaultValue={jobIdFormPageJob ? jobIdFormPageJob : ''}
                      required
                      onInvalid={(e) => handleRequireInvalid(e)}
                      onChange={(e) => resetRequireValidate(e)}
                      className='select'
                      name='jobId'
                      ref={(e) => (validatesRef.current.jobId = e)}
                    >
                      <option value=''>選択してください</option>
                      {listJobs?.map((item) => (
                        <option
                          selected={jobIdFormPageJob ? jobIdFormPageJob === item.value : ''}
                          key={item.value}
                          value={item?.value}
                        >
                          {item?.label}
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
                <tr>
                  <th>
                    応募日<span className='badge -danger u-spacer-left1'>必須</span>
                  </th>
                  <td>
                    <input
                      required
                      onInvalid={(e) => handleRequireInvalid(e)}
                      onChange={(e) => resetRequireValidate(e)}
                      type='date'
                      className='textfield -short'
                      name='appliedDate'
                      ref={(e) => (validatesRef.current.appliedDate = e)}
                    />
                  </td>
                </tr>
              </table>
            </section>
          )}
          <section className={`${mode === 'create' ? 'u-spacer-top6' : ''}`}>
            {mode === 'create' && <h2 className='title -primary'>基本情報</h2>}
            <table className='detailTable u-spacer-top2'>
              <caption className='detailTable__caption'>プロフィール</caption>
              <tbody>
                <tr>
                  <th>
                    氏名<span className='badge -danger u-spacer-left1'>必須</span>
                  </th>
                  <td>
                    <div className='form__inline'>
                      <input
                        type='text'
                        required
                        onInvalid={(e) => handleRequireInvalid(e)}
                        onChange={(e) => resetRequireValidate(e)}
                        className='textfield -short'
                        placeholder='姓'
                        name='lastName'
                        ref={(e) => (validatesRef.current.lastName = e)}
                      />
                      <input
                        type='text'
                        required
                        onInvalid={(e) => handleRequireInvalid(e)}
                        onChange={(e) => resetRequireValidate(e)}
                        className='textfield -short'
                        placeholder='名'
                        name='firstName'
                        ref={(e) => (validatesRef.current.firstName = e)}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>氏名（フリガナ）</th>
                  <td>
                    <div className='form__inline'>
                      <input type='text' className='textfield -short' placeholder='セイ' name='firstNameFurigana' />
                      <input type='text' className='textfield -short' placeholder='メイ' name='lastNameFurigana' />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>
                    生年月日<span className='badge -danger u-spacer-left1'>必須</span>
                  </th>
                  <td>
                    <div className='form__inline'>
                      <select
                        className='select -x_short'
                        required
                        onInvalid={(e) => handleRequireInvalid(e)}
                        onChange={(e) => handleChangeYear(e)}
                        name='dateOfBirth_YYYY'
                        ref={(e) => (validatesRef.current.dateOfBirth_YYYY = e)}
                      >
                        {optionsYear?.map((item) => (
                          <option key={item.value} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                      </select>
                      年
                      <select
                        required
                        onInvalid={(e) => handleRequireInvalid(e)}
                        onChange={(e) => handleChangeMonth(e)}
                        className='select -x_short'
                        name='dateOfBirth_MM'
                        ref={(e) => (validatesRef.current.dateOfBirth_MM = e)}
                      >
                        {optionsMonth?.map((item) => (
                          <option key={item.value} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                      </select>
                      月
                      <select
                        required
                        onInvalid={(e) => handleRequireInvalid(e)}
                        onChange={(e) => handleChangeDay(e)}
                        className='select -x_short'
                        name='dateOfBirth_DD'
                        ref={(e) => (validatesRef.current.dateOfBirth_DD = e)}
                      >
                        {optionsDate?.map((item) => (
                          <option key={item.value} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                      </select>
                      日{age ? age + '歳' : ''}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>
                    性別<span className='badge -danger u-spacer-left1'>必須</span>
                  </th>
                  <td>
                    <div className='radio-group'>
                      <label className='radio'>
                        <input type='radio' name='gender' value='male' className='radio__input' defaultChecked />
                        <span className='radio__label'>男性</span>
                      </label>
                      <label className='radio'>
                        <input type='radio' name='gender' value='female' className='radio__input' />
                        <span className='radio__label'>女性</span>
                      </label>
                      <label className='radio'>
                        <input type='radio' name='gender' value='not_set' className='radio__input' />
                        <span className='radio__label'>回答しない</span>
                      </label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>
                    メールアドレス<span className='badge -danger u-spacer-left1'>必須</span>
                  </th>
                  <td>
                    <div className='flex gap-[20px] items-center'>
                      <input
                        required
                        onInvalid={(e) => handleRequireInvalid(e)}
                        onChange={(e) => e.target.setCustomValidity('')}
                        name='email'
                        className='textfield'
                        placeholder='例）taro@example.com'
                        ref={(e) => (validatesRef.current.email = e)}
                      />
                      {showErrorEmail && <span className='text-[red] customError'>{showErrorEmail}</span>}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>電話番号</th>
                  <td>
                    <div className='form__inline'>
                      <input type='text' className='textfield -short' name='phoneNumber' />
                      {showErrorPhone && <span className='text-[red] customError'>{showErrorPhone}</span>}
                    </div>
                    <p className='detailTable__note'>※ハイフンなしで入力してください。</p>
                  </td>
                </tr>
                <tr>
                  <th>郵便番号</th>
                  <td>
                    <div className='form__inline'>
                      <input
                        name='zipCode'
                        type='text'
                        placeholder='郵便番号を入力してください'
                        maxLength={8}
                        className='textfield -short'
                        onChange={() => setShowErrorFindAddress('')}
                      />
                      <button onClick={() => getDataAddress()} type='button' className='button -secondary'>
                        住所自動入力
                      </button>
                      {showErrorFindAddress && <span className='text-[red]'>{showErrorFindAddress}</span>}
                      {showErrorZipcode && <span className='text-[red] customError'>{showErrorZipcode}</span>}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>住所</th>
                  <td>
                    <input type='text' name='address' className='textfield' />
                  </td>
                </tr>
              </tbody>
            </table>
            <table className='detailTable'>
              <caption className='detailTable__caption'>学歴・職歴</caption>
              <tbody>
                <tr>
                  <th>最終学歴</th>
                  <td>
                    <div className='form__inline'>
                      <select className='select -short' name='highestEducation'>
                        <option value=''>選択してください</option>
                        <option value='大学院了(博士)'>大学院了(博士)</option>
                        <option value='大学院了(修士)'>大学院了(修士)</option>
                        <option value='大学卒'>大学卒</option>
                        <option value='短大卒'>短大卒</option>
                        <option value='高専卒'>高専卒</option>
                        <option value='専門学校卒'>専門学校卒</option>
                        <option value='高校卒'>高校卒</option>
                        <option value='その他'>その他</option>
                      </select>
                      <select className='select -x_short u-spacer-left1' name='graduatedYear'>
                        {optionsYearGraduation?.map((item) => (
                          <option key={item.value}>{item.value}</option>
                        ))}
                      </select>
                      年卒業
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>学校名・学部・学科</th>
                  <td>
                    <input
                      name='major'
                      type='text'
                      className='textfield'
                      placeholder='例）テスト大学　経営学部　経営学科'
                    />
                  </td>
                </tr>
                <tr>
                  <th>保有資格</th>
                  <td>
                    <textarea name='certificates' className='textfield' cols='40' rows='4'></textarea>
                    <p className='detailTable__note'>
                      例）普通自動車免許あり（20xx年△月取得）、TOEIC ◯点（20xx年△月取得）
                    </p>
                  </td>
                </tr>
                <tr>
                  <th>職務経歴（テキスト）</th>
                  <td>
                    <textarea name='workHistory' className='textfield' cols='40' rows='4'></textarea>
                    <p className='detailTable__note'>
                      例）AAA株式会社 営業部 △△△△を販売（20xx年△月〜20xx年△月）
                      <br />
                      株式会社BBB 営業企画部 マネージャー 企画立案・チームメンバーのマネジメント (20xx年△月〜現在)
                    </p>
                  </td>
                </tr>
                <tr>
                  <th>履歴書（PDF）</th>
                  <td>
                    <div className='fileField js-fileField' value='pdfCV'>
                      <label className='button -secondary fileField__uploadButton'>
                        ファイルを選択
                        <input type='file' accept='.pdf' name='pdfCV' className='fileField__input' />
                      </label>
                      <div
                        className='fileField__name'
                        role='button'
                        id='pdfCV'
                        aria-hidden
                        onClick={() =>
                          document.getElementById('pdfCV').textContent !== '選択されていません'
                            ? openNewTabPreviewPDF(
                                urlFilePdfAfterChange,
                                dataDetail?._id,
                                TYPE_PDF_CV,
                                APPLICANT_SETTING
                              )
                            : null
                        }
                      >
                        選択されていません
                      </div>
                      <button
                        onClick={() => setFileCV('')}
                        type='button'
                        className='button -secondary fileField__clearButton'
                      >
                        クリア
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>職務経歴書（PDF）</th>
                  <td>
                    <div className='fileField js-fileField' value='pdfWorkHistory'>
                      <label className='button -secondary fileField__uploadButton'>
                        ファイルを選択
                        <input type='file' accept='.pdf' name='pdfWorkHistory' className='fileField__input' />
                      </label>
                      <div
                        className='fileField__name'
                        id='pdfWorkHistory'
                        role='button'
                        aria-hidden
                        onClick={() =>
                          document.getElementById('pdfWorkHistory').textContent !== '選択されていません'
                            ? openNewTabPreviewPDF(
                                urlFilePdfHistoryAfterChange,
                                dataDetail?._id,
                                TYPE_PDF_WORKHISTORY,
                                APPLICANT_SETTING
                              )
                            : null
                        }
                      >
                        選択されていません
                      </div>
                      <button
                        onClick={() => setFileWorkHistory('')}
                        type='button'
                        className='button -secondary fileField__clearButton'
                      >
                        クリア
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
          {mode === 'detail' && (
            <table className='detailTable mt-[32px]'>
              <caption className='detailTable__caption'>表示設定</caption>
              <tbody>
                <tr>
                  <th>表示ステータス</th>
                  <td>
                    <label className='checkbox'>
                      <input
                        type='checkbox'
                        name='hiddenInList'
                        defaultChecked={dataDetail?.hiddenInList}
                        className='checkbox__input'
                      />
                      <span className='checkbox__label'>応募者一覧から非表示にする</span>
                    </label>
                  </td>
                </tr>
              </tbody>
            </table>
          )}
          <div className='u-spacer-top4'>
            <div className='button-wrapper -center'>
              <button onClick={() => onHandleCancel()} type='button' className='button -default'>
                キャンセル
              </button>
              <button type='submit' disabled={loading} className='button -primary'>
                {loading && <i className='fa fa-spinner fa-spin'></i>}保存
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ApplicantBasicInfo;
