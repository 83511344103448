/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import ModalReplicaEvaluate from '../../../../Helper/modalReplicaEvaluate';
import ModalEditProgressInfo from '../../../../Helper/modalEditProgressInfo';
import { ROLE_CHAT_ONLY } from '../../../../common/constant';
import * as CONSTANT from '../../../../common/constant';
import { getAgeUser, getWeekDay, openNewTabPreviewPDF, setSex } from '../../../../common/FuntionCommon';
import { useDispatch, useSelector } from 'react-redux';
import { chatSlice, setInfoEvaluateInterviewApplicant } from '../../../../redux/reducerChat';
import moment from 'moment';
import { URL_API_COMPANY_PROGRESS } from '../../../../../services';
import useSWR from 'swr';
import { getDataProgress } from '../../../../../services/RecruiterService';

const ApplicantInformation = ({ dataApplication, applicantId, jobId }) => {
  const [openModalEditInterview, setOpenModalEditInterview] = useState(false);
  const { currentActiveGroup } = useSelector(chatSlice);
  const dispatch = useDispatch();
  const { evaluateInterviewApplicant } = useSelector(chatSlice);
  const role_user = JSON.parse(localStorage?.getItem('role'));
  const dataRightBarCurrentGroup = currentActiveGroup?.employeeId ?? currentActiveGroup?.applicantReplicaId;

  /**
   * ツールチップ
   * 省略されている箇所にカーソルホバーしたらすべて表示
   */
  const displayTooltip = () => {
    const tooltipTargets = document.querySelectorAll('.js-tooltipTarget');

    if (!tooltipTargets.length) {
      return;
    }

    tooltipTargets.forEach((target) => {
      // スクロールが発生しているか調べる
      const isOverflow = target.scrollHeight > target.offsetHeight;

      if (isOverflow) {
        target.classList.add('is-overflow');

        target.addEventListener('mouseenter', () => {
          // tooltipを生成して`body`に挿入
          const tooltip = document.createElement('div');
          tooltip.classList.add('tooltip');
          tooltip.innerText = target.textContent;
          document.body.appendChild(tooltip);

          const rect = target.getBoundingClientRect();
          const baseValue = 60;

          // targetの位置からtooltipの表示場所を指定
          tooltip.style.cssText = `
          position: absolute;
          top: 0;
          left: 0;
          width: ${rect.width + baseValue}px;
          transform: translate(${rect.x - baseValue / 2}px, ${rect.y + target.offsetHeight}px);
        `;

          target.addEventListener('mouseleave', () => {
            tooltip.remove();
          });
        });
      }
    });
  };

  useEffect(() => {
    if (dataApplication) {
      dispatch(setInfoEvaluateInterviewApplicant(dataApplication));
    }
  }, [currentActiveGroup]);

  useEffect(() => {
    displayTooltip();
  }, [evaluateInterviewApplicant]);

  const dateInterview =
    evaluateInterviewApplicant?.progress?.[evaluateInterviewApplicant?.progress?.length - 1]?.interviewStart;
  const dayJapanese = new Date(dateInterview);

  const { data: dataProgress } = useSWR(URL_API_COMPANY_PROGRESS, getDataProgress, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  return (
    <section className='js-tabContent' id='applicantInfo'>
      <div className='chatProfile'>
        {dataRightBarCurrentGroup?.avatar ? (
          <img
            src={`${currentActiveGroup?.applyProfile?.avatar || dataRightBarCurrentGroup.avatar}`}
            alt=''
            className='avatar -large'
          />
        ) : (
          <img src='avatar-default.svg' alt='' className='avatar' />
        )}
        <div className='chatProfile__body'>
          <h3>
            <span className='chatProfile__name'>
              {`${dataRightBarCurrentGroup?.lastName ?? ''} ${dataRightBarCurrentGroup?.firstName ?? ''} `}
            </span>
            {`(${dataRightBarCurrentGroup?.lastNameFurigana ?? ''} ${
              dataRightBarCurrentGroup?.firstNameFurigana ?? ''
            } )`}
          </h3>
          <p className='chatProfile__meta'>
            {dataRightBarCurrentGroup?.dateOfBirth_YYYY
              ? `${getAgeUser({
                  fullYear: +dataRightBarCurrentGroup?.dateOfBirth_YYYY,
                  month: +dataRightBarCurrentGroup?.dateOfBirth_MM,
                  day: +dataRightBarCurrentGroup?.dateOfBirth_DD,
                })}歳 /${setSex(dataRightBarCurrentGroup?.sex)} `
              : ''}
          </p>
        </div>
      </div>
      <div className='chatEditBox'>
        <div className='chatEditBox__inner'>
          <dl className='chatEditBox__definitionList' style={{ width: '70%' }}>
            <div className='chatEditBox__definitionItem -inline'>
              <dt>ステータス</dt>
              <dd>{evaluateInterviewApplicant?.evaluationStatus || '-'}</dd>
            </div>
            <div className='chatEditBox__definitionItem -inline'>
              <dt style={{ width: 'auto' }}>評価</dt>
              <dd>
                <span className='star'>{evaluateInterviewApplicant?.rating || '-'}</span>
              </dd>
            </div>
            <div className='chatEditBox__definitionItem'>
              <dt>備考</dt>
              <dd className='tooltip-target js-tooltipTarget' style={{ maxWidth: '70%' }}>
                {evaluateInterviewApplicant?.note ?? '-'}
              </dd>
            </div>
          </dl>
          {role_user !== ROLE_CHAT_ONLY && !currentActiveGroup?.employeeId && (
            <button
              disabled={!dataRightBarCurrentGroup}
              type='button'
              className={`button -default -small -edit ${!dataRightBarCurrentGroup ? '-disabled' : ''}`}
              data-modal-target='selection1'
            >
              編集
            </button>
          )}
        </div>
      </div>
      <div className='chatEditBox'>
        <div className='chatEditBox__inner'>
          <dl className='chatEditBox__definitionList'>
            <div className='chatEditBox__definitionItem'>
              <dt>進捗 / 結果</dt>
              <dd>
                {evaluateInterviewApplicant?.progress?.length > 0
                  ? evaluateInterviewApplicant?.progress?.[evaluateInterviewApplicant?.progress.length - 1]?.step +
                    '/' +
                    evaluateInterviewApplicant?.progress?.[evaluateInterviewApplicant?.progress.length - 1]?.result
                  : '-'}
              </dd>
            </div>
            <div className='chatEditBox__definitionItem'>
              <dt>面接日時</dt>
              {evaluateInterviewApplicant?.progress?.length > 0 && dateInterview ? (
                <dd>
                  <p>
                    {moment(dateInterview).format('YYYY年MM月DD日')}
                    {getWeekDay(dayJapanese)}
                  </p>
                  <p className='u-display-inlineBlock'>
                    {moment(dateInterview).format('HH:mm')}-{' '}
                    {evaluateInterviewApplicant?.progress?.[evaluateInterviewApplicant?.progress?.length - 1]
                      ?.interviewEnd
                      ? moment(
                          evaluateInterviewApplicant?.progress?.[evaluateInterviewApplicant?.progress?.length - 1]
                            ?.interviewEnd
                        ).format('HH:mm')
                      : ''}
                  </p>
                </dd>
              ) : (
                <dd>-</dd>
              )}
            </div>
          </dl>
          {role_user !== ROLE_CHAT_ONLY && !currentActiveGroup?.employeeId && (
            <button
              type='button'
              disabled={!dataRightBarCurrentGroup}
              onClick={() => setOpenModalEditInterview(true)}
              className={`button -default -small -edit ${!dataRightBarCurrentGroup ? '-disabled' : ''}`}
            >
              編集
            </button>
          )}
        </div>
      </div>
      <dl className='chatInfo__definitionList'>
        <dt>メールアドレス</dt>
        <dd>{dataRightBarCurrentGroup?.email}</dd>
        <dt>電話番号</dt>
        <dd>{dataRightBarCurrentGroup?.phoneNumber || '-'}</dd>
        <dt>郵便番号</dt>
        <dd>{dataRightBarCurrentGroup?.zipCode || '-'}</dd>
        <dt>住所</dt>
        <dd>{dataRightBarCurrentGroup?.address || '-'}</dd>
        <dt>最終学歴</dt>
        <dd>
          {dataRightBarCurrentGroup
            ? `${dataRightBarCurrentGroup?.highestEducation + dataRightBarCurrentGroup?.graduatedYear + '年卒業'}`
            : '-'}
        </dd>
        <dt>学校名・学部・学科</dt>
        <dd>{dataRightBarCurrentGroup?.major || '-'}</dd>
        <dt>保有資格</dt>
        <dd>{dataRightBarCurrentGroup?.certificates || '-'}</dd>
        <dt>職務経歴（テキスト）</dt>
        <dd>{dataRightBarCurrentGroup?.workHistory || '-'}</dd>
        <dt>履歴書（PDF）</dt>
        <dd>
          {dataRightBarCurrentGroup?.pdfCV ? (
            <div className='cursor-pointer w-fit'>
              <a
                onClick={() =>
                  openNewTabPreviewPDF(
                    '',
                    dataRightBarCurrentGroup?._id,
                    CONSTANT.TYPE_PDF_CV,
                    dataRightBarCurrentGroup?.employeeJobId ? CONSTANT.EMPLOYEE_SETTING : CONSTANT.APPLICANT_SETTING
                  )
                }
              >
                履歴書.pdf
              </a>
            </div>
          ) : (
            '-'
          )}
        </dd>
        <dt>職務経歴書（PDF）</dt>
        <dd>
          {dataRightBarCurrentGroup?.pdfWorkHistory ? (
            <div className='cursor-pointer w-fit'>
              <a
                onClick={() =>
                  openNewTabPreviewPDF(
                    '',
                    dataRightBarCurrentGroup?._id,
                    CONSTANT.TYPE_PDF_WORKHISTORY,
                    dataRightBarCurrentGroup?.employeeJobId ? CONSTANT.EMPLOYEE_SETTING : CONSTANT.APPLICANT_SETTING
                  )
                }
              >
                職務経歴書.pdf
              </a>
            </div>
          ) : (
            ''
          )}
        </dd>
      </dl>
      <ModalReplicaEvaluate dataApplication={evaluateInterviewApplicant} applicantId={applicantId} />
      {openModalEditInterview && (
        <ModalEditProgressInfo
          jobId={evaluateInterviewApplicant?.jobId ?? jobId}
          idUser={applicantId}
          typeAction={CONSTANT.ACTION_PROGRESS_FLEXIBLE}
          currentProgress={evaluateInterviewApplicant?.progress?.[evaluateInterviewApplicant?.progress?.length - 1]}
          isModalOpen={openModalEditInterview}
          handleCancel={() => setOpenModalEditInterview(false)}
          listSettingCompanyProgress={dataProgress?.progress?.progress}
        />
      )}
    </section>
  );
};
export default ApplicantInformation;
