/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-unused-vars */
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Image } from 'antd';
import { SettingCompany, getDataDetailCompany } from '../../../../../../services/RecruiterService';
import { get } from 'japan-postal-code';
import { toast } from '../../../../../../utils/notification';
import { useDispatch, useSelector } from 'react-redux';
import { dataSlice, setDataInitial } from '../../../../../redux/reducer';
import { urlServer } from '../../../../../common/constant';
import Loading from '../../../../../common/SpinLoading';
import {
  resetRequireValidate,
  handleRequireInvalid,
  handleKeyPress,
  validatePhoneNumber,
  validateZipcode,
} from '../../../../../common/FuntionCommon';
import { UPDATE_SUCCESSFULLY, UPDATE_FAIL } from '../../../../../common/constant';
import useSWR, { mutate, preload } from 'swr';
import { URL_API_DETAIL_COMPANY } from '../../../../../../services';
import * as CONSTANT from '../../../../../common/constant';

const Company = () => {
  const [loading, setLoading] = useState({ loadingDetail: true, loadingAddUpdate: false });
  const dataLandingPage = useSelector(dataSlice);
  const dataSetting = JSON.parse(localStorage?.getItem('dataSetting'));
  const dispatch = useDispatch();
  const { dataInitial } = useSelector(dataSlice);
  const validatesRef = useRef({
    companyName: null,
  });

  const fillData = (data) => {
    const formData = document.forms['settingData'];
    formData['companyName'].value = data?.companyName ?? '';
    formData['phoneNumber'].value = data?.phone ?? '';
    formData['zipCode'].value = data?.zipCode ?? '';
    formData['address'].value = data?.address ?? '';
  };
  const { data, error, isLoading } = useSWR(URL_API_DETAIL_COMPANY, getDataDetailCompany, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  useLayoutEffect(() => {
    if (data?.company) {
      fillData(data?.company);
    }
  }, [isLoading]);

  const handleSettingCompany = async (e) => {
    e.preventDefault();
    const validated = validateInput();
    if (!validated) {
      // If there are any error in validate, then do nothing
      return;
    }
    setLoading((prev) => ({ ...prev, loadingAddUpdate: true }));
    const formData = new FormData();
    if (fileLogo || fileLogo === '') {
      formData.append('logo', fileLogo);
    }
    const dataCompanyInput = document.forms['settingData'];
    if (dataCompanyInput['companyName'].value) {
      formData.append('companyName', dataCompanyInput['companyName'].value);
    }
    if (dataCompanyInput['phoneNumber'].value || dataCompanyInput['phoneNumber'].value === '') {
      formData.append('phone', dataCompanyInput['phoneNumber'].value);
    }
    if (dataCompanyInput['zipCode'].value || dataCompanyInput['zipCode'].value === '') {
      formData.append('zipCode', dataCompanyInput['zipCode'].value);
    }
    if (dataCompanyInput['address'].value || dataCompanyInput['address'].value === '') {
      formData.append('address', dataCompanyInput['address'].value);
    }

    const result = await SettingCompany(formData);
    if (result) {
      mutate(URL_API_DETAIL_COMPANY, result, true);
      toast('success', UPDATE_SUCCESSFULLY);
      dispatch(setDataInitial({ ...dataInitial, companyName: result?.company?.companyName }));
      const dataSetting = JSON.parse(localStorage?.getItem('dataSetting'));
      localStorage.setItem(
        'dataSetting',
        JSON.stringify({ ...dataSetting, companyName: result?.company?.companyName })
      );
    } else toast('failed', UPDATE_FAIL);

    setLoading((prev) => ({ ...prev, loadingAddUpdate: false }));
    handleClearErrorMess();
  };

  const [showErrorFindAddress, setShowErrorFindAddress] = useState('');
  const [showErrorPhone, setShowErrorPhone] = useState('');
  const [showErrorZipcode, setShowErrorZipcode] = useState('');
  const getDataAddress = async () => {
    let search = false;
    await new Promise((resolve, reject) => {
      get(document.forms['settingData']['zipCode'].value, function (address) {
        search = true;

        if (address) {
          document.forms['settingData']['address'].value = `${address.prefecture ?? ''} ${address.city ?? ''} ${
            address.area ?? ''
          } ${address.street ?? ''}`;
          setShowErrorFindAddress('');
          resolve(address);
        }
      });

      // reject after 4 seconds
      setTimeout(() => {
        resolve('Operation timed out.');
      }, 4000);
    });

    if (!search) {
      setShowErrorFindAddress(CONSTANT.ADDRESS_NOT_FOUND);
    }
  };

  const [fileLogo, setFileLogo] = useState();
  const [urlLogo, setUrlLogo] = useState();
  const customizeInputFile = () => {
    const fileFields = document.querySelectorAll('.js-fileField');

    if (!fileFields.length) {
      return;
    }

    fileFields.forEach((fileField) => {
      const fileInput = fileField.querySelector('.fileField__input');
      const fileName = fileField.querySelector('.fileField__name');
      const fileLogo = fileField.querySelector('.logo_company');
      const clearButton = fileField.querySelector('.fileField__clearButton');
      let fileUrl; //  ファイルURL初期化

      if (data?.company?.logo) {
        fileUrl = data?.company?.logo;
        fileField.classList.add('is-fileSet');
        fileName.textContent = '';
        fileLogo.src = fileUrl;
        setUrlLogo(fileUrl);
        fileName.tabIndex = '0';
      }

      // ファイルが選択された場合
      fileInput.addEventListener('change', (event) => {
        if (fileInput.files.length) {
          fileField.classList.add('is-fileSet');
          fileLogo.src = URL.createObjectURL(event.target.files[0]);
          fileName.textContent = '';
          setUrlLogo(URL.createObjectURL(event.target.files[0]));
          setFileLogo(event.target.files[0]);
          fileName.tabIndex = '0'; // フォーカスできるように`tabindex`属性をセット
        }
      });

      // クリアボタンが押された場合
      clearButton.addEventListener('click', () => {
        fileInput.value = '';
        fileField.classList.remove('is-fileSet');
        fileName.textContent = '選択されていません';
        fileName.removeAttribute('tabindex');
      });
    });
  };

  const onCancel = () => {
    fillData(data?.company);
    customizeInputFile();
    handleClearErrorMess();
  };

  useEffect(() => {
    customizeInputFile();
  }, [data?.company]);

  const validateInput = () => {
    let validatedItem = true;
    // Zip code
    const trueZipcode = validateZipcode(document.forms['settingData']['zipCode'].value);
    if (!trueZipcode) {
      setShowErrorZipcode(CONSTANT.ZIP_CODE_FORMAT);
      validatedItem = false;
    }

    // phone Number
    const truePhone = validatePhoneNumber(document.forms['settingData']['phoneNumber'].value);

    if (!truePhone) {
      setShowErrorPhone(CONSTANT.PHONE_NUMBER_FORMAT);
      validatedItem = false;
    }

    return validatedItem;
  };
  // clear error message after submit success
  const handleClearErrorMess = () => {
    setShowErrorZipcode('');
    setShowErrorPhone('');
    setShowErrorFindAddress('');
    // reset message some field require
    validatesRef.current.companyName.setCustomValidity('');
  };

  return (
    <div className='panel'>
      {isLoading ? (
        <Loading />
      ) : (
        <div className='panel__body -large'>
          <form
            onKeyDown={handleKeyPress}
            name='settingData'
            className='form'
            onSubmit={(e) => handleSettingCompany(e)}
          >
            <table className='detailTable'>
              <tbody>
                <tr>
                  <th>会社ID</th>
                  <td>{dataSetting?.companyCode ?? dataLandingPage?.dataInitial?.companyCode}</td>
                </tr>
                <tr>
                  <th>
                    会社名<span className='badge -danger u-spacer-left1'>必須</span>
                  </th>
                  <td>
                    <input
                      required
                      type='text'
                      className='textfield'
                      name='companyName'
                      onInvalid={(e) => handleRequireInvalid(e)}
                      onChange={(e) => resetRequireValidate(e)}
                      ref={(e) => (validatesRef.current.companyName = e)}
                    />
                  </td>
                </tr>
                <tr>
                  <th>チャットアイコン</th>
                  <td>
                    <div className='fileField js-fileField' value='logo'>
                      <label className='button -secondary fileField__uploadButton'>
                        ファイルを選択
                        <input type='file' accept='.jpg, .jpeg, .gif, .png' name='logo' className='fileField__input' />
                      </label>
                      <p className='fileField__name'>選択されていません</p>
                      <Image className='logo_company' src={urlLogo} />
                      <button type='button' className='button -secondary fileField__clearButton'>
                        クリア
                      </button>
                    </div>
                    <p className='detailTable__note'>
                      ※応募者とチャットをする際に、アイコンとして使用されます。会社ロゴの設定がオススメです。
                    </p>
                  </td>
                </tr>
                <tr>
                  <th>会社電話番号</th>
                  <td>
                    <div className='form__inline'>
                      <input type='text' className='textfield -short' name='phoneNumber' />
                      {showErrorPhone && <span className='text-[red]'>{showErrorPhone}</span>}
                    </div>
                    <p className='detailTable__note'>※ハイフンなしで入力してください。</p>
                  </td>
                </tr>
                <tr>
                  <th>会社郵便番号</th>
                  <td>
                    <div className='form__inline'>
                      <input
                        type='text'
                        placeholder='郵便番号を入力してください'
                        className='textfield -short'
                        name='zipCode'
                        maxLength={8}
                        onChange={() => setShowErrorFindAddress('')}
                      />
                      <button type='button' onClick={() => getDataAddress()} className='button -secondary'>
                        住所自動入力
                      </button>
                      {showErrorFindAddress && <span className='text-[red]'>{showErrorFindAddress}</span>}
                      {showErrorZipcode && <span className='text-[red] customError'>{showErrorZipcode}</span>}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>会社住所</th>
                  <td>
                    <input type='text' name='address' className='textfield' />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className='u-spacer-top4'>
              <div className='button-wrapper -center'>
                <button onClick={() => onCancel()} type='button' className='button -default'>
                  キャンセル
                </button>
                <button type='submit' disabled={loading.loadingAddUpdate} className='button -primary'>
                  {loading.loadingAddUpdate && <i className='fa fa-spinner fa-spin mr-[10px]'></i>}保存
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};
export default Company;
