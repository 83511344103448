import React from 'react';
import BodyTableListResult from './common/BodyTableListResult';

const EmployeesBody = () => {

  return (
    <div>
      <div className="pageHeader">
        <h1 className="pageHeader__title">従業員管理</h1>
      </div>
      <BodyTableListResult />
    </div>
  );
};
export default EmployeesBody;
