import BodyTableListResult from './common/BodyTableListResult';
import React from 'react';
import FormParentApplicant from './FormParentApplicant';


//辞退
const ApplicantsDecline = () => {
  return (
    <div className='lang-ding-page'>
      <FormParentApplicant>
        <BodyTableListResult type={'applicantsDecline'} />
      </FormParentApplicant>
    </div>
  );
};
export default ApplicantsDecline;
