import { notification } from 'antd';
export const toast = (type = 'info', message = 'INFO', placement = 'top', description = '') => {
  notification.open({
    type,
    message,
    placement,
    description,
    duration: 2.5,
  });
};
