import React from 'react';
import BodyTableListResult from './common/BodyTableListResult';
import FormParentApplicant from './FormParentApplicant';

// 選考中
const ApplicantsBody = () => {
  return (
    <FormParentApplicant>
      <BodyTableListResult />
    </FormParentApplicant>
  );
};
export default ApplicantsBody;
