import React, { useState } from 'react';
import { closeModalOpen, resetRequireValidate, handleRequireInvalid } from '../../../../../common/FuntionCommon';
import { registerUserApplicant } from '../../../../../../services/RecruiterService';
import { useParams } from 'react-router-dom';
import { toast } from '../../../../../../utils/notification';
import * as CONSTANT from '../../../../../common/constant';

const ModalRegisterNewEmployee = ({ dataApplications }) => {
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  const handleFinish = async (e) => {
    e.preventDefault();
    setLoading(true);
    const dataUser = document.forms['dataUser'];
    const payload = {
      employeeJobId: dataUser['jobId'].value,
      employeeStatus: dataUser['status'].value,
      employeeCode: dataUser['idEmployee'].value,
      employeeCompanyEntranceDate: dataUser['dateCreate'].value,
    };
    const data = await registerUserApplicant(id, payload);
    if (data) {
      toast('success', CONSTANT.CREATE_SUCCESSFULLY);
    }
    setLoading(false);
    closeModalOpen(document.getElementById('employee-registration'));
    return;
  };
  return (
    <div className='modal' id='employee-registration'>
      <div className='modal__inner'>
        <div className='modal__content'>
          <form onSubmit={(e) => handleFinish(e)} name='dataUser' className='form'>
            <div className='modal__header'>
              <p className='modal__title'>従業員として登録</p>
            </div>
            <div className='modal__body'>
              <table className='detailTable'>
                <tbody>
                  <tr>
                    <th>
                      ステータス<span className='badge -danger u-spacer-left1'>必須</span>
                    </th>
                    <td>
                      <div className='radio-group'>
                        <label className='radio'>
                          <input type='radio' name='status' defaultChecked className='radio__input' value='在職中' />
                          <span className='radio__label'>在職中</span>
                        </label>
                        <label className='radio'>
                          <input type='radio' name='status' className='radio__input' value='休職中' />
                          <span className='radio__label'>休職中</span>
                        </label>
                        <label className='radio'>
                          <input type='radio' name='status' className='radio__input' value='退職済み' />
                          <span className='radio__label'>退職済み</span>
                        </label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      従業員ID<span className='badge -danger u-spacer-left1'>必須</span>
                    </th>
                    <td>
                      <input
                        required
                        onInvalid={(e) => handleRequireInvalid(e)}
                        onChange={(e) => resetRequireValidate(e)}
                        type='text'
                        className='textfield -short'
                        name='idEmployee'
                      />
                      <p className='detailTable__note'>※貴社のフォーマットに合わせてご記入ください</p>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      入社年月日<span className='badge -danger u-spacer-left1'>必須</span>
                    </th>
                    <td>
                      <input
                        required
                        onInvalid={(e) => handleRequireInvalid(e)}
                        onChange={(e) => resetRequireValidate(e)}
                        type='date'
                        className='textfield -short'
                        name='dateCreate'
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      応募した求人<span className='badge -danger u-spacer-left1'>必須</span>
                    </th>
                    <td>
                      <div className='form__inline'>
                        <select
                          required
                          onInvalid={(e) => handleRequireInvalid(e)}
                          onChange={(e) => resetRequireValidate(e)}
                          className='select'
                          name='jobId'
                        >
                          <option value={''}>選択してください</option>
                          {dataApplications?.map((item) => (
                            <option key={item?._id} value={item?.jobId}>
                              {item?.jobTitle}
                            </option>
                          ))}
                        </select>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='modal__footer'>
              <div className='button-wrapper -end'>
                <button type='button' className='button -default js-modal-close'>
                  キャンセル
                </button>
                <button type='submit' disabled={loading} className='button -primary'>
                  {loading && <i className='fa fa-spinner fa-spin mr-[10px]'></i>}登録
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className='modal__overlay'></div>
    </div>
  );
};
export default ModalRegisterNewEmployee;
