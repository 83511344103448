import axios from 'axios';
import queryString from 'query-string';
import { set } from 'lodash';
import { toast } from '../utils/notification';
import { URL_ERROR_PAGE } from '../routes/constantRouter';

const Axios = axios.create({
  baseURL: process.env.REACT_APP_API_URL + 'api/',
  paramsSerializer: (params) => queryString.stringify(params),
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});
Axios.interceptors.request.use((request) => {
  let jwtToken = JSON.parse(localStorage.getItem('token'));
  if (!jwtToken) {
    return request;
  }
  set(request, 'headers.Authorization', `Bearer ${jwtToken}`);
  return request;
});
Axios.interceptors.response.use(
  (response) => {
    if (response && response.data) {
      return response.data;
    }
    return response;
  },
  async (error) => {
    const errorResponse = error.response;
    if (errorResponse && errorResponse.status === 401) {
      localStorage.clear();
      window.location.replace('/');
    }
    if (errorResponse && errorResponse.status === 404) {
      window.location.replace(`${URL_ERROR_PAGE}`);
    }
    toast('error', errorResponse.data.message);
    Promise.reject(error).catch((error) => error);
    throw errorResponse;
  }
);

export default Axios;
