import React, { useState } from 'react';
import { toast } from '../../../../../../utils/notification';
import { inviteApplicant, inviteApplicantAgain } from '../../../../../../services/RecruiterService';
import * as CONSTANT from '../../../../../common/constant';
import { closeModalOpen } from '../../../../../common/FuntionCommon';

const ModalSubmitSendMail = ({
  mode,
  refetchContext,
  replicaId,
  jobTitle,
  jobId,
  applicantEmail,
  firstName,
  lastName,
}) => {
  const [loading, setLoading] = useState(false);

  const handleSendMail = async () => {
    setLoading(true);
    let data = '';
    if (mode === 'sendMailAgain') {
      data = await inviteApplicantAgain(replicaId, jobId);
    } else {
      data = await inviteApplicant(replicaId, jobId);
    }
    setLoading(false);
    if (data) {
      toast('success', CONSTANT.SEND_MAIL_SUCCESSFULLY);
      if (refetchContext) {
        refetchContext();
      }
    }
    closeModalOpen(document.getElementById('chat-invitation'));
  };
  return (
    <>
      <div className='modal' id='chat-invitation'>
        <div className='modal__inner'>
          <div className='modal__content'>
            <div className='modal__header'>
              <p className='modal__title'>応募者をチャットに招待</p>
            </div>
            <div className='modal__body'>
              <p>以下の内容で、招待メールが送られます。</p>
              <table className='detailTable u-spacer-top2'>
                <tbody>
                  <tr>
                    <th>応募した求人</th>
                    <td>{jobTitle}</td>
                  </tr>
                  <tr>
                    <th>氏名</th>
                    <td>{lastName + ' ' + firstName}</td>
                  </tr>
                  <tr>
                    <th>メールアドレス</th>
                    <td>{applicantEmail}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='modal__footer'>
              <div className='button-wrapper -end'>
                <button type='button' className='button -default js-modal-close'>
                  キャンセル
                </button>
                <button
                  onClick={() => handleSendMail()}
                  disabled={loading}
                  type='button'
                  className='button -primary js-modal-close'
                >
                  {loading && <i className='fa fa-spinner fa-spin mr-[10px]'></i>}送信
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className='modal__overlay'></div>
      </div>
    </>
  );
};
export default ModalSubmitSendMail;
