import React, { useEffect, useState } from 'react';
import HeaderSearchApplicants from './common/HeaderSearchApplicants';
import { getTotalApplicantForStatus } from '../../../../../services/RecruiterService';

const FormParentApplicant = ({ children }) => {
  const [dataAmount, setDataAmount] = useState();

  useEffect(() => {
    const getListAmount = async () => {
      const response = await getTotalApplicantForStatus();
      setDataAmount(response?.data);
    };
    getListAmount();
  }, []);

  return (
    <div>
      <HeaderSearchApplicants dataAmount={dataAmount} />
      {children}
    </div>
  );
};
export default FormParentApplicant;
