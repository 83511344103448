import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dataSlice, setTagNameSettingCompany } from '../../../../redux/reducer';
import Company from './Company/Company';
import Progress from './Progress/Progress';
import User from './User';
import { Link, useNavigate } from 'react-router-dom';
import { ROLE_VICE_ADMIN } from '../../../../common/constant';
import { URL_JOBS } from '../../../../../routes/constantRouter';

const SettingsCompany = () => {
  const [activeKeyValue, setActiveKeyValue] = useState('company');
  const dispatch = useDispatch();
  const role = JSON.parse(localStorage?.getItem('role'));
  const navigate = useNavigate();
  const tagValueActivity = useSelector(dataSlice);
  useEffect(() => {
    if (role === ROLE_VICE_ADMIN) {
      return navigate(URL_JOBS);
    }
  }, [window.location.pathname]);
  useEffect(() => {
    setActiveKeyValue(tagValueActivity.tagValueSettingCompany);
  }, []);
  const onChangeTag = (e) => {
    setActiveKeyValue(e);
    dispatch(setTagNameSettingCompany(e));
  };
  useEffect(() => {
    if (!window.location.pathname.includes('/settings')) {
      setActiveKeyValue('company');
      dispatch(setTagNameSettingCompany('company'));
    }
  }, [window.location.pathname]);
  const TabsActivity = {
    company: <Company />,
    user: <User />,
    progress: <Progress />,
  };

  return (
    <>
      <div className='pageHeader'>
        <h1 className='pageHeader__title'>設定</h1>
      </div>
      <div className='u-spacer-block2'>
        <ul className='tab'>
          <li className='tab__item'>
            <Link
              onClick={() => onChangeTag('company')}
              className={`tab__button ${activeKeyValue === 'company' ? 'is-active' : ''}`}
            >
              会社情報
            </Link>
          </li>
          <li className='tab__item'>
            <Link
              onClick={() => onChangeTag('user')}
              className={`tab__button ${activeKeyValue === 'user' ? 'is-active' : ''}`}
            >
              ユーザー設定
            </Link>
          </li>
          <li className='tab__item'>
            <Link
              onClick={() => onChangeTag('progress')}
              className={`tab__button ${activeKeyValue === 'progress' ? 'is-active' : ''}`}
            >
              進捗と結果
            </Link>
          </li>
        </ul>
      </div>
      {TabsActivity?.[activeKeyValue]}
    </>
  );
};
export default SettingsCompany;
