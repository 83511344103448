import React, { useEffect } from 'react';
import { GetListRecruitment, getDataProgress } from '../../../../../../services/RecruiterService';
import { APPLICANT_EXIT, CHATTING, JOIN_WAITING, NOT_YET_INVITE } from '../../../../../common/constant';
import useSWR from 'swr';
import { URL_API_COMPANY_PROGRESS, URL_API_GET_LIST_RECRUITMENT } from '../../../../../../services';

export const getListProgress = () => {
  const { data } = useSWR(URL_API_COMPANY_PROGRESS, getDataProgress, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  return data?.progress?.progress;
};

export const getListJobs = () => {
  const { data } = useSWR([1, 1000, {}, URL_API_GET_LIST_RECRUITMENT], GetListRecruitment, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  return data?.recruitments;
};

const SearchApplicants = ({ type, getListApplicants, setQuerySearch }) => {
  const listJobs = getListJobs();
  const listProgress = getListProgress();

  useEffect(() => {
    const toggleSearchAdvanced = () => {
      const expandButton = document.getElementById('js-searchExpandButton');

      if (!expandButton) {
        return;
      }

      expandButton.addEventListener('click', (event) => {
        event.target.closest('.search').classList.toggle('is-expanded');
      });
    };
    toggleSearchAdvanced();
  }, []);

  const handleSearch = async (e) => {
    e.preventDefault();
    const searchData = document?.forms['searchApplicant'];
    const appliedDateFrom = searchData['appliedDateFrom']?.value
      ? new Date(searchData['appliedDateFrom']?.value)
      : null;
    const appliedDateTo = searchData['appliedDateTo']?.value ? new Date(searchData['appliedDateTo']?.value) : null;
    const evaluationDateFrom = searchData['evaluationDateFrom']?.value
      ? new Date(searchData['evaluationDateFrom']?.value)
      : null;
    const evaluationDateTo = searchData['evaluationDateTo']?.value
      ? new Date(searchData['evaluationDateTo']?.value)
      : null;
    const interviewDateFrom = searchData['interviewDateFrom']?.value
      ? new Date(searchData['interviewDateFrom']?.value)
      : null;
    const interviewDateTo = searchData['interviewDateTo']?.value
      ? new Date(searchData['interviewDateTo']?.value)
      : null;

    const inviteChat = searchData['inviteChat']?.checked ? NOT_YET_INVITE : '';
    const sendingEmail = searchData['sendingEmail']?.checked ? JOIN_WAITING : '';
    const chatting = searchData['chatting']?.checked ? CHATTING : '';
    const stopChat = searchData['stopChat']?.checked ? APPLICANT_EXIT : '';
    const chatStatus = [inviteChat, sendingEmail, chatting, stopChat].filter(Boolean).join(',');

    const querySearch = {
      chatStatus: chatStatus,
      evaluationStatus: !type
        ? '選考中'
        : type === 'applicantsPass'
        ? '内定'
        : type === 'applicantsDecline'
        ? '辞退'
        : '不合格',
      name: searchData['name']?.value,
      progressStep: searchData['progress']?.value,
      jobId: searchData['jobId']?.value,
      ratingFrom: searchData['ratingFrom']?.value,
      ratingTo: searchData['ratingTo']?.value,
      appliedDateFrom: appliedDateFrom ? appliedDateFrom?.toISOString() : '',
      appliedDateTo: appliedDateTo ? appliedDateTo?.toISOString() : '',
      evaluationDateFrom: evaluationDateFrom ? evaluationDateFrom.toISOString() : '',
      evaluationDateTo: evaluationDateTo ? evaluationDateTo?.toISOString() : '',
      interviewDateFrom: interviewDateFrom ? interviewDateFrom?.toISOString() : '',
      interviewDateTo: interviewDateTo ? interviewDateTo?.toISOString() : '',
      showHiddenInList: searchData['hiddenInList']?.checked,
    };
    setQuerySearch(querySearch);
    await getListApplicants(querySearch, 1);
  };

  const handleClearSearch = async () => {
    document?.forms['searchApplicant'].reset();
  };

  return (
    <div className='panel search'>
      <form className='form' onSubmit={(e) => handleSearch(e)} name='searchApplicant'>
        {!type ? (
          <div className='search__condition'>
            <div className='search__row'>
              <fieldset className='search__group'>
                <legend className='form__label u-text-light'>チャット</legend>
                <div className='form__field'>
                  <div className='checkbox-wrapper'>
                    <label className='checkbox'>
                      <input
                        type='checkbox'
                        defaultChecked
                        className='checkbox__input'
                        name='inviteChat'
                        value={NOT_YET_INVITE}
                      />
                      <span className='checkbox__label'>招待する（未招待）</span>
                    </label>
                    <label className='checkbox'>
                      <input
                        type='checkbox'
                        defaultChecked
                        className='checkbox__input'
                        name='sendingEmail'
                        value={JOIN_WAITING}
                      />
                      <span className='checkbox__label'>参加待ち</span>
                    </label>
                    <label className='checkbox'>
                      <input
                        type='checkbox'
                        defaultChecked
                        className='checkbox__input'
                        name='chatting'
                        value={CHATTING}
                      />
                      <span className='checkbox__label'>チャット中</span>
                    </label>
                    <label className='checkbox'>
                      <input
                        type='checkbox'
                        defaultChecked
                        className='checkbox__input'
                        name='stopChat'
                        value={APPLICANT_EXIT}
                      />
                      <span className='checkbox__label'>退出済み</span>
                    </label>
                  </div>
                </div>
              </fieldset>
            </div>
            <div className='search__row -col3'>
              <fieldset className='search__group'>
                <legend className='form__label u-text-light'>氏名</legend>
                <div className='form__field'>
                  <input type='text' className='textfield' name='name' />
                </div>
              </fieldset>
              <fieldset className='search__group'>
                <legend className='form__label u-text-light'> 進捗</legend>
                <div className='form__field'>
                  <select className='select' name='progress'>
                    <option value=''>選択してください</option>
                    {listProgress?.map((item) => (
                      <>
                        {item?.enable && (
                          <option key={item?.step} value={item?.step}>
                            {item?.step}
                          </option>
                        )}
                      </>
                    ))}
                  </select>
                </div>
              </fieldset>
              <fieldset className='search__group'>
                <legend className='form__label u-text-light'>応募した求人</legend>
                <div className='form__field'>
                  <select className='select' name='jobId'>
                    <option value=''>選択してください</option>
                    {listJobs?.map((item) => (
                      <option key={item?._id} value={item?._id}>
                        {item?.jobTitle}
                      </option>
                    ))}
                  </select>
                </div>
              </fieldset>
            </div>
            <div className='search__row -center'>
              <button type='button' className='search__more' id='js-searchExpandButton'>
                詳細検索
              </button>
            </div>
            <div className='search__detail'>
              <div className='search__row -col3'>
                <fieldset className='search__group'>
                  <legend className='form__label u-text-light'>
                    評価(<span className='star'></span>)
                  </legend>
                  <div className='form__field'>
                    <div className='form__inline'>
                      <input type='text' className='textfield' name='ratingFrom' />
                      〜
                      <input type='text' className='textfield' name='ratingTo' />
                    </div>
                  </div>
                </fieldset>
                <fieldset className='search__group'>
                  <legend className='form__label u-text-light'>応募日</legend>
                  <div className='form__field'>
                    <div className='form__inline'>
                      <input type='date' className='textfield' name='appliedDateFrom' />
                      〜
                      <input type='date' className='textfield' name='appliedDateTo' />
                    </div>
                  </div>
                </fieldset>
                <fieldset className='search__group'>
                  <legend className='form__label u-text-light'>面接日</legend>
                  <div className='form__field'>
                    <div className='form__inline'>
                      <input type='date' className='textfield' name='interviewDateFrom' />
                      〜
                      <input type='date' className='textfield' name='interviewDateTo' />
                    </div>
                  </div>
                </fieldset>
              </div>
              <div className='search__row'>
                <fieldset className='search__group'>
                  <legend className='form__label u-text-light'>表示設定</legend>
                  <div className='form__field'>
                    <label className='checkbox'>
                      <input type='checkbox' className='checkbox__input' name='hiddenInList' />
                      <span className='checkbox__label'>非表示中の応募者を表示</span>
                    </label>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
        ) : (
          <div className='search__condition'>
            <div className='search__row'>
              <fieldset className='search__group'>
                <legend className='form__label u-text-light'>チャット</legend>
                <div className='form__field'>
                  <div className='checkbox-wrapper'>
                    <label className='checkbox'>
                      <input
                        type='checkbox'
                        defaultChecked
                        className='checkbox__input'
                        name='inviteChat'
                        value={NOT_YET_INVITE}
                      />
                      <span className='checkbox__label'>招待する（未招待）</span>
                    </label>
                    <label className='checkbox'>
                      <input
                        type='checkbox'
                        defaultChecked
                        className='checkbox__input'
                        name='sendingEmail'
                        value={JOIN_WAITING}
                      />
                      <span className='checkbox__label'>参加待ち</span>
                    </label>
                    <label className='checkbox'>
                      <input
                        type='checkbox'
                        defaultChecked
                        className='checkbox__input'
                        name='chatting'
                        value={CHATTING}
                      />
                      <span className='checkbox__label'>チャット中</span>
                    </label>
                    <label className='checkbox'>
                      <input
                        type='checkbox'
                        defaultChecked
                        className='checkbox__input'
                        name='stopChat'
                        value={APPLICANT_EXIT}
                      />
                      <span className='checkbox__label'>退出済み</span>
                    </label>
                  </div>
                </div>
              </fieldset>
            </div>
            <div className='search__row -col3'>
              <fieldset className='search__group'>
                <legend className='form__label u-text-light'>氏名</legend>
                <div className='form__field'>
                  <input type='text' name='name' className='textfield' />
                </div>
              </fieldset>
              <fieldset className='search__group'>
                <legend className='form__label u-text-light'>応募した求人</legend>
                <div className='form__field'>
                  <select className='select' name='jobId'>
                    <option value=''>選択してください</option>
                    {listJobs?.map((item) => (
                      <option key={item?._id} value={item?._id}>
                        {item?.jobTitle}
                      </option>
                    ))}
                  </select>
                </div>
              </fieldset>
              <fieldset className='search__group'>
                <legend className='form__label u-text-light'>
                  {type === 'applicantsPass' ? '内定日' : type === 'applicantsDecline' ? '辞退日' : '不合格日'}
                </legend>
                <div className='form__field'>
                  <div className='form__inline'>
                    <input type='date' className='textfield' name='evaluationDateFrom' />
                    〜
                    <input type='date' className='textfield' name='evaluationDateTo' />
                  </div>
                </div>
              </fieldset>
            </div>
            <div className='search__row -center'>
              <button type='button' className='search__more' id='js-searchExpandButton'>
                詳細検索
              </button>
            </div>
            <div className='search__detail'>
              <div className='search__row -col3'>
                <fieldset className='search__group'>
                  <legend className='form__label u-text-light'>
                    評価(<span className='star'></span>)
                  </legend>
                  <div className='form__field'>
                    <div className='form__inline'>
                      <input type='text' className='textfield' name='ratingFrom' />
                      〜
                      <input type='text' className='textfield' name='ratingTo' />
                    </div>
                  </div>
                </fieldset>
                <fieldset className='search__group'>
                  <legend className='form__label u-text-light'>応募日</legend>
                  <div className='form__field'>
                    <div className='form__inline'>
                      <input type='date' className='textfield' name='appliedDateFrom' />
                      〜
                      <input type='date' className='textfield' name='appliedDateTo' />
                    </div>
                  </div>
                </fieldset>
                {type !== 'applicantsPass' && (
                  <fieldset className='search__group'>
                    <legend className='form__label u-text-light'>進捗</legend>
                    <div className='form__field'>
                      <select className='select' name='progress'>
                        <option value=''>選択してください</option>
                        {listProgress?.map((item) => (
                          <>
                            {item?.enable && (
                              <option key={item?.step} value={item?.step}>
                                {item?.step}
                              </option>
                            )}
                          </>
                        ))}
                      </select>
                    </div>
                  </fieldset>
                )}
              </div>
              <div className='search__row'>
                <fieldset className='search__group'>
                  <legend className='form__label u-text-light'>表示設定</legend>
                  <div className='form__field'>
                    <label className='checkbox'>
                      <input type='checkbox' className='checkbox__input' name='hiddenInList' />
                      <span className='checkbox__label'>非表示中の応募者を表示</span>
                    </label>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
        )}
        <div className='search__action'>
          <button type='button' className='button -default -reset search__resetButton' onClick={handleClearSearch}>
            検索条件をリセット
          </button>
          <button type='submit' className='button -accent search__primaryButton'>
            検索
          </button>
        </div>
      </form>
    </div>
  );
};
export default SearchApplicants;
