import { Button, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { GetDetailRecruitment, UpdateRecruitment } from '../../../../../services/RecruiterService';
import { toast } from '../../../../../utils/notification';
import * as CONSTANT from '../../../../common/constant';

const Note = ({ jobId }) => {
  const [infoJob, setInfoJob] = useState({});
  const [memoData, setMemoData] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getDataJobDetail = async () => {
      if (jobId) {
        const data = await GetDetailRecruitment([jobId]);
        if (data) {
          setInfoJob(data?.data);
          setMemoData(data?.data?.jobMemo);
        }
        setLoading(false);
      }
    };
    getDataJobDetail();
  }, [jobId]);

  const updateJobMemo = async () => {
    const payload = { ...infoJob, jobMemo: memoData };
    const data = await UpdateRecruitment(jobId, payload);
    if (data) {
      toast('success', CONSTANT.UPDATE_SUCCESSFULLY);
    }
  };
  return (
    <section>
      <h3 className='text-[16px]'>求人メモ</h3>
      <p className='text-[#788087] my-2'>
        求人ごとに共通のメモを残すことができます。 よく使用する返信内容など、ご自由にお使いください。
      </p>
      <Input.TextArea
        style={{
          resize: 'none',
        }}
        className='!h-[430px] mb-3'
        value={memoData ?? ''}
        onChange={(e) => setMemoData(e.target.value)}
      />
      <Button className='button-primary float-right' type='primary' htmlType='submit' onClick={updateJobMemo}>
        保存
      </Button>
    </section>
  );
};
export default Note;
