import React, { useEffect, useState } from 'react';
import PaginationCustom from '../../../../../template/Pagination';
import { URL_APPLICANT_NEW } from '../../../../../../routes/constantRouter';
import { useNavigate, useParams } from 'react-router-dom';
import { getListApplicantForJob } from '../../../../../../services/RecruiterService';
import { LIMIT_PAGE_DEFAULT, NOT_YET_INVITE, NO_DATA, TYPE_ASC, TYPE_DESC } from '../../../../../common/constant';
import { displayModal, getAgeUser, onHandleChatNewTab, setSex } from '../../../../../common/FuntionCommon';
import ModalSubmitSendMail from '../../Applicants/common/ModalSubmitSendMail';
import moment from 'moment';
import { setDataRouterBackButton } from '../../../../../redux/reducer';
import { useDispatch } from 'react-redux';

const ApplicantJobDetail = ({ dataDetail }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const onChangePage = (page) => {
    setCurrentPage(page);
  };
  const [dataList, setDataList] = useState();
  const [pagination, setPagination] = useState();
  const { id } = useParams();
  const getDataApplicant = async (valueSearch = { sortField: 'appliedDate', sortOrder: TYPE_DESC }) => {
    const data = await getListApplicantForJob(id, currentPage, LIMIT_PAGE_DEFAULT, valueSearch);
    if (data) {
      setPagination(data?.data?.pageInfo);
      setDataList(data?.data?.replicas);
    }
  };
  useEffect(() => {
    getDataApplicant();
  }, [id, currentPage]);

  useEffect(() => {
    displayModal();
  });

  const handleNavigateApplicant = (e) => {
    e.preventDefault();
    navigate(URL_APPLICANT_NEW, {
      state: {
        jobId: id,
        jobCode: dataDetail?.jobCode,
      },
    });
    dispatch(setDataRouterBackButton({ from_url: 'job', jobId: id }));
  };

  const [sortApplyDateType, setSortApplyDateType] = useState(TYPE_DESC);
  const [sortInterviewDateType, setSortInterviewDateType] = useState(TYPE_DESC);
  const handleSortDateCreate = () => {
    const typeSort = sortApplyDateType === TYPE_DESC ? TYPE_ASC : TYPE_DESC;
    setSortApplyDateType(typeSort);
    const paramSearch = { sortField: 'appliedDate', sortOrder: typeSort };
    getDataApplicant(paramSearch);
  };

  const handleSortDateInterView = () => {
    const typeSort = sortInterviewDateType === TYPE_DESC ? TYPE_ASC : TYPE_DESC;
    setSortInterviewDateType(typeSort);
    const paramSearch = { sortField: 'interviewDateFrom', sortOrder: typeSort };
    getDataApplicant(paramSearch);
  };

  const [dataDetailApplicant, setDataDetailApplicant] = useState();
  return (
    <div className='panel'>
      <div className='panel__body'>
        <div className='dataList'>
          <div className='dataList__header -center'>
            {dataList?.length > 0 && (
              <PaginationCustom currentPage={currentPage} onChangePage={onChangePage} pagination={pagination} />
            )}
            <div className='dataList__headerAction'>
              <button onClick={(e) => handleNavigateApplicant(e)} className='button -outline-primary -add'>
                新規登録
              </button>
            </div>
          </div>
          <div className='dataList__body'>
            <table className='dataList__table -jobApplicants'>
              <thead>
                <tr>
                  <th data-column='entryDate' className='dataList__tableSort'>
                    <button
                      type='button'
                      onClick={handleSortDateCreate}
                      className={`dataList__tableSortButton ${sortApplyDateType === TYPE_ASC ? 'sortAsc' : ''}`}
                      data-sort='false'
                    >
                      応募日
                    </button>
                  </th>
                  <th data-column='name'>応募者</th>
                  <th data-column='chat'>チャット</th>
                  <th data-column='status'>ステータス</th>
                  <th data-column='progress'>進捗 / 結果</th>
                  <th data-column='interviewDate' className='dataList__tableSort'>
                    <button
                      type='button'
                      onClick={handleSortDateInterView}
                      className={`dataList__tableSortButton ${sortInterviewDateType === TYPE_ASC ? 'sortAsc' : ''}`}
                      data-sort='false'
                    >
                      面接日
                    </button>
                  </th>
                  <th data-column='detail'></th>
                </tr>
              </thead>
              <tbody>
                {dataList?.map((item) => (
                  <>
                    <tr key={item?._id}>
                      <td>{moment(item?.application?.appliedDate).format('YYYY/MM/DD')}</td>
                      <td>
                        <>
                          <div className='dataList__tableMainText'>{item?.lastName + ' ' + item?.firstName}</div>
                          <p className='dataList__tableSubText'>
                            {`${getAgeUser({
                              fullYear: +item?.dateOfBirth_YYYY,
                              month: +item?.dateOfBirth_MM,
                              day: +item?.dateOfBirth_DD,
                            })}歳 /${setSex(item?.sex)} `}{' '}
                            {item?.application?.rating && (
                              <span className='star u-spacer-left1'>{item?.application?.rating}</span>
                            )}
                          </p>
                        </>
                      </td>
                      <td>
                        <div className='dataList__tableChatButton'>
                          {item?.application?.applyGroupId && item?.application?.chatStatus !== NOT_YET_INVITE ? (
                            <button
                              onClick={() =>
                                onHandleChatNewTab(item?.application?.applyGroupId, item?.application?.jobId)
                              }
                              className={`button -outline-primary -chat -small ${
                                item?.application?.chatStatus == '退出済み' ||
                                item?.application?.chatStatus == 'アカウント自分削除'
                                  ? '!bg-[#d3d4d4]'
                                  : '!bg-transparent'
                              }`}
                              target='_blank'
                            >
                              {item?.application?.chatStatus}
                            </button>
                          ) : (
                            <button
                              onClick={() => setDataDetailApplicant(item)}
                              data-modal-target='chat-invitation'
                              className='button -outline-primary -mail -small'
                            >
                              招待する
                            </button>
                          )}
                        </div>
                      </td>
                      <td>
                        <div
                          className={`statusLight ${
                            item?.application?.evaluationStatus === '選考中'
                              ? '-success'
                              : item?.application?.evaluationStatus === '内定'
                              ? '-info'
                              : '-neutral'
                          }`}
                        >
                          {item?.application?.evaluationStatus}
                        </div>
                      </td>
                      <td>
                        {item?.application?.progress.length > 0
                          ? `${item?.application?.progress?.[item?.application?.progress.length - 1]?.step}/${
                              item?.application?.progress?.[item?.application?.progress.length - 1]?.result
                            }`
                          : null}
                      </td>
                      <td>
                        {item?.application?.progress?.[item?.application?.progress.length - 1]?.interviewStart
                          ? `${moment(
                              item?.application?.progress?.[item?.application?.progress.length - 1]?.interviewStart
                            ).format('YYYY/MM/DD HH:mm')}-${
                              item?.application?.progress?.[item?.application?.progress.length - 1]?.interviewEnd
                                ? moment(
                                    item?.application?.progress?.[item?.application?.progress.length - 1]?.interviewEnd
                                  ).format('HH:mm')
                                : ''
                            }`
                          : null}
                      </td>
                      <td>
                        <button
                          onClick={() => (
                            navigate(`/applicants-detail-basic/${item?._id}`),
                            dispatch(setDataRouterBackButton({ from_url: 'job', jobId: id }))
                          )}
                          className='button -primary -small'
                        >
                          詳細
                        </button>
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>
          <div className='dataList__footer'>
            {dataList?.length > 0 ? (
              <PaginationCustom
                currentPage={currentPage}
                onChangePage={onChangePage}
                pagination={pagination}
                type='top'
              />
            ) : (
              <div className='w-fit m-auto text-[#d3d4d4]'>{NO_DATA}</div>
            )}
          </div>
          <ModalSubmitSendMail
            mode={'sendMailInApplicantByJob'}
            refetchContext={getDataApplicant}
            jobTitle={dataDetailApplicant?.application.jobTitle ?? dataDetail.jobTitle}
            jobId={dataDetailApplicant?.application.jobId}
            replicaId={dataDetailApplicant?._id}
            applicantEmail={dataDetailApplicant?.email}
            firstName={dataDetailApplicant?.firstName}
            lastName={dataDetailApplicant?.lastName}
          />
        </div>
      </div>
    </div>
  );
};
export default ApplicantJobDetail;
