export const urlServer = process.env.REACT_APP_API_URL;
export const currentYear = new Date().getFullYear();
export const ROLE_ADMIN = 'kanri';
export const ROLE_VICE_ADMIN = 'sub_kanri';
export const ROLE_CHAT_ONLY = 'chat_only';
export const CHAT_TYPE_PUBLIC = 'apply';
export const CHAT_TYPE_ANONYMOUS = 'contact';
export const AUTO_LOGOUT_TIME = 15 * 60 * 1000; // auto logout after 15 minutes

// file type
export const TYPE_PDF_CV = 'pdfCV';
export const TYPE_PDF_WORKHISTORY = 'pdfWorkHistory';
//
export const APPLICANT_SETTING = 'applicant';
export const EMPLOYEE_SETTING = 'employee';

// User side
export const USER_SIDE_ANONYMOUS = 'Anonymous';
export const USER_SIDE_RECRUITER = 'Recruiter';
export const USER_SIDE_APPLICANT = 'ApplicantUser';

export const ACTION_ADD_NEW = 'create';
export const ACTION_EDIT = 'edit';
export const ACTION_PROGRESS_FLEXIBLE = 'flexible';
export const MESSAGE_TYPE_INTERVIEW = 'interview';

// Notification
export const NOTI_INTERVIEW = '面接日時を設定しました';
export const NOTI_PDF_CV = '応募者が履歴書を送りました';
export const NOTI_PDF_WORKHISTORY = '応募者が職務経歴書を送りました';


// chat status
export const NOT_YET_INVITE = '招待する'; // Meaning the applyGroup not yet existed
export const JOIN_WAITING = '参加待ち';
export const CHATTING = 'チャット中';
export const APPLICANT_EXIT = '退出済み';
export const COMPANY_EXIT = '企業側が退出済み'; // Redundant
export const APPLICANT_DELETED_ACCOUNT = 'アカウント自分削除';

export const LIMIT_PAGE_DEFAULT = 20;
//status chat
export const CHATTING_STATUS = 'chatting';
export const CHAT_PENDING_STATUS = 'chat_pending';
export const NEW_CHAT_PENDING_STATUS = 'new_chat_pending';

// type sort order
export const TYPE_DESC = 'desc';
export const TYPE_ASC = 'asc';

//
export const NO_DATA = 'データがありません';

// message_error validate
export const VALIDATE_DATE = '募集開始日は募集終了日より後に設定することはできません。';
export const ADDRESS_NOT_FOUND = '該当の住所が見つかりませんでした';
export const ZIP_CODE_FORMAT = '郵便番号の形式が正しくありません。';
export const ERROR_EMAIL = 'メールの形式が正しくありません';
export const ERROR_URL = 'URLの形式が正しくありません';
export const PHONE_NUMBER_FORMAT = '電話番号の形式が正しくありません。';
export const PLEASE_CHECK_INPUT = '再度入力項目をご確認下さい';

export const START_END_TIME = '開始時刻は終了時刻を上回ることはできません。';
export const HOUR_LESS_THAN_24 = '時間は24を超えることはできません。';
export const MINUTE_LESS_THAN_60 = '分は60を超えることはできません。';
export const MINUTE_SELECT_AFTER_HOUR = '分を選択する前に時間を選択する必要があります';

// Password rule error
export const PASSWORD_RULE = 'パスワードは半角で文字と数字を含めて最小８桁で入力してください。';
export const PASSWORD_CONFIRM_ERROR = 'パスワードが一致しません';

// Toast result

export const RESET_PASSWORD_SUCCESSFULLY = '正確にパスワードがリセットされました';
export const CREATE_SUCCESSFULLY = '正確に登録されました';
export const UPDATE_SUCCESSFULLY = '正確に更新されました';
export const COPY_SUCCESSFULLY = '正確に反映されました';
export const LOGIN_SUCCESSFULLY = '正確にログインされました';
export const DELETE_SUCCESSFULLY = '正確に削除されました';
export const VERIFY_SUCCESSFULLY = '正確にアカウントが認証されました';
export const SETTING_SUCCESSFULLY = '正確に設定されました';
export const CHANGE_PASSWORD_SUCCESSFULLY = 'パスワード変更されました';
export const SEND_MAIL_SUCCESSFULLY = 'メールが送信されました';
export const RESEND_MAIL_SUCCESSFULLY = 'メールが再送信されました';

export const FAIL = '正しく操作が完了しません';
export const COPY_FAIL = '正しく反映されませんでした';
export const UPDATE_FAIL = '正しく更新されませんでした';

export const LIST_HR_NULL = 'すべての担当者を削除することはできません';
