/* eslint-disable import/namespace */
import Axios from './baseApiService';
import * as URL from '.';
import { TYPE_PDF_CV } from '../components/common/constant';
export const RecruiterLogin = async (email, password) => {
  try {
    const data = await Axios.post(URL.URL_API_RECRUITER_LOGIN, {
      email,
      password,
    });
    return data;
  } catch (error) {
    if (error?.data?.error_code === 'MAIL_NOT_VERIFIED') {
      return error?.data?.error_code;
    }
    return;
  }
};
export const logOut = async () => {
  try {
    const data = await Axios.post(URL.URL_API_LOGOUT, {});
    return data;
  } catch (error) {
    return;
  }
};
export const RecruiterRegister = async (email, password) => {
  try {
    const data = await Axios.post(URL.URL_API_RECRUITER_REGISTER, {
      email,
      password,
    });
    return data;
  } catch (error) {
    return;
  }
};
export const RecruiterVerifyOTP = async (email, OTP) => {
  try {
    const data = await Axios.post(URL.URL_API_RECRUITER_VERIFY_OTP, {
      email,
      OTP,
    });
    return data;
  } catch (error) {
    return;
  }
};
export const RecruiterResendOTP = async (email) => {
  try {
    const data = await Axios.post(URL.URL_API_RECRUITER_RESEND_OTP, {
      email,
    });
    return data;
  } catch (error) {
    return;
  }
};
export const RecruiterForgotPW = async (email) => {
  try {
    const data = await Axios.post(URL.URL_API_RECRUITER_FORGOT_PW, {
      email,
    });
    return data;
  } catch (error) {
    return;
  }
};
export const RecruiterResetPassword = async (payload) => {
  try {
    const data = await Axios.post(URL.URL_API_RECRUITER_RESET_PW, payload);
    return data;
  } catch (error) {
    return;
  }
};

export const RecruiterSettingPasswordFirstTime = async (newPassword) => {
  try {
    const data = await Axios.post(URL.URL_API_RECRUITER_SET_PASSWORD_FIRSTTIME, { newPassword });
    return data;
  } catch (error) {
    return;
  }
};

export const initialSetting = async (payload) => {
  try {
    const data = await Axios.post(URL.URL_API_INITIAL_SETTINGS, payload);
    return data;
  } catch (error) {
    return;
  }
};
export const changNewPassword = async (currentPassword, newPassword) => {
  try {
    const data = await Axios.post(URL.URL_API_CHANGE_NEW_PASSWORD, {
      currentPassword,
      newPassword,
    });
    return data;
  } catch (error) {
    return;
  }
};
//jobs
export const CreateNewRecruitment = async (payload) => {
  try {
    const data = await Axios.post(URL.URL_API_CREATE_NEW_RECRUITMENT, payload);
    return data;
  } catch (error) {
    return;
  }
};
export const UpdateRecruitment = async (id, payload) => {
  try {
    const data = await Axios.put(URL.URL_API_UPDATE_RECRUITMENT + id, payload);
    return data;
  } catch (error) {
    return;
  }
};
export const GetListRecruitment = async ([page, limit, querySearch, url = URL.URL_API_GET_LIST_RECRUITMENT]) => {
  try {
    const skip = (page - 1) * limit;
    const params = { skip, limit, ...querySearch };
    const data = await Axios.get(url, { params });
    return data;
  } catch (error) {
    return;
  }
};
export const GetDetailRecruitment = async ([id, url = URL.URL_API_GET_DETAIL_RECRUITMENT]) => {
  try {
    const data = await Axios.get(url + id);
    return data;
  } catch (error) {
    return;
  }
};

export const deleteJob = async (id) => {
  try {
    const data = await Axios.delete(URL.URL_API_DELETE_RECRUITMENT + id);
    return data;
  } catch (error) {
    return;
  }
};

//setting
export const CreateNewUser = async (payload) => {
  try {
    const data = await Axios.post(URL.URL_API_CREATE_NEW_USER, payload);
    return data;
  } catch (error) {
    return;
  }
};
export const GetListHr = async ([page, limit, url = URL.URL_API_GET_LIST_HR]) => {
  try {
    const data = await Axios.get(url + '?limit=' + limit + '&skip=' + (page - 1) * limit);
    return data;
  } catch (error) {
    return;
  }
};
export const GetDetailHr = async (id) => {
  try {
    const data = await Axios.get(URL.URL_API_GET_DETAIL_HR + id);
    return data;
  } catch (error) {
    return;
  }
};

export const UpdateHrUser = async (id, payload) => {
  try {
    const data = await Axios.put(URL.URL_API_UPDATE_HR + id, payload);
    return data;
  } catch (error) {
    return;
  }
};
export const resendMailUser = async (id) => {
  try {
    const data = await Axios.put(URL.URL_API_RESEND_EMAIL + id);
    return data;
  } catch (error) {
    return;
  }
};
export const SettingCompany = async (payload) => {
  try {
    const data = await Axios.post(URL.URL_API_SETTING_COMPANY, payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return data;
  } catch (error) {
    return;
  }
};
export const getDataDetailCompany = async (url = URL.URL_API_DETAIL_COMPANY) => {
  try {
    const data = await Axios.get(url);
    return data;
  } catch (error) {
    return;
  }
};
export const getDataProgress = async (url = URL.URL_API_COMPANY_PROGRESS) => {
  try {
    const data = await Axios.get(url);
    return data;
  } catch (error) {
    return;
  }
};
export const createDataProgress = async (payload) => {
  try {
    const data = await Axios.put(URL.URL_API_COMPANY_PROGRESS, payload);
    return data;
  } catch (error) {
    return;
  }
};
export const getDataDetailProgress = async (id) => {
  try {
    const data = await Axios.get(URL.URL_API_COMPANY_PROGRESS + id);
    return data;
  } catch (error) {
    return;
  }
};
export const updateDataProgress = async (id, payload) => {
  try {
    const data = await Axios.put(URL.URL_API_COMPANY_PROGRESS + id, payload);
    return data;
  } catch (error) {
    return;
  }
};
export const updateListProgress = async (payload) => {
  try {
    const data = await Axios.put(URL.URL_API_COMPANY_PROGRESS_UPDATE_ODER, payload);
    return data;
  } catch (error) {
    return;
  }
};

// applicant
export const CreateNewApplicant = async (payload) => {
  try {
    const data = await Axios.post(URL.URL_API_CREATE_APPLICANT, payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return data;
  } catch (error) {
    return;
  }
};
export const GetListApplicants = async (querySearch, page, limit) => {
  try {
    const skip = (page - 1) * limit;
    const params = { skip, limit, ...querySearch };
    const data = await Axios.get(URL.URL_API_GET_ALL_APPLICANT, { params });
    return data;
  } catch (error) {
    return;
  }
};

export const GetDetailApplicants = async (id) => {
  try {
    const data = await Axios.get(URL.URL_API_DETAIL_APPLICANT + id);
    return data;
  } catch (error) {
    return;
  }
};

export const GetUrlPdfForApplicant = async (applicantId, typePdf) => {
  try {
    const url =
      typePdf === TYPE_PDF_CV
        ? URL.URL_API_GET_URL_PDF_CV_FOR_APPLICANT
        : URL.URL_API_GET_URL_PDF_WORK_HISTORY_FOR_APPLICANT;
    const response = await Axios.get(url + applicantId, { responseType: 'blob' });
    return response;
  } catch (error) {
    return;
  }
};

export const GetUrlPdfForEmployee = async (applicantId, typePdf) => {
  try {
    const url =
      typePdf === TYPE_PDF_CV
        ? URL.URL_API_GET_URL_PDF_CV_FOR_EMPLOYEE
        : URL.URL_API_GET_URL_PDF_WORK_HISTORY_FOR_EMPLOYEE;
    const response = await Axios.get(url + applicantId, { responseType: 'blob' });
    return response;
  } catch (error) {
    return;
  }
};

export const GetUrlPdfForPreviewChat = async (applyGroupId, typePdf) => {
  try {
    const url =
      typePdf === TYPE_PDF_CV
        ? URL.URL_API_GET_URL_PDF_CV_FOR_CHAT_PAGE
        : URL.URL_API_GET_URL_PDF_WORK_HISTORY_FOR_CHAT_PAGE;
    const response = await Axios.get(url + applyGroupId, { responseType: 'blob' });
    return response;
  } catch (error) {
    return;
  }
};

export const UpdateApplicants = async (id, payload) => {
  try {
    const data = await Axios.put(URL.URL_API_UPDATE_APPLICANT + id, payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return data;
  } catch (error) {
    return;
  }
};

export const inviteApplicant = async (id, jobId) => {
  try {
    const data = await Axios.post(URL.URL_API_INVITE_APPLICANT, {
      replicaId: id,
      jobId: jobId,
    });
    return data;
  } catch (error) {
    return;
  }
};
export const inviteApplicantAgain = async (replicaId, jobId) => {
  try {
    const data = await Axios.post(URL.URL_API_INVITE_APPLICANT_AGAIN, {
      replicaId,
      jobId,
    });
    return data;
  } catch (error) {
    return;
  }
};
export const reflectPdfCv = async (messageId) => {
  try {
    const data = await Axios.put(URL.URL_API_REFLECT_PDF_CV, {
      messageId: messageId,
    });
    return data;
  } catch (error) {
    return;
  }
};
export const reflectPdfWorkHistory = async (messageId) => {
  try {
    const data = await Axios.put(URL.URL_API_REFLECT_PDF_WORK_HISTORY, {
      messageId: messageId,
    });
    return data;
  } catch (error) {
    return;
  }
};
export const reflectApplicantInfo = async (groupId) => {
  try {
    const data = await Axios.post(URL.URL_API_REFLECT_APPLICANT_INFO, {
      applyGroupId: groupId,
    });
    return data;
  } catch (error) {
    return;
  }
};
export const HandleSetEvaluateApplicant = async (id, payload) => {
  try {
    const data = await Axios.put(URL.URL_API_EVALUATE + id, payload);
    return data;
  } catch (error) {
    return;
  }
};
export const getListApplicantForJob = async (id, page, limit, querySearch) => {
  try {
    const skip = (page - 1) * limit;
    const params = { skip, limit, ...querySearch };
    const data = await Axios.get(URL.URL_API_LIST_APPLICANT_JOB + id, { params });
    return data;
  } catch (error) {
    return;
  }
};
export const addProgressForApplicant = async (id, payload) => {
  try {
    const data = await Axios.post(URL.URL_API_ADD_PROGRESS_FOR_APPLICANT + id, payload);
    return data;
  } catch (error) {
    return;
  }
};
export const deleteProgressStep = async (replicaId, payload) => {
  try {
    const data = await Axios.delete(URL.URL_API_DELETE_PROGRESS_FOR_APPLICANT + replicaId, {
      data: { progressId: payload },
    });
    return data;
  } catch (error) {
    return;
  }
};
export const updateProgressForApplicant = async (id, payload) => {
  try {
    const data = await Axios.put(URL.URL_API_ADD_PROGRESS_FOR_APPLICANT + id, payload);
    return data;
  } catch (error) {
    return;
  }
};
export const registerUserApplicant = async (id, payload) => {
  try {
    const data = await Axios.post(`${URL.URL_API_REGISTER_USER_APPLICANT}/${id}`, payload);
    return data;
  } catch (error) {
    return;
  }
};
export const changeListHrChat = async (id, payload) => {
  try {
    const data = await Axios.put(URL.URL_API_UPDATE_HR_FOR_GROUP_CHAT + id, payload);
    return data;
  } catch (error) {
    return;
  }
};

//employee
export const GetListEmployees = async (querySearch, page, limit) => {
  try {
    const skip = (page - 1) * limit;
    const params = { skip, limit, ...querySearch };
    const data = await Axios.get(URL.URL_API_GET_ALL_EMPLOYEE, { params });
    return data;
  } catch (error) {
    return;
  }
};

export const UpdateEmployee = async (id, payload) => {
  try {
    const data = await Axios.put(URL.URL_API_UPDATE_EMPLOYEE + id, payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return data;
  } catch (error) {
    return;
  }
};

export const deleteEmployee = async (id) => {
  try {
    const data = await Axios.delete(URL.URL_API_DELETE_EMPLOYEE + id);
    return data;
  } catch (error) {
    return;
  }
};

export const GetDetailEmployee = async (id) => {
  try {
    const data = await Axios.get(URL.URL_API_DETAIL_EMPLOYEE + id);
    return data;
  } catch (error) {
    return;
  }
};

export const getTotalApplicantForStatus = async (url = URL.URL_API_GET_TOTAL_DATA_APPLICANT) => {
  try {
    const data = await Axios.get(url);
    return data;
  } catch (err) {
    return;
  }
};
export const getTotalRecuiterForStatus = async () => {
  try {
    const data = await Axios.get(URL.URL_API_GET_TOTAL_DATA_RECRUITMENT);
    return data;
  } catch (err) {
    return;
  }
};
export const getTotalEmployeeForStatus = async () => {
  try {
    const data = await Axios.get(URL.URL_API_GET_TOTAL_DATA_EMPLOYEE);
    return data;
  } catch (err) {
    return;
  }
};
