import React, { useEffect, useState } from 'react';
import { GetDetailRecruitment } from '../../../../../services/RecruiterService';
import { Skeleton } from 'antd';
import moment from 'moment';
import { jobType, statusChat } from '../../../../common/FuntionCommon';
import useSWR from 'swr';
import { URL_API_GET_DETAIL_RECRUITMENT } from '../../../../../services';

const JobInfo = ({ jobId, dataListHr }) => {
  const [infoJob, setInfoJob] = useState(null);

  const { data, isLoading } = useSWR([jobId, URL_API_GET_DETAIL_RECRUITMENT], GetDetailRecruitment, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  useEffect(() => {
    if (data) {
      setInfoJob(data?.data);
    }
  }, [data]);

  let loadingSkeleton = [];
  for (let i = 0; i < 6; i++) {
    loadingSkeleton.push(<Skeleton />);
  }

  return (
    <section>
      {isLoading ? (
        <>{loadingSkeleton}</>
      ) : (
        <section className='js-tabContent' id='jobInfo'>
          <h3 className='chatInfo__title'>{infoJob?.jobTitle}</h3>
          <dl className='chatInfo__definitionList'>
            <dt>求人ID</dt>
            <dd>{infoJob?.jobCode}</dd>
            <dt>ステータス</dt>
            <dd>{statusChat(infoJob?.status)}</dd>
            <dt>求人媒体</dt>
            <dd>{infoJob?.medium}</dd>
            <dt>求人URL</dt>
            <dd>
              {infoJob?.mediumURL && (
                <a href={infoJob?.mediumURL} target='_blank' rel='noreferrer'>
                  {infoJob?.mediumURL}
                </a>
              )}
            </dd>
            <dt>担当者</dt>
            <dd>
              {infoJob?.HRinCharge?.map((item, index) => {
                const data = dataListHr?.find((e) => {
                  if ([item].includes(e?.id)) return e;
                });
                return (
                  <>
                    <div key={index}>{data?.fullName ?? ''}</div>
                  </>
                );
              })}
            </dd>
            <dt>雇用形態</dt>
            <dd>{jobType(infoJob?.jobType)}</dd>
            <dt>勤務地の郵便番号</dt>
            <dd>{infoJob?.zipCode}</dd>
            <dt>勤務地の住所</dt>
            <dd>{infoJob?.workAddress}</dd>
            <dt>募集開始日</dt>
            <dd>{infoJob?.startDate ? moment(infoJob?.startDate).format('YYYY/MM/DD') : ''}</dd>
            <dt>募集終了日</dt>
            <dd>{infoJob?.endDate ? moment(infoJob?.endDate).format('YYYY/MM/DD') : ''}</dd>
            <dt>応募者</dt>
            <dd>{infoJob?.replicaNum}</dd>
            <dt>内定者</dt>
            <dd>{infoJob?.passedApplicantNum}</dd>
          </dl>
        </section>
      )}
    </section>
  );
};
export default JobInfo;
