import React, { useEffect } from 'react';

const Footer = () => {
  const backToTop = () => {
    const backToTopButton = document.getElementById('js-backToTop');

    if (!backToTopButton) {
      return;
    }

    backToTopButton.addEventListener('click', (e) => {
      e.preventDefault();
      window.scrollTo({ top: 0, behavior: 'smooth' });
    });
  };
  useEffect(() => {
    backToTop();
  }, []);

  return (
    <footer className='layout__footer footer'>
      <button href='#header-top' className='footer__scrollToTop' id='js-backToTop'></button>
      <p className='footer__copyright'>2023&copy;CAERU inc.</p>
    </footer>
  );
};

export default Footer;
