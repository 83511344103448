import BodyTableListResult from './common/BodyTableListResult';
import React from 'react';
import FormParentApplicant from './FormParentApplicant';

// 内定
const ApplicantsPass = () => {
  return (
    <div className='lang-ding-page'>
      <FormParentApplicant>
        <BodyTableListResult type={'applicantsPass'} />
      </FormParentApplicant>
    </div>
  );
};
export default ApplicantsPass;
